import { SponsorModel } from "../../Models/Sponsor.model";

// Action types
export const SET_SPONSORS = "SET_SPONSORS";

// State interface
export interface IFooterState {
  sponsors: SponsorModel[];
}

// Actions interface

interface ISponsorAction {
  type: typeof SET_SPONSORS;
  sponsors: SponsorModel[];
}

export type FooterActionTypes = ISponsorAction;
