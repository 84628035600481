import React from "react";
import {
  LastestResultWrapper,
  LastestResultContainer,
  LastestResultHeader,
  LastestResultContent,
  LastestResultFooter,
  HeaderTitle,
  HeaderSubtitle,
  DetailsButton,
  ContentWrapperText,
  ContentCarouselContainer,
  ContentCarousel,
  ResultContainer,
  ResultInfoContainer,
  ResultInfoBlack,
  ResultInfoGreen,
  ResultInfoSeparator,
  TeamInfoContainer,
  TeamLogo,
  TeamName,
  LastestCarouselButton,
  ResultInfoMobileLogo,
  ResultBlack,
  ResultGreen,
  MobileTeamInfoContainer,
  MobileTeamInfoName,
  MobileTeamInfoSeparator,
} from "../View/style/LastestResult.style";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faLongArrowAltRight,
  faArrowLeft,
  faArrowRight,
} from "@fortawesome/free-solid-svg-icons";
import OwlCarousel from "react-owl-carousel2";
import { CalendarModel } from "../../../Models/Calendar.model";
import sort from "fast-sort";
import ReactDOMServer from "react-dom/server";
import { Link } from "react-router-dom";

export interface LastestResultProps {
  lastest: CalendarModel;
}

const LastestResult: React.FC<LastestResultProps> = (props) => {
  const { lastest } = props;
  const sortedLastest = sort([...lastest.full]).by([
    {
      desc: (match) => match.post_date,
    },
  ]);

  const options = {
    items: 1,
    nav: true,
    navText: [
      ReactDOMServer.renderToString(
        <LastestCarouselButton>
          <FontAwesomeIcon icon={faArrowLeft} />
        </LastestCarouselButton>
      ),
      ReactDOMServer.renderToString(
        <LastestCarouselButton>
          <FontAwesomeIcon icon={faArrowRight} />
        </LastestCarouselButton>
      ),
    ],
  };
  if (!lastest.full.length) {
    return <></>;
  } else {
    return (
      <LastestResultWrapper>
        <LastestResultContainer>
          <LastestResultHeader>
            <HeaderTitle>Utolsó mérkőzés</HeaderTitle>
            <HeaderSubtitle>
              <Link to="/merkozesek">Összes eredmény</Link>
            </HeaderSubtitle>
          </LastestResultHeader>
          <LastestResultContent>
            <ContentWrapperText mode="single" max={200}>
              Legfrissebb
            </ContentWrapperText>
            <ContentCarouselContainer>
              <ContentCarousel>
                <OwlCarousel options={options}>
                  {sortedLastest.map((match) => (
                    <ResultContainer key={match.ID}>
                      <TeamInfoContainer>
                        <TeamLogo
                          src={match.f_image0}
                          alt={match.team0.post.post_title}
                        ></TeamLogo>
                        <TeamName>{match.team0.post.post_title}</TeamName>
                      </TeamInfoContainer>
                      <ResultInfoContainer>
                        <div>
                          <ResultBlack>
                            <ResultInfoMobileLogo>
                              <img
                                src={match.f_image0}
                                alt={match.team0.post.post_title}
                              />
                            </ResultInfoMobileLogo>
                            <ResultInfoBlack>
                              {match.team0goals?.twoflid}
                            </ResultInfoBlack>
                          </ResultBlack>
                          <ResultInfoSeparator>VS</ResultInfoSeparator>
                          <ResultGreen>
                            <ResultInfoGreen>
                              {match.team1goals?.twoflid}
                            </ResultInfoGreen>
                            <ResultInfoMobileLogo>
                              <img
                                src={match.f_image1}
                                alt={match.team1.post.post_title}
                              />
                            </ResultInfoMobileLogo>
                          </ResultGreen>
                        </div>
                        <MobileTeamInfoContainer>
                          <MobileTeamInfoName>
                            {match.team0.post.post_title}
                          </MobileTeamInfoName>
                          <MobileTeamInfoSeparator>{" VS "}</MobileTeamInfoSeparator>
                          <MobileTeamInfoName>
                            {match.team1.post.post_title}
                          </MobileTeamInfoName>
                        </MobileTeamInfoContainer>
                        <div>
                          <Link to={`/merkozesek/${match.post_name}`}>
                            <DetailsButton>
                              <FontAwesomeIcon icon={faLongArrowAltRight} />A
                              meccs részletei
                            </DetailsButton>
                          </Link>
                        </div>
                      </ResultInfoContainer>
                      <TeamInfoContainer>
                        <TeamName>{match.team1.post.post_title}</TeamName>
                        <TeamLogo
                          src={match.f_image1}
                          alt={match.team1.post.post_title}
                        />
                      </TeamInfoContainer>
                    </ResultContainer>
                  ))}
                </OwlCarousel>
              </ContentCarousel>
            </ContentCarouselContainer>
          </LastestResultContent>
          <LastestResultFooter />
        </LastestResultContainer>
      </LastestResultWrapper>
    );
  }
};

export default LastestResult;
