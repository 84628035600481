import React from 'react';
import Breadcrumb from '../../UI/Breadcrumb/Controller/Breadcrumb.controller';
import { Container, Row, Col } from 'react-bootstrap';
import NextMatchWidget from '../../UI/NextMatchWidget/Controller/NextMatchWidget.controller';
import { MatchOverviewModel } from '../../../Models/MatchOverview.model';
import {
	MatchOverviewWrapper,
	MatchOverviewBox,
	MatchOverviewBoxTitle,
	MatchOverviewBoxContent,
	MatchOverviewResultBox,
} from './style/MatchOverview.style';
import ShadowText from '../../UI/ShadowText/ShadowText';
import moment from 'moment';
import HTMLParser from 'html-react-parser';
import 'moment/locale/hu';

interface IMatchOverviewViewProps {
	data: MatchOverviewModel[];
}
const MatchOverviewView: React.FC<IMatchOverviewViewProps> = (props) => {
	if (props.data[0] === undefined) return <></>;
	const data = props.data[0];
	return (
		<>
			<Breadcrumb third_level={HTMLParser(data.title.rendered).toString()} />
			<MatchOverviewWrapper>
				<Container>
					<Row>
						<Col xs='12' lg='8' xl='9'>
							{/** EREDMÉNY */}
							<MatchOverviewBox>
								<MatchOverviewBoxTitle>
									<ShadowText
										color='#000000'
										margin=''
										text='Eredmény'
										fontSize='46px'
									/>
								</MatchOverviewBoxTitle>
								<MatchOverviewBoxContent>
									<MatchOverviewResultBox>
										<div className='logo'>
											<img src={data.mydata.f_image0} alt={data.mydata.team0} />
										</div>
										<div className='result-wrapper'>
											<div className='result'>
												{data.mydata.team0goals?.twoflid} -{' '}
												{data.mydata.team1goals?.twoflid}
											</div>
											<div className='teams'>
												{data.mydata.team0} <span>VS</span> {data.mydata.team1}
											</div>
											<div className='match-date'>
												{moment(data.date).format('LLLL')}
											</div>
											<div className='venue'>
												{data.mydata.event_venue
													? data.mydata.event_venue
													: '-'}
											</div>
										</div>
										<div className='logo'>
											<img src={data.mydata.f_image1} alt={data.mydata.team1} />
										</div>
									</MatchOverviewResultBox>
								</MatchOverviewBoxContent>
							</MatchOverviewBox>

							{/** Összefoglaló */}
							<MatchOverviewBox>
								<MatchOverviewBoxTitle>
									<ShadowText
										color='#000000'
										margin=''
										text='Összefoglaló'
										fontSize='46px'
									/>
								</MatchOverviewBoxTitle>
								<MatchOverviewBoxContent>
									{HTMLParser(data.content.rendered)}
								</MatchOverviewBoxContent>
							</MatchOverviewBox>

							{/** Részletek */}
							<MatchOverviewBox>
								<MatchOverviewBoxTitle>
									<ShadowText
										color='#000000'
										margin=''
										text='Részletek'
										fontSize='46px'
									/>
								</MatchOverviewBoxTitle>
								<MatchOverviewBoxContent>
									<div className='details'>
										<Row>
											<Col>Idő</Col>
											<Col>Liga</Col>
											<Col>Szezon</Col>
										</Row>
										<Row>
											<Col>
												<span>{moment(data.date).format('H:mm')}</span>
											</Col>
											<Col>
												<span>{data.mydata.league}</span>
											</Col>
											<Col>
												<span>{data.mydata.season}</span>
											</Col>
										</Row>
									</div>
								</MatchOverviewBoxContent>
							</MatchOverviewBox>
						</Col>
						<Col xs='12' lg='4' xl='3'>
							<NextMatchWidget />
						</Col>
					</Row>
				</Container>
			</MatchOverviewWrapper>
		</>
	);
};

export default MatchOverviewView;
