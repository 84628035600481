import React, { ReactNode } from "react";
import { IButton } from "../Interfaces/Style.interface";
import { Button } from "./Style";

export interface IButtonViewProps extends IButton {
  children: ReactNode;
}

const ButtonView: React.FC<IButtonViewProps> = (props) => {
  return <Button {...props} />;
};

export default ButtonView;
