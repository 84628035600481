import React from 'react';
import { RouteComponentProps } from 'react-router-dom';

export interface IE404PageNotFoundProps extends RouteComponentProps {}

const E404View: React.FC<IE404PageNotFoundProps> = (
	props
): React.ReactElement => {
	return <div>404 not found</div>;
};

export default E404View;
