import styled from 'styled-components';
import { theme } from '../../../Global/Variables';
import { Row } from 'react-bootstrap';

export const MatchPageWrapper = styled.div`
	padding-top: 35px;
	padding-bottom: 80px;
`;

export const MatchContainer = styled.div`
	display: flex;
	padding: 10px 0;
	border-bottom: 1px solid ${theme.colors.frame_border_color};
	background: white;
	min-height: 120px;
`;

export const HomeInfoContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: flex-start;
	height: 100%;
	width: 100%;
	text-align: start;
	padding-left: 50px;
	@media screen and (max-width: 992px) {
		padding-left: 25px;
	}
	@media screen and (max-width: 576px) {
		padding-left: 0;
	}
`;

export const TeamInfoTitle = styled.p`
	margin: 0;
	text-transform: uppercase;
	@media screen and (max-width: 768px) {
		display: none;
	}
`;

export const TeamInfoLogo = styled.img`
	height: 50px;
	width: auto;
	margin-right: 15px;
	@media screen and (max-width: 768px) {
		margin: 0;
	}
`;

export const ResultInfoContainer = styled.div`
	height: 100%;
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
`;

export const ResultInfoDate = styled.p`
	font-weight: 400;
	color: ${theme.colors.light_text};
	font-size: 12px;
	text-align: center;
	text-transform: uppercase;
	margin: 0;
`;

export const ResultInfoData = styled.p`
	font-size: 16px;
	font-weight: 900;
	color: white;
	background-color: ${theme.colors.secondary};
	padding: 10px 15px;
	margin: 0;
`;

export const ResultInfoButton = styled.button`
	text-transform: uppercase;
	background: transparent;
	outline: none;
	border: none;
	font-size: 12px;
	font-weight: 900;
	color: ${theme.colors.separator_color};
	margin: 0;

	&:focus,
	&:active {
		outline: none;
	}
`;

export const AwayInfoContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: flex-end;
	height: 100%;
	width: 100%;
	padding-right: 50px;
	text-align: end;
	text-transform: uppercase;

	@media screen and (max-width: 992px) {
		padding-right: 25px;
	}

	@media screen and (max-width: 576px) {
		padding-right: 0;
	}

	& > img {
		margin-left: 15px;
	}
`;

export const UpcomingInfoContainer = styled.div`
	height: 100%;
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
`;

export const UpcomingInfoDate = styled.p`
	font-size: 14px;
	font-weight: 400;
	color: ${theme.colors.light_text};
	margin: 0;
	text-transform: uppercase;
	text-align: center;
`;

export const UpcomingInfoTime = styled.span`
	font-size: 28px;
	font-weight: 700;
	color: ${theme.colors.secondary_text};
`;

export const UpcomingInfoVenue = styled.p`
	font-size: 14px;
	font-weight: 400;
	color: ${theme.colors.light_text};
	margin: 0;
	text-align: center;
`;

export const FeaturedEventContainer = styled(Row)`
	min-height: 300px;
	background-color: ${theme.colors.grey_background_color};
	border: 1px solid ${theme.colors.frame_border_color};
	max-width: 100%;
	margin: 0 auto;
`;

export const FeaturedTeamContainer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	width: 100%;
	height: 100%;
`;

export const FeaturedTeamName = styled.p`
	font-size: 20px;
	font-weight: 700;
	text-transform: uppercase;
	text-align: center;
	word-wrap: break-word;
	@media screen and (max-width: 992px) {
		font-size: 16px;
		line-height: 1.1;
	}
`;

export const FeaturedTeamLogo = styled.img`
	max-height: 150px;
	height: 100%;
	width: auto;
	margin: 35px 0 35px 0;
	@media screen and (max-width: 992px) {
		max-height: 100px;
	}

	/* @media screen and (max-width: 992px) {
    height: 100px;
  } */
`;

export const FeaturedContent = styled.div`
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	padding-bottom: 20px;
`;

export const FeaturedInfoContainer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
`;

export const FeaturedInfoText = styled.p`
	text-transform: uppercase;
	color: #999999;
	font-size: 16px;
	font-weight: 400;
	text-align: center;

	position: relative;
	bottom: -25px;
	&:first-child {
		margin: 0;
	}
`;
