import { SingleNewsModel } from "./../../Models/SingleNews.model";
import { SET_SINGLE_NEWS, SingleNewsActionTypes } from "./types";
import { Dispatch } from "redux";
import IReduxState from "../../Interfaces/IRedux.interface";
import { setError, setFull, setLoading } from "../Status/actions";
import { IError } from "../../Interfaces/IError.interface";
import InsideNewsPageDAO from "../../DAOs/InsideNewsPage.DAO";

const setSingleNews = (news: SingleNewsModel[]): SingleNewsActionTypes => {
  return {
    type: SET_SINGLE_NEWS,
    news: news,
  };
};

export const getSingleNews = (slug: string) => {
  return async (dispatch: Dispatch, getState: () => IReduxState) => {
    if (getState().singleNews.news[0]?.slug === slug) {
      dispatch(setFull(false));
    } else {
      dispatch(setFull(true));
    }

    dispatch(
      setLoading(
        InsideNewsPageDAO.getPost(slug)
          .then((post: SingleNewsModel[]) => {
            if (getState().singleNews.news[0]?.slug !== slug) {
              dispatch(setSingleNews(post));
            }
          })
          .catch((error: IError) => {
            dispatch(setError(error));
          })
          .finally(() => {
            dispatch(setLoading(null));
          })
      )
    );
  };
};
