import { IError } from "../../Interfaces/IError.interface";

// Action types
export const SET_ERROR = "SET_ERROR";
export const SET_LOADING = "SET_LOADING";
export const SET_FULL = "SET_FULL";

// State interface
export interface IStatusState {
  error: IError;
  loading: Promise<any> | null;
  full: boolean;
}

// Actions interface

interface IErrorAction {
  type: typeof SET_ERROR;
  error: IError;
}

interface ILoadingAction {
  type: typeof SET_LOADING;
  loading: Promise<any> | null;
}

interface IFullAction {
  type: typeof SET_FULL;
  full: boolean;
}

export type StatusActionTypes = IFullAction | IErrorAction | ILoadingAction;
