import styled from 'styled-components';
import { theme } from '../../../../Global/Variables';

export const NextMatchWidgetContainer = styled.div`
	width: 100%;
	min-height: 325px;
	border: 1px solid ${theme.colors.frame_border_color};
	background: ${theme.colors.tertiary};
	@media screen and (max-width: 992px) {
		padding-bottom: 60px;
	} ;
`;

export const WidgetTitleContainer = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-start;
	background-color: ${theme.colors.tertiary};
	border-bottom: 1px solid ${theme.colors.frame_border_color};
	padding: 15px;
`;

export const WidgetTitleBagde = styled.span`
	font-weight: 600;
	font-size: 14px;
	color: ${theme.colors.primary_text};
	margin: 0;
	text-transform: uppercase;
	background-color: ${theme.colors.secondary};
	margin-right: 5px;
	padding-right: 15px;
	padding-left: 5px;
	clip-path: polygon(0 0, 100% 0%, 85% 100%, 0% 100%);
`;

export const WidgetTitle = styled.p`
	font-weight: 900;
	font-size: 16px;
	color: ${theme.colors.secondary_text};
	margin: 0;
	text-transform: uppercase;
`;

export const WidgetContent = styled.div``;

export const WidgetItem = styled.div`
	background-color: ${theme.colors.tertiary};
	padding: 30px 25px 0 30px;
`;

export const WidgetItemLogos = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: flex-end;
	margin-bottom: 30px;
`;

export const WidgetItemLogo = styled.img`
	width: auto !important;
	height: 80px;
`;

export const WidgetItemTitleContainer = styled.div`
	display: flex;
	justify-content: center;
	flex-direction: column;
	align-items: center;
	padding-bottom: 15px;
	span {
		font-weight: 600;
		font-size: 16px;
		text-transform: uppercase;
		text-align: center;
		color: ${theme.colors.light_text};
		margin-bottom: 0;
	}
`;

export const WidgetItemTitle = styled.p`
	text-align: center;
	font-size: 20px;
	text-transform: uppercase;
	color: ${theme.colors.secondary_text};
	font-weight: 600;
	margin-bottom: 0;
`;

export const WidgetItemInfos = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	color: ${theme.colors.light_text};
	text-transform: uppercase;
	background-image: linear-gradient(
		${theme.colors.secondary} 0%,
		${theme.colors.secondary} 100%
	);
	background-position: top center;
	background-size: 20% 3px;
	background-repeat: no-repeat;
	p {
		margin-bottom: 0;
		text-align: center;
		font-size: 12px;
		font-weight: 600;
	}
	padding-top: 20px;
`;

export const WidgetItemButton = styled.div`
	display: flex;
	justify-content: center;
	margin: 20px 0;
`;
