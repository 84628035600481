import React from 'react';
import { ISingleMedia } from '../../../Interfaces/SingleMedia.interface';
import '../../../../node_modules/react-image-gallery/styles/css/image-gallery.css';

import ImageGallery from 'react-image-gallery';
import { Container } from 'react-bootstrap';
import Breadcrumb from '../../UI/Breadcrumb/Controller/Breadcrumb.controller';
import Loader from '../../UI/Loader/View/Loader.view';
import { SingleMediaWrapper, ImageGalleryWrapper } from './SingleMedia.style';

interface ISingleMediaViewProps {
	media: ISingleMedia[];
}

const SingleMediaView: React.FC<ISingleMediaViewProps> = (
	props
): React.ReactElement => {
	if (props.media.length === 0) return <Loader full={true} visible={true} />;
	const media = props.media;
	const bc = media[0]?.gallery_name;
	media.splice(0, 1);
	media.map((m) => [
		...media,
		(m.thumbnail = 'https://labdarugas.deac.hu' + m.thumbnail),
		(m.original = 'https://labdarugas.deac.hu' + m.original),
	]);

	return (
		<>
			<Breadcrumb third_level={bc} />
			<SingleMediaWrapper>
				<Container>
					<ImageGalleryWrapper>
						<ImageGallery
							items={media}
							thumbnailPosition='bottom'
							disableSwipe={false}
						/>
					</ImageGalleryWrapper>
				</Container>
			</SingleMediaWrapper>
		</>
	);
};

export default SingleMediaView;
