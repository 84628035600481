import { TeamPageModel } from "../../Models/TeamPage.model";
import { ITeamPageState, SET_STAFF, SET_TEAM, TeamPageActionTypes } from "./types";

const initialState: ITeamPageState = {
  team: new TeamPageModel(),
  staff: [],
};

const widgetsReducer = (
  state = initialState,
  action: TeamPageActionTypes
): ITeamPageState => {
  switch (action.type) {
    case SET_TEAM:
      return { ...state, team: action.team };
    case SET_STAFF:
      return { ...state, staff: action.staff };
    default:
      return state;
  }
};

export default widgetsReducer;
