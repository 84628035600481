import { ITeamPage, IData } from './../Interfaces/ITeamPage.interface';
import { ModelConstructor } from '../Utils/DataSetter.util';

export class TeamPageModel {
	constructor(TeamPage?: ITeamPage) {
		if (TeamPage) {
			ModelConstructor(TeamPage, this);
		}
	}
	data: IData = {
		mydata: [
			{
				featured_image: '',
				name: '',
				number: '',
				pos: [{ term_id: 0, name: '' }],
				player_id: 0,
				magassag: '',
				suly: '',
				elitprospects: '',
				player_data: { post_date: '' },
			},
		],
	};
}
