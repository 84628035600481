import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { MediaModel } from '../../../Models/Media.model';
import {
	MainMediaWrapper,
	MediaBody,
	MediaHead,
} from './style/MainMedia.style';
import { MediaCard } from '../../UI/MediaCard/MediaCard';
import ShadowText from '../../UI/ShadowText/ShadowText';
export interface IMainMediaViewProps {
	media: MediaModel[];
}

const MainMediaView: React.FC<IMainMediaViewProps> = (
	props
): React.ReactElement => {
	return (
		<MainMediaWrapper>
			<Container>
				<MediaHead>
					<div>
						<ShadowText margin='0' text='Média' color='#ffffff' />
						<div>
							<p title='Képek' className='active'>
								Képek
							</p>
							{/** TODO: YT LINK */}
							<a
								title='Videók'
								href='https://yt.com'
								target='_blank'
								rel='noopener noreferrer'
							>
								Videók
							</a>
						</div>
					</div>
					<Link to='/media'>
						<em>Irány a médiatár</em>
					</Link>
				</MediaHead>
				<MediaBody>
					<Row>
						{props.media.map((m) => {
							return (
								<Col lg='4' sm='12' key={`mid-${m.id}`}>
									<MediaCard
										featured_image={`https://labdarugas.deac.hu/${m.preview_image}`}
										link={`media/${m.slug}`}
										title={m.name}
									/>
								</Col>
							);
						})}
					</Row>
				</MediaBody>
			</Container>
		</MainMediaWrapper>
	);
};

export default MainMediaView;
