import React from "react";
import ReactDOM from "react-dom";
import { axios } from "./API/axios";
import { BrowserRouter } from "react-router-dom";
import Router from "./Components/Router/Controller/Router.controller";
import { Provider } from "react-redux";
import store from "./Redux/store";
import Loader from "./Components/UI/Loader/Controller/Loader.controller";

axios.initAxios("https://labdarugas.deac.hu/wp-json");

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <Router />
      </BrowserRouter>
      <Loader />
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);
