import React from "react";
import { IMainNews } from "../../../Interfaces/IMainPage.interface";
import { MainNewsModel } from "../../../Models/MainNews.model";
import { Container, Row, Col } from "react-bootstrap";
import HTMLParser from "html-react-parser";
import { Link } from "react-router-dom";
import {
  NewsWrapper,
  ArticleWrapper,
  ArticleImage,
  ArticleDate,
  ArticleTitle,
  ArticleExcerpt,
} from "./style/MainNews.style";
import { formatDateString } from "../../../Utils/FormatDate.util";
import ButtonView from "../../UI/Button/View/Button.view";
import ShadowText from "../../UI/ShadowText/ShadowText";

export interface IMainNewsViewProps {
  news: MainNewsModel[];
}

const MainNewsView: React.FC<IMainNewsViewProps> = (
  props
): React.ReactElement => {
  if (!props.news.length) {
    return <></>;
  } else {
    return (
      <NewsWrapper>
        <Container>
          <ShadowText margin="0 0 80px 0" text="Hírek" color="#000000" />
          <Row>
            {props.news.map((article: IMainNews, index) => (
              <Col xs={12} md={4} key={index}>
                <ArticleWrapper>
                  <ArticleImage>
                    <Link to={`/hirek/${article.slug}`}>
                      <img src={article.f_image.medium} alt="" />
                    </Link>
                  </ArticleImage>
                  <Row>
                    <Col xs={3}>
                      <ArticleDate>
                        <span>
                          {
                            formatDateString(
                              article.date.toString(),
                              "-",
                              true,
                              false,
                              true
                            ).split(" ")[0]
                          }
                        </span>
                        <span>
                          {
                            formatDateString(
                              article.date.toString(),
                              "-",
                              true,
                              false,
                              true
                            ).split(" ")[1]
                          }
                        </span>
                      </ArticleDate>
                    </Col>
                    <Col xs={9}>
                      <ArticleTitle>
                        <Link to={`/hirek/${article.slug}`}>
                          {article.title.rendered}
                        </Link>
                      </ArticleTitle>
                      <ArticleExcerpt>
                        {HTMLParser(article.excerpt.rendered)}
                      </ArticleExcerpt>
                    </Col>
                  </Row>
                </ArticleWrapper>
              </Col>
            ))}
          </Row>
          <Row className="d-flex justify-content-center">
            <ButtonView outlined width="250px">
              Még több hír
            </ButtonView>
          </Row>
        </Container>
      </NewsWrapper>
    );
  }
};

export default MainNewsView;
