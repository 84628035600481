import { ModelConstructor } from "../Utils/DataSetter.util";
import { INewsResponse, IHeaders, INew } from "../Interfaces/NewsPage.interface";

export class NewsPageModel {
  constructor(NewsPage?: INewsResponse) {
    if (NewsPage) {
      ModelConstructor(NewsPage, this);
    }
  }
  headers: IHeaders = { "x-wp-total": 0, "x-wp-totalpages": 0 };
  data: INew[] = [];
}
