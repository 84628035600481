import styled from "styled-components";
/* import { motion } from "framer-motion";*/
import { theme } from "../../../Global/Variables";
import contactBg from "../../../Assets/Images/footer-bckg.png";
import { Container, Row } from "react-bootstrap";

export const FooterContainer = styled.footer`
  background-color: ${theme.colors.grey_background_color};
`;

export const SponsorsContainer = styled(Container)`
  padding-bottom: 40px;
`;

export const SponsorsTitle = styled.p`
  color: ${theme.colors.secondary_text};
  text-transform: uppercase;
  font-size: 28px;
  font-weight: 900;
  padding: 60px 0;
`;

export const DashedSponsorContainer = styled.div`
  position: relative;
  height: 160px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  margin: 5px;

  &:hover {
    &:after {
      width: 100%;
      border-radius: 5px;
    }
    &:before {
      height: 100%;
      border-radius: 5px;
    }
  }

  &:after {
    content: "";
    position: absolute;
    width: 75%;
    height: 100%;
    border-top: 1.5px dashed ${theme.colors.inactive_color};
    border-bottom: 1.5px dashed ${theme.colors.inactive_color};
    transition: width 0.3s ease-in-out, border 0.3s ease-in-out;
  }
  &:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 75%;
    border-right: 1.5px dashed ${theme.colors.inactive_color};
    border-left: 1.5px dashed ${theme.colors.inactive_color};
    transition: height 0.3s ease-in-out, border 0.3s ease-in-out;
  }

  a {
    cursor: pointer;
    z-index: 2;
    text-decoration: none;
    user-select: none;
    transition: filter 0.3s ease-in-out;
    filter: grayscale(1);
    &:hover {
      filter: grayscale(0);
    }
  }

  img {
    width: 100%;
    height: auto;
  }

  @media screen and (max-width: 360px) {
    height: 140px;
  }
`;

export const ContactWrapper = styled.div`
  background-image: url(${contactBg});
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
`;

export const ContactContainer = styled(Container)`
  padding-bottom: 60px;
`;

export const ContactTitle = styled.p`
  color: ${theme.colors.primary_text};
  font-size: 18px;
  font-weight: 900;
  text-transform: uppercase;
  padding: 60px 0 40px 0;
`;

export const ContactContent = styled(Row)`
  a {
    text-decoration: none;
    &:hover {
      text-decoration: none !important;
    }
  }
`;

export const Contact = styled.div`
  color: ${theme.colors.light_text};
  display: flex;
  flex-direction: row;
  margin-bottom: 15px;
  justify-content: center;
  align-items: center;

  @media screen and (min-width: 1200px) {
    margin-bottom: 0;
  }
`;
export const ContactIcon = styled.div`
  margin-right: 15px;
`;
export const ContactLabel = styled.p`
  margin: 0;
`;

export const CopyrightContainer = styled.div`
  background-color: ${theme.colors.copyright_background_color};
  width: 100%;
`;

export const CopyrightContent = styled.div`
  padding: 22px 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  width: 100%;
`;

export const CopyrightInfoLink = styled.a`
  color: ${theme.colors.primary};
  font-size: 12px;
  font-weight: 500;
  text-decoration: none;
  &:hover {
    text-decoration: none !important;
    undeline: none !important;
    color: ${theme.colors.primary};
  }
`;

export const Creator = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;
export const CreatorInfo = styled.p`
  margin: 0 15px 0 0;
  color: ${theme.colors.primary};
  font-size: 12px;
  font-weight: 300;
  text-transform: uppercase;
`;
export const CreatorLink = styled.a`
  color: ${theme.colors.secondary_text} !important;
  font-family: "Open Sans", sans-serif !important;
  font-weight: 800 !important;
  font-size: 12px;
  font-style: italic !important;
  text-transform: lowercase;
  &:hover {
    text-decoration: none !important;
    undeline: none !important;
    color: ${theme.colors.secondary_text} !important;
  }
`;
