import React, { useEffect } from 'react';
import MainPageView from '../View/MainPage.view';
import IReduxState from '../../../Interfaces/IRedux.interface';
import { MainPageSliderModel } from '../../../Models/MainPageSlider.model';
import { MainNewsModel } from '../../../Models/MainNews.model';
import { CalendarModel } from '../../../Models/Calendar.model';
import { MediaModel } from '../../../Models/Media.model';
import { connect, useDispatch } from 'react-redux';
import * as mainPageActions from '../../../Redux/MainPage/actions';

export interface IMainPageControllerProps {
	slider: MainPageSliderModel[];
	news: MainNewsModel[];
	upcoming: CalendarModel;
	lastest: CalendarModel;
	media: MediaModel[];
}

const MainPageController: React.FC<IMainPageControllerProps> = (
	props
): React.ReactElement => {
	const dispatch = useDispatch();

	const loadMainPage = (): void => {
		dispatch(mainPageActions.getMainPage());
	};

	useEffect(() => {
		loadMainPage();
		//eslint-disable-next-line
	}, []);

	return (
		<MainPageView
			slider={props.slider}
			news={props.news}
			upcoming={props.upcoming}
			lastest={props.lastest}
			media={props.media}
		/>
	);
};

const mapStateToProps = (state: IReduxState) => ({
	slider: state.mainPage.slider,
	news: state.mainPage.news,
	upcoming: state.calendar.upcoming,
	lastest: state.calendar.lastest,
	media: state.mainPage.media,
});

export default connect(mapStateToProps)(MainPageController);
