import React from "react";
import { SponsorModel } from "../../../Models/Sponsor.model";
import {
  FooterContainer,
  SponsorsTitle,
  SponsorsContainer,
  DashedSponsorContainer,
  ContactContainer,
  ContactTitle,
  ContactWrapper,
  ContactContent,
  Contact,
  ContactIcon,
  ContactLabel,
  CopyrightContainer,
  CopyrightContent,
  CopyrightInfoLink,
  Creator,
  CreatorInfo,
  CreatorLink,
} from "../View/Style";
import { Row, Col, Container } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFlag,
  faEnvelope,
  faPhoneAlt,
} from "@fortawesome/free-solid-svg-icons";

export interface IFooterViewProps {
  sponsors: SponsorModel[];
}

const FooterView: React.FC<IFooterViewProps> = (props): React.ReactElement => {
  return (
    <FooterContainer>
      {!props.sponsors.length ? (
        <></>
      ) : (
        <SponsorsContainer>
          <Row>
            <Col xs="12">
              <SponsorsTitle>Köszönjük a támogatást!</SponsorsTitle>
            </Col>
          </Row>
          <Row>
            {props.sponsors.map((sponsor, index) => (
              <Col className="p-0" xs={6} md={4} lg={3} xl={2} key={index}>
                <DashedSponsorContainer>
                  <a href={sponsor.url}>
                    <img
                      src={sponsor._embedded["wp:featuredmedia"][0].source_url}
                      alt={sponsor.title.rendered}
                    />
                  </a>
                </DashedSponsorContainer>
              </Col>
            ))}
          </Row>
        </SponsorsContainer>
      )}
      <ContactWrapper>
        <ContactContainer>
          <Row>
            <Col xs="12">
              <ContactTitle>Lépj velünk kapcsolatba</ContactTitle>
            </Col>
          </Row>
          <ContactContent>
            <Col sm={12} lg={5} xl={4}>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://goo.gl/maps/A4QWnpYemLpg9Th39"
              >
                <Contact>
                  <ContactIcon>
                    <FontAwesomeIcon icon={faFlag} />
                  </ContactIcon>
                  <ContactLabel>4032 DEBRECEN, DÓCZY JÓZSEF U. 9.</ContactLabel>
                </Contact>
              </a>
            </Col>
            <Col sm={12} lg={3} xl={4}>
              <a href="mailto: hello@deac.hu">
                <Contact>
                  <ContactIcon>
                    <FontAwesomeIcon icon={faEnvelope} />
                  </ContactIcon>
                  <ContactLabel>HELLO@DEAC.HU</ContactLabel>
                </Contact>
              </a>
            </Col>
            <Col sm={12} lg={4} xl={4}>
              <a href="tel:+3652512721">
                <Contact>
                  <ContactIcon>
                    <FontAwesomeIcon icon={faPhoneAlt} />
                  </ContactIcon>
                  <ContactLabel>+36 (52) 512-721 MELLÉK: 23721</ContactLabel>
                </Contact>
              </a>
            </Col>
          </ContactContent>
        </ContactContainer>
      </ContactWrapper>
      <CopyrightContainer>
        <Container>
          <Row>
            <CopyrightContent>
              <CopyrightInfoLink href="https://labdarugas.deac.hu/">
                COPYRIGHT DEAC.HU © 2020
              </CopyrightInfoLink>
              <Creator>
                <CreatorInfo>KÉSZÍTETTE</CreatorInfo>
                <CreatorLink href="http://itechline.hu" target="_blank">
                  itech____
                </CreatorLink>
              </Creator>
            </CopyrightContent>
          </Row>
        </Container>
      </CopyrightContainer>
    </FooterContainer>
  );
};

export default FooterView;
