import { infoPageRoute } from '../Static/ApiRoutes';
import { axios } from '../API/axios';
import { InfoPageModel } from '../Models/InfoPage.model';
import { IInfoPage } from '../Interfaces/IInfoPage.interface';
import { AxiosPromise, AxiosResponse } from 'axios';

export default abstract class InfoPageDAO {
	private static readonly base: string = infoPageRoute;

	public static async getData(): Promise<InfoPageModel> {
		const request: AxiosPromise<IInfoPage> = axios.getInstance().get(this.base);
		const response: AxiosResponse<IInfoPage> = await request;
		return response.data;
	}
}
