import styled from "styled-components";
import { theme } from "../../../../Global/Variables";
import { Container } from "react-bootstrap";
import { Textfit } from "react-textfit";

interface ITextFit {
  max: number;
  min: number;
  mode: "single" | "multi";
}

export const LastestResultWrapper = styled.section`
  position: relative;
  background-color: ${theme.colors.grey_background_color};
`;

export const LastestResultContainer = styled(Container)``;

export const LastestResultHeader = styled.div`
  position: relative;
  min-height: 160px;
  z-index: 0;

  @media screen and (max-width: 992px) {
    &::after {
      display: none;
    }
  }

  &::after {
    z-index: -1;
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 50%;
    height: 100%;
    background-image: linear-gradient(
      80deg,
      ${theme.colors.tertiary} 14.29%,
      transparent 14.29%,
      transparent 50%,
      ${theme.colors.tertiary} 50%,
      ${theme.colors.tertiary} 64.29%,
      transparent 64.29%,
      transparent 100%
    );
    background-size: 14.22px 80.62px;
  }
`;

export const HeaderTitle = styled.p`
  font-size: 28px;
  color: ${theme.colors.secondary_text};
  font-weight: 900;
  text-transform: uppercase;
  padding: 60px 0 0 0;
  margin: 0;
`;

export const HeaderSubtitle = styled.p`
  margin: 0;
  padding-bottom: 60px;
  a {
    font-size: 14px;
    color: ${theme.colors.light_text} !important;
    font-weight: 300;
    font-style: italic;
  }
`;

export const LastestResultContent = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  @media screen and (max-width: 992px) {
    padding-bottom: 40px;
  }
`;

export const ContentWrapperText = styled(Textfit)<ITextFit>`
  position: absolute;
  font-weight: 900;
  text-transform: uppercase;
  color: ${theme.colors.primary_text};
  width: 100%;
  user-select: none;

  @media screen and (max-width: 992px) {
    display: none;
  }
`;

export const ContentCarouselContainer = styled.div`
  width: 100%;
`;

export const ContentCarousel = styled.div`
  width: 100%;
  .owl-carousel {
    touch-action: manipulation;
  }
  .owl-item {
    height: 100%;
  }
  .owl-nav {
    display: flex;
    flex-direction: row;
    position: absolute;
    top: -140px;
    right: 0;
  }
  .owl-prev button {
    margin-right: 5px;
  }
  .disabled {
    opacity: 0.5;
  }

  @media screen and (max-width: 992px) {
    .owl-nav {
      position: relative;
      top: auto;
      right: auto;
      justify-content: center;
      margin-top: 30px;
      align-items: center;
    }
  }
`;

export const LastestCarouselButton = styled.button`
  width: 30px;
  height: 30px;
  border: 1px solid ${theme.colors.separator_color};
  background-color: ${theme.colors.tertiary};
  outline: none;
  color: ${theme.colors.upcoming_button_color};
  font-size: 12px;
  transition: opacity 0.3s ease-in-out;
  &:focus {
    outline: none;
  }

  @media screen and (max-width: 768px) {
    width: 45px;
    height: 45px;
    font-size: 16px;
  }
`;

export const LastestResultFooter = styled.div`
  position: relative;
  min-height: 205px;
  z-index: 0;
  display: flex;
  justify-content: center;
  align-items: flex-start;

  @media screen and (max-width: 992px) {
    min-height: 0;
    &::before {
      display: none;
    }
  }

  &::before {
    z-index: -1;
    content: "";
    position: absolute;
    top: -3px;
    bottom: 0;
    right: 0;
    width: 50%;
    height: calc(100% + 3px);
    background-image: linear-gradient(
      100deg,
      ${theme.colors.tertiary} 14.29%,
      transparent 14.29%,
      transparent 50%,
      ${theme.colors.tertiary} 50%,
      ${theme.colors.tertiary} 64.29%,
      transparent 64.29%,
      transparent 100%
    );
    background-size: 14.22px 80.62px;
  }
`;

export const DetailsButton = styled.button`
  background: transparent;
  outline: none;
  border: none;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: 600;

  &:focus {
    outline: none;
    border: none;
  }

  svg {
    font-size: 24px;
    margin-right: 25px;
  }
`;

export const ResultContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  width: 100%;
`;

export const TeamInfoContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 30%;
  padding: 0 25px;

  @media screen and (max-width: 992px) {
    display: none;
  }
`;

export const TeamLogo = styled.img`
  height: 100% !important;
  width: auto !important;
  max-height: 100px;
`;

export const TeamName = styled.p`
  font-weight: 900;
  font-size: 18px;
  color: ${theme.colors.secondary_text};
  text-transform: uppercase;
  margin: 0;
  text-align: center;
`;

export const ResultInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  & > div {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    width: auto;
    &:last-child {
      width: auto;
    }
  }
`;

export const ResultInfoBlack = styled.div`
  padding: 0 30px 0 40px;
  font-weight: 900;
  font-size: 28px;
  color: ${theme.colors.primary_text};
  text-transform: uppercase;
  @media screen and (max-width: 992px) {
    font-size: 36px;
  }

  @media screen and (max-width: 576px) {
    font-size: 32px;
    padding: 0 15px 0 20px;
  }
`;

export const ResultInfoSeparator = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  font-weight: 900;
  font-size: 18px;
  color: ${theme.colors.primary_text};
  background-color: ${theme.colors.quarterly};
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;

  @media screen and (max-width: 992px) {
    width: 55px;
    height: 55px;
  }

  @media screen and (max-width: 576px) {
    width: 45px;
    height: 45px;
  }
`;

export const ResultInfoGreen = styled.div`
  padding: 0 30px 0 40px;
  font-weight: 900;
  font-size: 28px;
  color: ${theme.colors.primary_text};
  text-transform: uppercase;
  @media screen and (max-width: 992px) {
    font-size: 36px;
  }

  @media screen and (max-width: 576px) {
    font-size: 32px;
    padding: 0 15px 0 20px;
  }
`;

export const ResultInfoMobileLogo = styled.div`
  @media screen and (min-width: 993px) {
    display: none;
  }

  img {
    height: 75px !important;
    width: auto !important;
  }

  @media screen and (max-width: 576px) {
    img {
      height: 50px !important;
      width: auto !important;
    }
  }
`;

export const ResultBlack = styled.div`
  background-color: ${theme.colors.quarterly};
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  padding: 20px;
`;

export const ResultGreen = styled.div`
  background-color: ${theme.colors.secondary};
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  padding: 20px;
`;

export const MobileTeamInfoContainer = styled.div`
  padding: 40px 0 20px 0;
  width: 100%;
  margin-bottom: 20px;
  display: none !important;

  @media screen and (max-width: 992px) {
    display: flex !important;
  }

  background-image: linear-gradient(
    ${theme.colors.secondary},
    ${theme.colors.secondary}
  );
  background-position: bottom center;
  background-repeat: no-repeat;
  background-size: 20% 3px;
`;

export const MobileTeamInfoName = styled.div`
  font-weight: 500;
  font-size: 18px;
  color: ${theme.colors.secondary_text};
  text-transform: uppercase;
`;

export const MobileTeamInfoSeparator = styled.div`
  font-weight: 500;
  font-size: 18px;
  color: ${theme.colors.light_text};
  text-transform: uppercase;
  padding: 0 10px;
`;
