import React, { ReactNode } from "react";
import Layout from "../View/Layout.view";

export interface ILayoutControllerProps {
  children: ReactNode;
}

const LayoutController: React.FC<ILayoutControllerProps> = (
  props
): React.ReactElement => {
  return <Layout {...props} />;
};

export default LayoutController;
