import React, { useEffect } from "react";
import FooterView from "../View/Footer.view";
import { SponsorModel } from "../../../Models/Sponsor.model";
import IReduxState from "../../../Interfaces/IRedux.interface";
import * as footerActions from "../../../Redux/Footer/actions";
import { connect, useDispatch } from "react-redux";

export interface FooterControllerProps {
  sponsors: SponsorModel[];
}

const FooterController: React.FC<FooterControllerProps> = (props) => {
  const dispatch = useDispatch();
  const loadSponsors = (): void => {
    dispatch(footerActions.getSponsors());
  };

  useEffect(() => {
    loadSponsors();
    //eslint-disable-next-line
  }, []);
  return <FooterView sponsors={props.sponsors} />;
};

const mapStateToProps = (state: IReduxState) => ({
  sponsors: state.footer.sponsors,
});

export default connect(mapStateToProps)(FooterController);
