import { MediaModel } from '../../Models/Media.model';

// Action types
export const SET_MEDIA = 'SET_MEDIA';

// State interface
export interface IMediaState {
	media: MediaModel[];
}

// Actions interface

interface IMediaAction {
	type: typeof SET_MEDIA;
	media: MediaModel[];
}

export type MediaActionTypes = IMediaAction;
