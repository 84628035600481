import styled from 'styled-components';
import { theme } from '../../../../Global/Variables';
import { IInterestedCardImg } from '../Interfaces/Styles.interface';

export const InterestedInContainer = styled.div`
	padding-bottom: 80px;
	a {
		text-decoration: none;
	}
	@media screen and (max-width: 991px) {
		padding-bottom: 0;
	}
`;

export const InterestedInTitleConteiner = styled.div`
	border-bottom: 1px solid ${theme.colors.frame_border_color};
	border-top: 1px solid ${theme.colors.frame_border_color};
	padding: 30px 0 30px 0;
	margin: 35px 0 35px 0;
`;

export const InterestedInTitle = styled.p`
	font-size: 18px;
	font-weight: 900;
	text-transform: uppercase;
	font-style: italic;
	margin: 0;
	color: ${theme.colors.secondary_text};
`;

export const InterestedInCard = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: flex-start;

	@media screen and (max-width: 991px) {
		padding-bottom: 35px;
	}
`;

export const InterestedInCardImg = styled.div<IInterestedCardImg>`
	background: url(${({ imageUrl }) => imageUrl});
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	width: 150px;
	height: 150px;
	min-width: 150px;
	min-height: 150px;
`;
export const InterestedInCardInfo = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	margin-left: 15px;
`;

export const InterestedInCardTitle = styled.p`
	font-size: 18px;
	font-weight: 900;
	text-transform: uppercase;
	color: ${theme.colors.secondary_text};
	margin: 0;
`;
export const InterestedInCardDate = styled.p`
	font-size: 16px;
	font-weight: 300;
	text-transform: uppercase;
	color: ${theme.colors.secondary_text};
	margin: 0;
`;

export const InterestedInCardCategory = styled.span`
	background-color: ${theme.colors.secondary};
	color: ${theme.colors.primary_text};
	font-size: 16px;
	padding: 2.5px 5px;
	text-transform: uppercase;
`;
