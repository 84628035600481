import React, { useEffect, useState } from 'react';
import { MenuModel } from '../../../Models/Menu.model';
import HeaderView from '../View/Header.view';
import { RouteChildrenProps } from 'react-router-dom';
import IReduxState from '../../../Interfaces/IRedux.interface';
import * as headerActions from '../../../Redux/Header/actions';
import { connect, useDispatch } from 'react-redux';

interface IHeaderControllerProps extends RouteChildrenProps {
	mainStyle: boolean;
	activeItem: string;
	menu: MenuModel;
}

const HeaderController: React.FC<IHeaderControllerProps> = (
	props
): React.ReactElement => {
	const { pathname } = props.location;
	const { mainStyle, activeItem, menu } = props;
	const [dropdownStatus, setDropdownStatus] = useState(false);
	const [drawerStatus, setDrawerStatus] = useState(false);
	const dispatch = useDispatch();

	const changeMainStyle = (mainStyle: boolean) => {
		dispatch(headerActions.changeMainStyle(mainStyle));
	};

	const selectMenuItemHandler = (activeItem: string) => {
		dispatch(headerActions.setActiveItem(activeItem));
	};

	useEffect(() => {
		if (pathname === '/') {
			changeMainStyle(true);
			selectMenuItemHandler('');
		} else {
			if (mainStyle) {
				changeMainStyle(false);
			}
			selectMenuItemHandler(pathname.split('/')[1]);
		}
		// eslint-disable-next-line
	}, [pathname]);

	return (
		<HeaderView
			{...props}
			menu={menu}
			dropdownStatus={dropdownStatus}
			dropdownHandler={setDropdownStatus}
			drawerStatus={drawerStatus}
			drawerHandler={setDrawerStatus}
			mainStyle={mainStyle}
			selectMenuItemHandler={selectMenuItemHandler}
			activeItem={activeItem}
		/>
	);
};

const mapStateToProps = (state: IReduxState) => ({
	mainStyle: state.header.mainStyle,
	activeItem: state.header.activeItem,
	menu: state.menu,
});

export default connect(mapStateToProps)(HeaderController);
