import {
	IMainPageState,
	MainPageActionTypes,
	SET_FEATURED_MEDIA,
	SET_NEWS,
	SET_SLIDER,
} from './types';

const initialState: IMainPageState = {
	slider: [],
	news: [],
	media: [],
};

const mainPageReducer = (
	state = initialState,
	action: MainPageActionTypes
): IMainPageState => {
	switch (action.type) {
		case SET_SLIDER:
			return { ...state, slider: action.slider };
		case SET_NEWS:
			return { ...state, news: action.news };
		case SET_FEATURED_MEDIA:
			return { ...state, media: action.media };
		default:
			return state;
	}
};

export default mainPageReducer;
