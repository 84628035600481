import { StaffModel } from "./../../Models/Staff.model";
import { TeamPageModel } from "../../Models/TeamPage.model";

// Action types
export const SET_TEAM = "SET_TEAM";
export const SET_STAFF = "SET_STAFF";

// State interface
export interface ITeamPageState {
  team: TeamPageModel;
  staff: StaffModel[];
}

// Actions interface
interface ITeamDataAction {
  type: typeof SET_TEAM;
  team: TeamPageModel;
}

interface IStaffDataAction {
  type: typeof SET_STAFF;
  staff: StaffModel[];
}

export type TeamPageActionTypes = ITeamDataAction | IStaffDataAction;
