import React, { useEffect } from 'react';
import Breadcrumb from '../../UI/Breadcrumb/Controller/Breadcrumb.controller';
import {
	NewsContainer,
	NewsContentContainer,
	NewCard,
	NewCardTexts,
	NewCardDescription,
	NewCardTitle,
	NewCardAuthor,
	NewCardWrapper,
	NewsPaginationContainer,
} from './NewsPage.style';
import { Container, Row, Col } from 'react-bootstrap';
import NextMatchWidget from '../../UI/NextMatchWidget/Controller/NextMatchWidget.controller';
import { NewsPageModel } from '../../../Models/NewsPage.model';
import HTMLParser from 'html-react-parser';
import moment from 'moment';
import 'moment/locale/hu';
import ReactPaginate from 'react-paginate';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { Link, RouteComponentProps } from 'react-router-dom';

export interface NewsPageViewProps extends RouteComponentProps {
	newsPageData: NewsPageModel;
	selectedPageHandler: (selected: number) => void;
}

const NewsPageView: React.FC<NewsPageViewProps> = (props) => {
	const { newsPageData, selectedPageHandler } = props;
	useEffect(() => {
		window.scrollTo(0, 0);
	}, [newsPageData]);
	if (newsPageData.data.length === 0)
		return (
			<div
				style={{
					textAlign: 'center',
					padding: '50px 0',
					fontWeight: 'bold',
					fontSize: '24px',
					textTransform: 'uppercase',
				}}
			>
				Nincs találat!
				<br />
				<br />
				<Link to='/hirek' style={{ color: 'black' }}>
					<em>Vissza a hírek oldalra!</em>
				</Link>
			</div>
		);
	return (
		<NewsContainer>
			<Breadcrumb />
			<NewsContentContainer>
				<Container>
					<Row>
						<Col xs='12' lg='8' xl='9'>
							{newsPageData.data.map((d) => (
								<NewCardWrapper key={d.id} to={`hirek/${d.slug}`}>
									<NewCard BgImage={d.f_image.medium_large}>
										<NewCardTexts>
											<NewCardTitle>
												{HTMLParser(d.title.rendered)}
											</NewCardTitle>
											<NewCardDescription>
												{HTMLParser(d.excerpt.rendered)}
											</NewCardDescription>
											<NewCardAuthor>
												{moment(d.date).format('L')} írta{' '}
												{d._embedded.author[0].name}
											</NewCardAuthor>
										</NewCardTexts>
									</NewCard>
								</NewCardWrapper>
							))}
							<NewsPaginationContainer>
								<ReactPaginate
									pageCount={newsPageData.headers['x-wp-totalpages']}
									pageRangeDisplayed={4}
									marginPagesDisplayed={0}
									initialPage={0}
									previousLabel={<FontAwesomeIcon icon={faArrowLeft} />}
									nextLabel={<FontAwesomeIcon icon={faArrowRight} />}
									containerClassName='paginateContainer'
									pageClassName='pageElement'
									previousClassName='prev'
									nextClassName='next'
									activeClassName='active'
									onPageChange={({ selected }) =>
										selectedPageHandler(selected + 1)
									}
								/>
							</NewsPaginationContainer>
						</Col>
						<Col xs='12' lg='4' xl='3'>
							<NextMatchWidget />
						</Col>
					</Row>
				</Container>
			</NewsContentContainer>
		</NewsContainer>
	);
};

export default NewsPageView;
