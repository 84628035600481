import {
  IMatchOverviewState,
  MatchOverviewActionTypes,
  SET_OVERVIEW,
} from "./types";

const initialState: IMatchOverviewState = {
  overview: [],
};

const widgetsReducer = (
  state = initialState,
  action:  MatchOverviewActionTypes
): IMatchOverviewState => {
  switch (action.type) {
    case SET_OVERVIEW:
      return { ...state, overview: action.overview };
    default:
      return state;
  }
};

export default widgetsReducer;
