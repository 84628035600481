import {
  IWidgetsState,
  WidgetsActionTypes,
  SET_NEXT_MATCH,
  SET_INTERESTED_IN,
} from "./types";

const initialState: IWidgetsState = {
  nextMatch: { full: [] },
  interestedIn: [],
};

const widgetsReducer = (
  state = initialState,
  action: WidgetsActionTypes
): IWidgetsState => {
  switch (action.type) {
    case SET_NEXT_MATCH:
      return { ...state, nextMatch: action.nextMatch };
    case SET_INTERESTED_IN:
      return { ...state, interestedIn: action.interestedIn };
    default:
      return state;
  }
};

export default widgetsReducer;
