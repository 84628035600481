import {
  SET_HEADER_STYLE,
  HeaderActionTypes,
  SET_ACTIVE_ITEM_ID,
} from "./types";

export const changeMainStyle = (mainStyle: boolean): HeaderActionTypes => {
  return {
    type: SET_HEADER_STYLE,
    mainStyle: mainStyle,
  };
};

export const setActiveItem = (activeItem: string): HeaderActionTypes => {
  return {
    type: SET_ACTIVE_ITEM_ID,
    activeItem: activeItem,
  };
};
