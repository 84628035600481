import React, { useRef } from 'react';
import { MenuModel } from '../../../Models/Menu.model';
import { IMenuItem } from '../../../Interfaces/IMenu.interface';
import { Container, Row } from 'react-bootstrap';
import {
	BeforeMenuWrapper,
	BeforeMenu,
	HeaderContainer,
	DropdownButton,
	DropdownContainer,
	Dropdown,
	DropdownElement,
	SocialsAndSearch,
	SocialsContainer,
	SocialsTitle,
	SearchContainer,
	SearchInput,
	SearchButton,
	BeforeMenuLogo,
	MenuWrapper,
	MenuContainer,
	MenuLogo,
	MenuItemsContainer,
	MenuItem,
	ResponsiveDrawerMenu,
	ResponsiveDrawerSearch,
	ResponsiveDrawerSearchButton,
	ResponsiveDrawerContent,
	ResponsiveDrawerItem,
	ResponsiveDrawerFooter,
	ResponsiveDrawerMenuContainer,
	MobileMenuButton,
	DropdownButtonContainer,
	MobileMenuButtonLine,
	MobileMenuButtonContainer,
	GrayBG,
} from './Style';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faSearch } from '@fortawesome/free-solid-svg-icons';
import {
	faFacebookSquare,
	faInstagram,
} from '@fortawesome/free-brands-svg-icons';
import Logo from '../../../Assets/Images/DEAC_Logo_labdarugas.png';
import Logo_B from '../../../Assets/Images/DEAC_Logo_labdarugas-b.png';
import { DeacSections } from '../../../Static/DeacSections';
import { Link, RouteChildrenProps } from 'react-router-dom';
import { theme } from '../../../Global/Variables';

export interface IHeaderViewProps extends RouteChildrenProps {
	menu: MenuModel;
	dropdownStatus: boolean;
	dropdownHandler: (status: boolean) => void;
	drawerStatus: boolean;
	drawerHandler: (status: boolean) => void;
	mainStyle?: boolean;
	selectMenuItemHandler: (slug: string) => void;
	activeItem: string;
}

const HeaderView: React.FC<IHeaderViewProps> = (props): React.ReactElement => {
	const {
		menu,
		dropdownStatus,
		dropdownHandler,
		drawerStatus,
		drawerHandler,
		mainStyle,
		selectMenuItemHandler,
		activeItem,
	} = props;
	const inputRef = useRef<HTMLInputElement>(null);
	const mobilInputRef = useRef<HTMLInputElement>(null);
	const handleSearch = () => {
		if (inputRef.current && inputRef.current.value !== '')
			props.history.push({
				pathname: '/hirek',
				search: `${inputRef.current.value}`,
			});
	};
	const handleMobileSearch = () => {
		drawerHandler(false);
		if (mobilInputRef.current && mobilInputRef.current.value !== '')
			props.history.push({
				pathname: '/hirek',
				search: `${mobilInputRef.current.value}`,
			});
	};

	return (
		<HeaderContainer>
			<GrayBG isVisible={drawerStatus} />
			<BeforeMenuWrapper>
				<Container fluid='lg'>
					<Row>
						<BeforeMenu>
							<DropdownContainer>
								<DropdownButtonContainer>
									<DropdownButton
										onClick={() => dropdownHandler(!dropdownStatus)}
										show={dropdownStatus}
									>
										További szakosztályok
										<FontAwesomeIcon icon={faChevronDown} />
									</DropdownButton>
									<MobileMenuButtonContainer
										onClick={() => drawerHandler(!drawerStatus)}
									>
										<MobileMenuButton drawerStatus={drawerStatus}>
											<MobileMenuButtonLine right />
											<MobileMenuButtonLine full />
											<MobileMenuButtonLine left />
										</MobileMenuButton>
									</MobileMenuButtonContainer>
								</DropdownButtonContainer>
								<Dropdown
									style={{ originY: 0 }}
									initial={['inactive', 'hide']}
									animate={
										dropdownStatus ? ['active', 'show'] : ['inactive', 'hide']
									}
									variants={{
										active: {
											height: 'auto',
											transition: { duration: 1, type: 'spring' },
										},
										show: {
											opacity: 1,
											border: `1px solid ${theme.colors.dropdown_line_color}`,
											transition: { duration: 0.5 },
										},
										inactive: {
											height: 0,
											transition: { duration: 1, type: 'spring' },
										},
										hide: {
											opacity: 0,
											border: 'none',
											transition: { duration: 0.5 },
										},
									}}
								>
									{DeacSections.map((section, index) => (
										<DropdownElement key={index}>
											<a href={section.url}>
												<img src={section.logo} alt={section.name} />
											</a>
										</DropdownElement>
									))}
								</Dropdown>
							</DropdownContainer>
							<SocialsAndSearch>
								<SocialsContainer>
									<SocialsTitle>Kövess minket</SocialsTitle>
									<a
										target='_blank'
										rel='noopener noreferrer'
										href='https://www.facebook.com/CSAKADEAC/'
									>
										<FontAwesomeIcon icon={faFacebookSquare} />
									</a>
									<a
										target='_blank'
										rel='noopener noreferrer'
										href='https://www.instagram.com/deacfootball/'
									>
										<FontAwesomeIcon icon={faInstagram} />
									</a>
								</SocialsContainer>
								<SearchContainer>
									<SearchInput
										width='290px'
										darkTheme={true}
										type='text'
										placeholder='Keresés...'
										ref={inputRef}
									/>
									<SearchButton onClick={handleSearch}>
										<FontAwesomeIcon icon={faSearch} />
									</SearchButton>
								</SearchContainer>
							</SocialsAndSearch>
						</BeforeMenu>
						<BeforeMenuLogo to='/'>
							<img src={Logo} alt='Logo' />
						</BeforeMenuLogo>
					</Row>
				</Container>
			</BeforeMenuWrapper>
			<MenuWrapper main={mainStyle}>
				<Container fluid='lg'>
					<Row>
						<MenuContainer>
							{mainStyle ? (
								<MenuLogo to='/' onClick={() => selectMenuItemHandler('')}>
									<img src={Logo} alt='Logo White' />
								</MenuLogo>
							) : (
								<MenuLogo to='/' onClick={() => selectMenuItemHandler('')}>
									<img src={Logo_B} alt='Logo Black' />
								</MenuLogo>
							)}
							<MenuItemsContainer>
								{menu.items.map((item: IMenuItem, index: number) =>
									item.type === 'custom' ? (
										<a
											key={index}
											href={item.url}
											target='_blank'
											rel='noopener noreferrer'
										>
											<MenuItem
												title={item.title}
												className={
													activeItem === item.object_slug ? 'active' : ''
												}
											>
												{item.title}
											</MenuItem>
										</a>
									) : (
										<Link
											key={index}
											to={`/${item.object_slug}`}
											onClick={() => selectMenuItemHandler(item.object_slug)}
										>
											<MenuItem
												title={item.title}
												className={
													activeItem === item.object_slug ? 'active' : ''
												}
											>
												{item.title}
											</MenuItem>
										</Link>
									)
								)}
							</MenuItemsContainer>
						</MenuContainer>
					</Row>
				</Container>
			</MenuWrapper>
			<ResponsiveDrawerMenu
				style={{ originX: 0 }}
				initial={['inactive', 'hide']}
				animate={drawerStatus ? ['active', 'show'] : ['inactive', 'hide']}
				variants={{
					active: {
						width: window.outerWidth > 320 ? '75%' : '100%',
						opacity: 1,
						transition: { duration: 0.5, type: 'tween' },
					},
					show: {
						visibility: 'visible',
					},
					inactive: {
						width: 0,
						opacity: 0,
						transition: { duration: 0.5, type: 'tween' },
					},
					hide: {
						visibility: 'hidden',
						transition: { delay: 0.5 },
					},
				}}
			>
				<ResponsiveDrawerMenuContainer>
					<ResponsiveDrawerSearch>
						<SearchInput
							darkTheme={false}
							type='text'
							placeholder='Keresés'
							ref={mobilInputRef}
						/>
						<ResponsiveDrawerSearchButton>
							<FontAwesomeIcon icon={faSearch} onClick={handleMobileSearch} />
						</ResponsiveDrawerSearchButton>
					</ResponsiveDrawerSearch>
					<ResponsiveDrawerContent>
						{menu.items.map((item: IMenuItem, index: number) =>
							item.type === 'custom' ? (
								<a
									key={index}
									href={item.url}
									onClick={() => {
										drawerHandler(false);
									}}
									target='_blank'
									rel='noopener noreferrer'
								>
									<ResponsiveDrawerItem
										title={item.title}
										show={drawerStatus}
										className={activeItem === item.object_slug ? 'active' : ''}
									>
										{item.title}
									</ResponsiveDrawerItem>
								</a>
							) : (
								<Link
									key={index}
									to={`/${item.object_slug}`}
									onClick={() => {
										selectMenuItemHandler(item.object_slug);
										drawerHandler(false);
									}}
								>
									<ResponsiveDrawerItem
										title={item.title}
										show={drawerStatus}
										className={activeItem === item.object_slug ? 'active' : ''}
									>
										{item.title}
									</ResponsiveDrawerItem>
								</Link>
							)
						)}
					</ResponsiveDrawerContent>
					<ResponsiveDrawerFooter>
						<SocialsTitle>Kövess minket</SocialsTitle>
						<SocialsContainer>
							<a
								target='_blank'
								rel='noopener noreferrer'
								href='https://www.facebook.com/CSAKADEAC/'
							>
								<FontAwesomeIcon icon={faFacebookSquare} />
							</a>
							<a
								target='_blank'
								rel='noopener noreferrer'
								href='https://www.instagram.com/deacfootball/'
							>
								<FontAwesomeIcon icon={faInstagram} />
							</a>
						</SocialsContainer>
					</ResponsiveDrawerFooter>
				</ResponsiveDrawerMenuContainer>
			</ResponsiveDrawerMenu>
		</HeaderContainer>
	);
};

export default HeaderView;
