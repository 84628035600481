import { axios } from "../API/axios";
import { IPost } from "../Interfaces/ISingleNews.interface";
import { AxiosPromise, AxiosResponse } from "axios";
import { interestedPostsRoute } from "../Static/ApiRoutes";
import { SingleNewsModel as InterestedInModel } from "../Models/SingleNews.model";

export default abstract class InterestedInWidgetDAO {
  private static readonly base: string = interestedPostsRoute;

  public static async getInterestedIn(): Promise<InterestedInModel[]> {
    const interestedInReq: AxiosPromise<IPost[]> = axios
      .getInstance()
      .get(this.base);
    const interestedInResp: AxiosResponse<IPost[]> = await interestedInReq;
    return interestedInResp.data;
  }
}
