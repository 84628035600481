import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { RouteChildrenProps } from 'react-router-dom';
import Breadcrumb from '../../UI/Breadcrumb/Controller/Breadcrumb.controller';
import ContactBoxView from './ContactBox.view';
import ContactPageForm from './ContactPageForm.view';

export interface IContactViewProps extends RouteChildrenProps {}

const ContactView: React.FC<IContactViewProps> = (props) => {
	if (!props) return <></>;
	return (
		<>
			<Breadcrumb />
			<Container>
				<Row>
					<Col lg='4' sm='12'>
						<ContactBoxView
							title='Elérhetőségeink'
							location='4032 Debrecen, Dóczy József u. 9.'
							mail='hello@deac.hu'
							tel='+36 (52) 512-721 mellék: 23721'
						/>
						<ContactBoxView
							title='Futballpálya'
							location='4032 Debrecen, Dóczy József u. 9.'
							mail='hello@deac.hu'
						/>
						<ContactBoxView
							title='Sajtókapcsolat'
							text='Pénzes-Kemecsei Zsuzsa'
							mail='sajto@deac.hu'
							tel='+36 (52) 512-900 mellék: 23250'
						/>
					</Col>
					<Col lg='8' sm='12'>
						<ContactPageForm />
					</Col>
				</Row>
			</Container>
			<iframe
				title='DEAC'
				style={{ border: '0' }}
				src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2692.6640968706256!2d21.61484951595914!3d47.55486597918113!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47470df510ea9155%3A0x9c33a3523b1bf5f9!2sDEAC%20Egyetemi%20Sportcentrum!5e0!3m2!1shu!2shu!4v1570116331481!5m2!1shu!2shu'
				width='100%'
				height='600'
				frameBorder='0'
				allowFullScreen={false}
			/>
			<div style={{ marginBottom: '-10px' }}></div>
		</>
	);
};

export default ContactView;
