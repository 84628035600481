import { NewsPageModel } from "./../../Models/NewsPage.model";
import {
  INewsPageState,
  SET_NEWS_PAGE_DATA,
  NewsPageActionTypes,
} from "./types";

const initialState: INewsPageState = new NewsPageModel();

const widgetsReducer = (
  state = initialState,
  action: NewsPageActionTypes
): INewsPageState => {
  switch (action.type) {
    case SET_NEWS_PAGE_DATA:
      return { ...action.newsPageData };
    default:
      return state;
  }
};

export default widgetsReducer;
