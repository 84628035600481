import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { MediaModel } from '../../../Models/Media.model';
import { MediaCard } from '../../UI/MediaCard/MediaCard';
import { MediaWrapper } from './style/Media.style';
import { RouteChildrenProps } from 'react-router-dom';
import Breadcrumb from '../../UI/Breadcrumb/Controller/Breadcrumb.controller';

export interface IMediaViewProps extends RouteChildrenProps {
	media: MediaModel[];
}

const MediaView: React.FC<IMediaViewProps> = (props): React.ReactElement => {
	if (!props) return <></>;

	return (
		<MediaWrapper>
			<Breadcrumb />
			<Container>
				<div style={{ marginTop: '20px' }}></div>
				<Row>
					{props.media.map((m) => {
						return (
							<Col lg='4' sm='12' key={`mid-${m.id}`}>
								<MediaCard
									featured_image={`https://labdarugas.deac.hu/${m.preview_image}`}
									link={`/media/${m.slug}`}
									title={m.name}
								/>
							</Col>
						);
					})}
				</Row>
			</Container>
		</MediaWrapper>
	);
};

export default MediaView;
