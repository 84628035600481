import { ModelConstructor } from '../Utils/DataSetter.util';
import { IInfoPage } from '../Interfaces/IInfoPage.interface';

export class InfoPageModel {
	constructor(InfoPageModel?: IInfoPage) {
		if (InfoPageModel) {
			ModelConstructor(InfoPageModel, this);
		}
	}
	acf = { rolunk: '' };
}
