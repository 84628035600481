import { InfoPageModel } from "../../Models/InfoPage.model";

// Action types
export const SET_INFO_PAGE_DATA = "SET_INFO_PAGE_DATA";

// State interface
export interface IInfoPageState extends InfoPageModel {}

// Actions interface

interface IInfoDataAction {
  type: typeof SET_INFO_PAGE_DATA;
  infoData: InfoPageModel;
}

export type InfoPageActionTypes = IInfoDataAction;
