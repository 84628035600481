import React, { useState } from 'react';
import { Tab } from '../../UI/Tab/Tab';
import { Container, Row, Col } from 'react-bootstrap';
import {
	MatchPageWrapper,
	AwayInfoContainer,
	HomeInfoContainer,
	MatchContainer,
	ResultInfoButton,
	ResultInfoContainer,
	ResultInfoData,
	ResultInfoDate,
	TeamInfoLogo,
	TeamInfoTitle,
	UpcomingInfoContainer,
	UpcomingInfoTime,
	UpcomingInfoDate,
	UpcomingInfoVenue,
	FeaturedEventContainer,
	FeaturedTeamContainer,
	FeaturedTeamLogo,
	FeaturedTeamName,
	FeaturedContent,
	FeaturedInfoContainer,
	FeaturedInfoText,
} from '../View/MatchPage.style';
import { CalendarModel } from '../../../Models/Calendar.model';
import moment from 'moment';
import 'moment/locale/hu';
import CountDown from '../../UI/CountDown/Controller/CountDown.controller';
import { Link } from 'react-router-dom';
import sort from 'fast-sort';
import Breadcrumb from '../../UI/Breadcrumb/Controller/Breadcrumb.controller';

export interface MatchPageViewProps {
	upcoming: CalendarModel;
	results: CalendarModel;
}

const MatchPageView: React.FC<MatchPageViewProps> = (props) => {
	const { results, upcoming } = props;
	const [active, setActive] = useState<number>(0);
	const tabData = ['Eredmények', 'Következő'];
	const changeActiveTab = (d: string): void => {
		tabData.forEach((td: string, idx: number) => {
			if (td === d) setActive(idx);
		});
	};

	const sortedResults = sort([...results.full]).by([
		{
			desc: (match) => match.post_date,
		},
	]);

	if (!results.full.length || !upcoming.full.length) {
		return <></>;
	} else {
		return (
			<>
				<Breadcrumb />
				<MatchPageWrapper>
					<Container>
						<Row>
							<Col xs='12'>
								<Tab
									data={tabData}
									active={active}
									callback={changeActiveTab}
								/>
							</Col>
						</Row>
						<Row>
							{active === 0
								? sortedResults.map((result) => (
										<Col xs='12' key={result.ID}>
											<MatchContainer>
												<Col xs='3' md='4'>
													<HomeInfoContainer>
														<TeamInfoLogo
															src={result.f_image0}
															alt={result.team0.post.post_title}
														/>
														<TeamInfoTitle>
															{result.team0.post.post_title}
														</TeamInfoTitle>
													</HomeInfoContainer>
												</Col>
												<Col xs='6' md='4'>
													<ResultInfoContainer>
														<ResultInfoDate>
															{moment(result.post_date).format(
																'YYYY-MM-DD. - HH:mm - dddd'
															)}
														</ResultInfoDate>
														<ResultInfoData>
															{result.team0goals?.twoflid}
															{' - '}
															{result.team1goals?.twoflid}
														</ResultInfoData>
														<Link to={`/merkozesek/${result.post_name}`}>
															<ResultInfoButton>Meccs riport</ResultInfoButton>
														</Link>
													</ResultInfoContainer>
												</Col>
												<Col xs='3' md='4'>
													<AwayInfoContainer>
														<TeamInfoTitle>
															{result.team1.post.post_title}
														</TeamInfoTitle>
														<TeamInfoLogo
															src={result.f_image1}
															alt={result.team1.post.post_title}
														/>
													</AwayInfoContainer>
												</Col>
											</MatchContainer>
										</Col>
								  ))
								: upcoming.full.map((upcoming, index) => (
										<Col xs='12' key={upcoming.ID}>
											{index === 0 ? (
												<FeaturedEventContainer>
													<Col xs='6' md='3'>
														<FeaturedTeamContainer>
															<FeaturedTeamLogo
																src={upcoming.f_image0}
																alt={upcoming.team0.post.post_title}
															/>
															<FeaturedTeamName>
																{upcoming.team0.post.post_title}
															</FeaturedTeamName>
														</FeaturedTeamContainer>
													</Col>
													<Col md='6' className='d-none d-md-block'>
														<FeaturedContent>
															<CountDown date={upcoming.post_date} />
															<FeaturedInfoContainer>
																<FeaturedInfoText>
																	{moment(upcoming.post_date).format(
																		'YYYY-MM-DD. - HH:mm - dddd'
																	)}
																</FeaturedInfoText>
																<FeaturedInfoText>
																	{upcoming.event_venue.length
																		? upcoming.event_venue[0].name
																		: '-'}
																</FeaturedInfoText>
															</FeaturedInfoContainer>
														</FeaturedContent>
													</Col>
													<Col xs='6' md='3'>
														<FeaturedTeamContainer>
															<FeaturedTeamLogo
																src={upcoming.f_image1}
																alt={upcoming.team1.post.post_title}
															/>
															<FeaturedTeamName>
																{upcoming.team1.post.post_title}
															</FeaturedTeamName>
														</FeaturedTeamContainer>
													</Col>
													<Col xs='12' className='d-md-none'>
														<FeaturedContent>
															<CountDown date={upcoming.post_date} />
															<FeaturedInfoContainer>
																<FeaturedInfoText>
																	{moment(upcoming.post_date).format(
																		'YYYY-MM-DD. - HH:mm - dddd'
																	)}
																</FeaturedInfoText>
																<FeaturedInfoText>
																	{upcoming.event_venue.length
																		? upcoming.event_venue[0].name
																		: '-'}
																</FeaturedInfoText>
															</FeaturedInfoContainer>
														</FeaturedContent>
													</Col>
												</FeaturedEventContainer>
											) : (
												<MatchContainer>
													<Col xs='3' md='4'>
														<HomeInfoContainer>
															<TeamInfoLogo
																src={upcoming.f_image0}
																alt={upcoming.team0.post.post_title}
															/>
															<TeamInfoTitle>
																{upcoming.team0.post.post_title}
															</TeamInfoTitle>
														</HomeInfoContainer>
													</Col>
													<Col xs='6' md='4'>
														<UpcomingInfoContainer>
															<UpcomingInfoDate>
																{moment(upcoming.post_date).format(
																	'YYYY-MM-DD dddd'
																)}
															</UpcomingInfoDate>
															<UpcomingInfoTime>
																{moment(upcoming.post_date).format('HH:mm')}
															</UpcomingInfoTime>
															<UpcomingInfoVenue>
																{upcoming.event_venue.length
																	? upcoming.event_venue[0].name
																	: '-'}
															</UpcomingInfoVenue>
														</UpcomingInfoContainer>
													</Col>
													<Col xs='3' md='4'>
														<AwayInfoContainer>
															<TeamInfoTitle>
																{upcoming.team1.post.post_title}
															</TeamInfoTitle>
															<TeamInfoLogo
																src={upcoming.f_image1}
																alt={upcoming.team1.post.post_title}
															/>
														</AwayInfoContainer>
													</Col>
												</MatchContainer>
											)}
										</Col>
								  ))}
						</Row>
					</Container>
				</MatchPageWrapper>
			</>
		);
	}
};

export default MatchPageView;
