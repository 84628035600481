import * as React from "react";
import {
  NextMatchWidgetContainer,
  WidgetTitleContainer,
  WidgetTitle,
  WidgetTitleBagde,
  WidgetContent,
  WidgetItem,
  WidgetItemButton,
  WidgetItemInfos,
  WidgetItemLogo,
  WidgetItemLogos,
  WidgetItemTitle,
  WidgetItemTitleContainer,
} from "./Style";
import moment from "moment";
import "moment/locale/hu";
import { google } from "calendar-link";
import { CalendarModel } from "../../../../Models/Calendar.model";
import ButtonView from "../../Button/View/Button.view";

export interface NextMatchWidgetViewProps {
  nextMatch: CalendarModel;
}

const NextMatchWidgetView: React.FC<NextMatchWidgetViewProps> = (props) => {
  if (!props.nextMatch.full.length) {
    return <></>;
  } else {
    return (
      <NextMatchWidgetContainer>
        <WidgetTitleContainer>
          <WidgetTitleBagde>Most</WidgetTitleBagde>
          <WidgetTitle>Következik</WidgetTitle>
        </WidgetTitleContainer>
        <WidgetContent>
          <WidgetItem>
            <WidgetItemLogos>
              <WidgetItemLogo
                src={props.nextMatch.full[0]?.f_image0}
                alt={props.nextMatch.full[0]?.team0.post.post_title}
              />
              <WidgetItemLogo
                src={props.nextMatch.full[0]?.f_image1}
                alt={props.nextMatch.full[0]?.team1.post.post_title}
              />
            </WidgetItemLogos>
            <WidgetItemTitleContainer>
              <WidgetItemTitle>
                {props.nextMatch.full[0]?.team0.post.post_title}
              </WidgetItemTitle>
              <span> Vs </span>
              <WidgetItemTitle>
                {props.nextMatch.full[0]?.team1.post.post_title}
              </WidgetItemTitle>
            </WidgetItemTitleContainer>
            <WidgetItemInfos>
              <p>
                {moment(props.nextMatch.full[0]?.post_date).format(
                  "YYYY-MM-DD hh:mm dddd"
                )}
              </p>
              <p>
                {props.nextMatch.full[0]?.event_venue
                  ? props.nextMatch.full[0]?.event_venue[0]?.name
                  : "-"}
              </p>
            </WidgetItemInfos>
            <WidgetItemButton>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={google({
                  title: props.nextMatch.full[0]?.post_title,
                  start: props.nextMatch.full[0]?.post_date,
                  end: props.nextMatch.full[0]?.post_date,
                  location: props.nextMatch.full[0]?.event_venue
                    ? props.nextMatch.full[0]?.event_venue[0]?.name
                    : "",
                })}
              >
                <ButtonView width="160px" height="45px">
                  Naptárba
                </ButtonView>
              </a>
            </WidgetItemButton>
          </WidgetItem>
        </WidgetContent>
      </NextMatchWidgetContainer>
    );
  }
};

export default NextMatchWidgetView;
