import { CalendarModel } from "../../Models/Calendar.model";
import {
  ICalendarState,
  CalendarActionTypes,
  SET_LASTEST,
  SET_UPCOMING,
} from "./types";

const initialState: ICalendarState = {
  upcoming: new CalendarModel(),
  lastest: new CalendarModel(),
};

const mainPageReducer = (
  state = initialState,
  action: CalendarActionTypes
): ICalendarState => {
  switch (action.type) {
    case SET_UPCOMING:
      return { ...state, upcoming: action.upcoming };
    case SET_LASTEST:
      return { ...state, lastest: action.lastest };
    default:
      return state;
  }
};

export default mainPageReducer;
