import styled from 'styled-components';
import * as theme from '../../../../Global/Variables';

export const NewsWrapper = styled.section`
	margin: 80px 0;
	.col-3 {
		padding-right: 0;
	}
`;

export const ArticleWrapper = styled.article``;
export const ArticleImage = styled.div`
	overflow: hidden;

	img {
		width: 100%;
		max-width: 100%;
		transition: all 0.3s ease-in-out;
		&:hover {
			transform: scale(1.2);
			filter: grayscale(50%);
		}
	}
`;
export const ArticleDate = styled.div`
	background: ${theme.theme.colors.secondary};
	width: 100%;
	color: white;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 10px 0;
	position: relative;
	top: -30px;
	font-weight: 900;
	font-size: 28px;
	span:first-child {
		line-height: 28px;
	}
	span:last-child {
		font-size: 12px;
		font-weight: 300;
		text-transform: uppercase;
	}
`;
export const ArticleTitle = styled.div`
	margin: 10px 0;
	a {
		font-size: 18px;
		font-weight: 900;
		line-height: 20px;
		color: black !important;
		text-decoration: none !important;
	}
`;
export const ArticleExcerpt = styled.div`
	font-size: 16px;
	font-weight: 200;
	display: flex;
`;
