import React, { useEffect } from "react";
import { useDispatch, connect } from "react-redux";
import { SinglePlayerModel } from "../../../Models/SinglePlayer.model";
import SinglePlayerView from "../View/SinglePlayer.view";
import * as playerActions from "../../../Redux/SinglePlayerPage/actions";
import IReduxState from "../../../Interfaces/IRedux.interface";

interface SinglePlayerControllerProps {
  player: SinglePlayerModel[];
}

const SinglePlayerController: React.FC<SinglePlayerControllerProps> = (
  props
) => {
  const dispatch = useDispatch();
  useEffect(() => {
    loadPlayer();
    // eslint-disable-next-line
  }, []);

  const loadPlayer = () => {
    dispatch(playerActions.getPlayer(window.location.pathname.split("/")[2]));
  };
  return <SinglePlayerView player={props.player} />;
};

const mapStateToProps = (state: IReduxState) => ({
  player: state.playerPage.player,
});

export default connect(mapStateToProps)(SinglePlayerController);
