import { FooterActionTypes, IFooterState, SET_SPONSORS } from "./types";

const initialState: IFooterState = {
  sponsors: [],
};

const footerReducer = (
  state = initialState,
  action: FooterActionTypes
): IFooterState => {
  switch (action.type) {
    case SET_SPONSORS:
      return { ...state, sponsors: action.sponsors };
    default:
      return state;
  }
};

export default footerReducer;
