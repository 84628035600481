import { WidgetsActionTypes, SET_NEXT_MATCH, SET_INTERESTED_IN } from "./types";
import { CalendarModel } from "../../Models/Calendar.model";
import { Dispatch } from "redux";
import IReduxState from "../../Interfaces/IRedux.interface";
import { setError /* , setLoading  */ } from "../Status/actions";
import { IError } from "../../Interfaces/IError.interface";
import NextMatchWidgetDAO from "../../DAOs/NextMatchWidget.DAO";
import InterestedInWidgetDAO from "../../DAOs/InterestedInWidget.DAO";
import { SingleNewsModel as InterestedInModel } from "../../Models/SingleNews.model";

const setNextMatch = (nextMatch: CalendarModel): WidgetsActionTypes => {
  return {
    type: SET_NEXT_MATCH,
    nextMatch: nextMatch,
  };
};

const setInterestedIn = (
  interestedIn: InterestedInModel[]
): WidgetsActionTypes => {
  return {
    type: SET_INTERESTED_IN,
    interestedIn: interestedIn,
  };
};

export const getNextMatch = () => {
  return async (dispatch: Dispatch, getState: () => IReduxState) => {
    /* dispatch(
      setLoading( */
    NextMatchWidgetDAO.getNext()
      .then((next: CalendarModel) => {
        if (
          JSON.stringify(getState().widgets.nextMatch) !== JSON.stringify(next)
        ) {
          dispatch(setNextMatch(next));
        }
      })
      .catch((error: IError) => dispatch(setError(error)));
    /*       )
    ); */
  };
};

export const getInterestedIn = () => {
  return async (dispatch: Dispatch, getState: () => IReduxState) => {
    /* dispatch(
      setLoading( */
    InterestedInWidgetDAO.getInterestedIn()
      .then((interestedIn: InterestedInModel[]) => {
        if (
          JSON.stringify(getState().widgets.interestedIn) !==
          JSON.stringify(interestedIn)
        ) {
          dispatch(setInterestedIn(interestedIn));
        }
      })
      .catch((error: IError) => dispatch(setError(error)));
    /*       )
    ); */
  };
};
