import React from 'react';
import BreadcrumbView from '../View/Breadcrumb.view';

export interface BreadcrumbControllerProps {
	third_level?: string | undefined;
}

const BreadcrumbController: React.FC<BreadcrumbControllerProps> = (props) => {
	return (
		<BreadcrumbView
			breadcrumb={[
				'Főoldal',
				...window.location.pathname.split('/').filter((str) => str),
			]}
			third_level={props.third_level}
		/>
	);
};

export default BreadcrumbController;
