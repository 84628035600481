import { MatchOverviewModel } from "./../../Models/MatchOverview.model";
import { SET_OVERVIEW, MatchOverviewActionTypes } from "./types";
import { Dispatch } from "redux";
import IReduxState from "../../Interfaces/IRedux.interface";
import { setError, setFull, setLoading } from "../Status/actions";
import { IError } from "../../Interfaces/IError.interface";
import MatchOverviewDAO from "../../DAOs/MatchOverview.DAO";

const setMatchOverview = (
  overview: MatchOverviewModel[]
): MatchOverviewActionTypes => {
  return {
    type: SET_OVERVIEW,
    overview: overview,
  };
};

export const getMatchOverview = (slug: string) => {
  return async (dispatch: Dispatch, getState: () => IReduxState) => {
    if (getState().matchOverviewPage.overview[0]?.slug === slug) {
      dispatch(setFull(false));
    } else {
      dispatch(setFull(true));
    }

    dispatch(
      setLoading(
        MatchOverviewDAO.getData(slug)
          .then((overview: MatchOverviewModel[]) => {
            if (
              JSON.stringify(getState().matchOverviewPage.overview) !==
              JSON.stringify(overview)
            ) {
              dispatch(setMatchOverview(overview));
            }
          })
          .catch((error: IError) => {
            dispatch(setError(error));
          })
          .finally(() => {
            dispatch(setLoading(null));
          })
      )
    );
  };
};
