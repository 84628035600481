import styled from 'styled-components';
import Bg from '../../../../Assets/Images/mediabg.jpg';

export const MainMediaWrapper = styled.section`
	background: url(${Bg});
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
	padding: 60px 0 40px 0;
	color: white;
`;

export const MediaHead = styled.div`
	div {
		display: flex;
		justify-content: space-between;
		align-items: center;
		div {
			p,
			a {
				background-image: linear-gradient(to right, #8dcc26, #8dcc26);
				background-position: bottom center;
				background-repeat: no-repeat;
				background-size: 0% 5px;
				transition: background-size 0.3s ease-in-out,
					font-weight 0.15s ease-in-out;
				cursor: pointer;

				margin: 0;
				text-decoration: none;
				&:hover,
				&.active {
					font-weight: 900;
					background-size: 100% 5px;
				}
				font-size: 16px;
				font-weight: 300;
				text-transform: uppercase;
				&:first-child {
					margin-right: 30px;
				}
				&:after {
					display: block;
					content: attr(title);
					font-weight: 900;
					height: 0;
					overflow: hidden;
					visibility: hidden;
				}
			}
		}
	}
	a {
		font: 300 14px 'Rubik', sans-serif;
		color: white !important;
		position: relative;
		z-index: 1;
	}
`;

export const MediaBody = styled.div`
	margin: 40px 0;
`;
