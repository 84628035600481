import React from "react";
import { MainPageSliderModel } from "../../../Models/MainPageSlider.model";
import OwlCarousel from "react-owl-carousel2";
import "../../../../node_modules/react-owl-carousel2/lib/styles.css";
import { faLongArrowAltRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  OwlButton,
  OwlContents,
  OwlDesc,
  OwlText,
  OwlTitle,
  OwlWrapper,
} from "./style/MainSlider.style";
import HTMLParser from "html-react-parser";
import { Link } from "react-router-dom";

export interface IMainSliderViewProps {
  slider: MainPageSliderModel[];
}

const MainSliderView: React.FC<IMainSliderViewProps> = (
  props
): React.ReactElement => {
  if (!props.slider.length) return <></>;
  const options = {
    center: true,
    items: 1,
    loop: true,
    margin: 0,
    dots: false,
    nav: true,
    navText: [
      '<img class="owl-prev-arrow" src="./assets/images/long-arrow-left.png">',
      '<img class="owl-next-arrow" src="./assets/images/long-arrow-right.png">',
    ],
    responsive: {
      992: {
        items: 2,
      },
    },
  };

  const addLeftAndRight = (e: any) => {
    document.querySelector(".owl-item.left")?.classList.remove("left");
    document.querySelector(".owl-item.right")?.classList.remove("right");
    if (e.item.index) {
      document
        .querySelectorAll(".owl-item")
        [e.item.index - 1].classList.add("left");
      document
        .querySelectorAll(".owl-item")
        [e.item.index + 1].classList.add("right");
    }
  };

  const events = {
    onChanged: (e: any) => addLeftAndRight(e),
    onInitialized: (e: any) => addLeftAndRight(e),
  };

  return (
    <OwlWrapper>
      <OwlCarousel options={options} className="owl-carousel" events={events}>
        {props.slider.map((slide) => {
          return (
            <article
              key={slide.id}
              className="item"
              style={{
                backgroundImage: "url(" + slide.f_image.url + ")",
                backgroundSize: "cover",
              }}
            >
              <OwlContents>
                <OwlText>
                  <OwlTitle>{slide.title.rendered}</OwlTitle>
                  <OwlDesc>{HTMLParser(slide.excerpt.rendered)}</OwlDesc>
                </OwlText>
                <OwlButton>
                  <Link to={"hirek/" + slide.slug}>
                    Elolvasom <FontAwesomeIcon icon={faLongArrowAltRight} />
                  </Link>
                </OwlButton>
              </OwlContents>
            </article>
          );
        })}
      </OwlCarousel>
    </OwlWrapper>
  );
};

export default MainSliderView;
