import { createStore, applyMiddleware } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import thunk from "redux-thunk";

// reducer
import rootReducer from "./rootReducer";
/* import IReduxState from "../Interfaces/IRedux.interface"; */

const loadFromLocalStore = () => {
  const state = localStorage.getItem("state");
  try {
    if (typeof state === "string") {
      return JSON.parse(state);
    }
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
    return undefined;
  }
};

const store = createStore(
  rootReducer,
  loadFromLocalStore(),
  composeWithDevTools(applyMiddleware(thunk))
);

const saveToLocalStore = () => {
  try {
    localStorage.setItem("state", JSON.stringify(store.getState()));
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
  }
}; 

store.subscribe(() => saveToLocalStore());

export default store;
