import styled, { css } from 'styled-components';
import { theme } from '../../../../Global/Variables';

interface IGreenBGProps {
	alt?: boolean;
}

export const SinglePlayerWrapper = styled.div`
	padding: 20px 0;
	img {
		max-width: 100%;
	}
`;

export const SinglePlayerLead = styled.div`
	display: flex;
	align-items: center;
	margin-bottom: 20px;
	p {
		font-size: 24px;
		font-weight: 900;
		font-style: italic;
		text-transform: uppercase;
		margin: 0;
	}
`;

export const GreenBG = styled.span<IGreenBGProps>`
	font-weight: 900;
	font-size: 16px;
	color: ${theme.colors.tertiary};
	margin: 0;
	text-transform: uppercase;
	background-color: ${theme.colors.secondary};
	margin-right: 5px;
	padding: 5px 25px 5px 5px;
	clip-path: polygon(0 0, 100% 0%, 85% 100%, 0% 100%);
	${({ alt }) =>
		alt &&
		css`
			font-size: 14px;
			font-weight: 600;
			font-style: italic;
			padding: 2px 15px 2px 5px;
		`}
`;

export const SinglePlayerBox = styled.div`
	background: ${theme.colors.tertiary};
	border: 2px solid ${theme.colors.frame_border_color};
	margin-bottom: 20px;
`;

export const SinglePlayerBoxTitle = styled.div`
	display: flex;
	align-items: center;
	border-bottom: 2px solid ${theme.colors.frame_border_color};
	padding: 15px;
	p {
		font-size: 16px;
		font-weight: 900;
		font-style: italic;
		text-transform: uppercase;
		margin: 0;
	}
`;

export const SinglePlayerBoxContentTop = styled.div`
	display: flex;
	flex-direction: column;
	padding: 30px 20px;
	p {
		margin: 0;
		color: #666;
		&:not(:last-child) {
			margin-bottom: 10px;
		}
		span {
			float: right;
			font-weight: 900;
			color: #666;
		}
	}
`;
