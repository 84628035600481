export const cutLink = (ytLink: string): string => {
	let result: string = '';
	if (ytLink.indexOf('?v=') >= 0) {
		result = ytLink.split('?v=', 2)[1];
		if (result.indexOf('&') >= 0) result = result.split('&', 2)[0];
	} else if (ytLink.indexOf('youtu.be') >= 0) {
		result = ytLink.split('youtu.be/', 2)[1];
	}
	return result;
};
