export const menuRoute = '/wp-api-menus/v2/menus/13';
export const mainPageSliderRoute = '/wp/v2/posts?sticky=true&per_page=5';
export const mainPageNewsRoute = '/wp/v2/posts?sticky=false&per_page=3';
export const featuredMediaRoute = '/wp/v2/media/';
export const upcomingMatchRoute = '/sportspress/v2/calendars/1377';
export const lastestMatchRoute = '/sportspress/v2/calendars/4646';
export const mainGalleryRoute = '/tk_gallery/v1/gallery/1';
export const galleryRoute = '/tk_gallery/v1/gallery/';
export const sponsorsRoute =
	'/sportspress/v2/sponsors?_embed&orderby=menu_order&order=asc';
export const newsRoute = '/wp/v2/posts?_embed';
export const cfRoute = '/contact-form-7/v1/contact-forms/1521/feedback';
export const teamRoute = '/sportspress/v2/lists/2059';
export const staffRoute = '/sportspress/v2/staff?_embed';
export const playerRoute = (p_slug: string): string =>
	`/sportspress/v2/players/?slug=${p_slug}&_embed`;
export const interestedPostsRoute = '/wp/v2/posts?_embed&per_page=3&page=1';
export const infoPageRoute = '/acf/v3/pages/1467';
export const eventRoute = '/sportspress/v2/events/?slug=';
