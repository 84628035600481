import styled, { css } from "styled-components";
import { ILoader } from "../Interfaces/Loader.interface";
import { theme } from "../../../../Global/Variables";

export const LoaderContainer = styled.div<ILoader>`
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  z-index: 9999;
  background-color: ${theme.colors.primary};
  ${({ full }) =>
    full
      ? css`
          top: 0;
          height: 100%;
        `
      : css`
          height: 50px;
        `};

  ${({ visible }) =>
    !visible &&
    css`
      display: none;
    `};
`;

export const SvgContainer = styled.div<ILoader>`
  margin-bottom: 50px;
  width: 50%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;

  ${({ full }) =>
    !full &&
    css`
      display: none;
    `}

  .svg-deac {
    width: 100%;
    height: auto;

    .path {
      fill: white;
      fill-opacity: 0;
      stroke: ${theme.colors.secondary};
      stroke-width: 1;
      stroke-linecap: round;
      stroke-dasharray: 860;
      stroke-dashoffset: 860;
      animation: draw 1.5s linear infinite;
    }

    @keyframes draw {
      75% {
        fill-opacity: 0;
        stroke-dashoffset: 0;
      }
      100% {
        stroke: ${theme.colors.tertiary};
        fill-opacity: 1;
        stroke-dashoffset: 0;
      }
    }
  }
`;

export const Wrapper = styled.div`
  position: relative;
  width: 50%;
  height: 5px;
`;

export const Loader = styled.div`
  height: 100%;
  display: flex;
  transform: translateZ(0);

  & > div {
    flex: 1;
    background: ${theme.colors.secondary};
    animation: go 0.8s infinite alternate ease;
    box-shadow: 0 0 20px ${theme.colors.secondary};
  }

  & > div:nth-child(1) {
    -webkit-animation-delay: -0.72s;
    animation-delay: -0.72s;
  }
  & > div:nth-child(2) {
    -webkit-animation-delay: -0.64s;
    animation-delay: -0.64s;
  }
  & > div:nth-child(3) {
    -webkit-animation-delay: -0.56s;
    animation-delay: -0.56s;
  }
  & > div:nth-child(4) {
    -webkit-animation-delay: -0.48s;
    animation-delay: -0.48s;
  }
  & > div:nth-child(5) {
    -webkit-animation-delay: -0.4s;
    animation-delay: -0.4s;
  }
  & > div:nth-child(6) {
    -webkit-animation-delay: -0.32s;
    animation-delay: -0.32s;
  }
  & > div:nth-child(7) {
    -webkit-animation-delay: -0.24s;
    animation-delay: -0.24s;
  }
  & > div:nth-child(8) {
    -webkit-animation-delay: -0.16s;
    animation-delay: -0.16s;
  }
  & > div:nth-child(9) {
    -webkit-animation-delay: -0.08s;
    animation-delay: -0.08s;
  }
  & > div:nth-child(10) {
    -webkit-animation-delay: 0s;
    animation-delay: 0s;
  }

  @keyframes go {
    100% {
      background: transparent;
      flex: 10;
      box-shadow: 0 0 0 transparent;
    }
  }
`;