import { eventRoute } from '../Static/ApiRoutes';
import { axios } from '../API/axios';
import { MatchOverviewModel } from '../Models/MatchOverview.model';
import { IMatchOverview } from '../Interfaces/IMatchOverview.interface';
import { AxiosPromise, AxiosResponse } from 'axios';

export default abstract class MatchOverviewDAO {
	private static readonly base: string = eventRoute;

	public static async getData(id: string): Promise<MatchOverviewModel[]> {
		const request: AxiosPromise<IMatchOverview[]> = axios
			.getInstance()
			.get(this.base + id);
		const response: AxiosResponse<IMatchOverview[]> = await request;
		return response.data;
	}
}
