import { MainNewsModel } from '../../Models/MainNews.model';
import { MainPageSliderModel } from '../../Models/MainPageSlider.model';
import { MediaModel } from '../../Models/Media.model';

// Action types
export const SET_SLIDER = 'SET_SLIDER';
export const SET_NEWS = 'SET_NEWS';
export const SET_FEATURED_MEDIA = 'SET_FEATURED_MEDIA';

// State interface
export interface IMainPageState {
	slider: MainPageSliderModel[];
	news: MainNewsModel[];
	media: MediaModel[];
}

// Actions interface

interface ISliderAction {
	type: typeof SET_SLIDER;
	slider: MainPageSliderModel[];
}
interface INewsAction {
	type: typeof SET_NEWS;
	news: MainNewsModel[];
}
interface IMediaAction {
	type: typeof SET_FEATURED_MEDIA;
	media: MediaModel[];
}

export type MainPageActionTypes = ISliderAction | INewsAction | IMediaAction;
