import { ICalendar } from "../Interfaces/ICalendar.interface";
import { axios } from "../API/axios";
import { AxiosPromise, AxiosResponse } from "axios";
import {
  upcomingMatchRoute,
  lastestMatchRoute,
} from "../Static/ApiRoutes";
import { CalendarModel } from "../Models/Calendar.model";

export default abstract class MainPageDAO {
  private static readonly upcoming: string = upcomingMatchRoute;
  private static readonly lastest: string = lastestMatchRoute;

  public static async getUpcoming(): Promise<CalendarModel> {
    const upcomingReq: AxiosPromise<ICalendar> = axios
      .getInstance()
      .get(this.upcoming);
    const upcomingResp: AxiosResponse<ICalendar> = await upcomingReq;
    return upcomingResp.data;
  }

  public static async getResults(): Promise<CalendarModel> {
    const lastestReq: AxiosPromise<ICalendar> = axios
      .getInstance()
      .get(this.lastest);
    const lastestResp: AxiosResponse<ICalendar> = await lastestReq;
    return lastestResp.data;
  }
}
