import React from 'react';
import { TabItem, TabWrapper } from './Tab.style';

interface ITabProps {
	data: string[];
	callback: (d: string) => void;
	active: number;
}

export const Tab: React.FC<ITabProps> = (props): React.ReactElement => {
	const getTab = (): React.ReactElement => {
		return (
			<TabWrapper>
				{props.data.map((d: string, idx: number) => (
					<TabItem
						className={`${props.active === idx && 'active'}`}
						onClick={() => props.callback(d)}
						key={`tab-${idx}`}
					>
						{d}
					</TabItem>
				))}
			</TabWrapper>
		);
	};

	return getTab();
};
