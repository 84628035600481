import { NewsPageModel } from "../../Models/NewsPage.model";

// Action types
export const SET_NEWS_PAGE_DATA = "SET_NEWS_PAGE_DATA";

// State interface
export interface INewsPageState extends NewsPageModel {}

// Actions interface

interface INewsPageDataAction {
  type: typeof SET_NEWS_PAGE_DATA;
  newsPageData: NewsPageModel;
}

export type NewsPageActionTypes = INewsPageDataAction;
