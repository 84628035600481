import { ISingleMedia } from './../Interfaces/SingleMedia.interface';
import { axios } from '../API/axios';
import { AxiosPromise, AxiosResponse } from 'axios';
import { galleryRoute } from '../Static/ApiRoutes';
import { SingleMediaModel } from '../Models/SingleMedia.model';

export default abstract class SingleMediaDAO {
	private static readonly media: string = galleryRoute;

	public static async getMedia(slug: string): Promise<SingleMediaModel[]> {
		const mediaReq: AxiosPromise<ISingleMedia[]> = axios
			.getInstance()
			.get(`${this.media}${slug}`);
		const mediaResp: AxiosResponse<ISingleMedia[]> = await mediaReq;
		return mediaResp.data;
	}
}
