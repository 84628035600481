import { CalendarActionTypes, SET_UPCOMING, SET_LASTEST } from "./types";
import { CalendarModel } from "../../Models/Calendar.model";

export const setUpcoming = (upcoming: CalendarModel): CalendarActionTypes => {
  return {
    type: SET_UPCOMING,
    upcoming: upcoming,
  };
};
export const setLastest = (lastest: CalendarModel): CalendarActionTypes => {
  return {
    type: SET_LASTEST,
    lastest: lastest,
  };
};
