import { IRouteCmp } from '../Interfaces/IRouteAsComponent.interface';
import { IDynamicObject } from '../Interfaces/IDynamicObject.interface';
import NewsPage from '../Components/NewsPage/Controller/NewsPage.controller';
import InsideNewsPageController from '../Components/InsideNewPage/Controller/InsideNewsPage.controller';
import MediaController from '../Components/Media/Controller/Media.controller';
import ContactController from '../Components/Contact/Controller/Contact.controller';
import TeamPageController from '../Components/TeamPage/Controller/TeamPage.controller';
import PlayerController from '../Components/SinglePlayer/Controller/SinglePlayer.controller';
import InfoPageController from '../Components/InfoPage/Controller/InfoPage.controller';
import MatchPageController from '../Components/MatchPage/Controller/MatchPage.controller';
import MatchOverviewController from '../Components/MatchOverview/Controller/MatchOverview.controller';
import SingleMediaController from '../Components/SingleMedia/Controller/SingleMedia.controller';

export const RouteAsComponent: IDynamicObject<IRouteCmp> = {
	hirek: {
		component: NewsPage,
		sub_url: '/:news_id',
		sub_cmp: InsideNewsPageController,
	},
	media: {
		component: MediaController,
		sub_url: '/:slug',
		sub_cmp: SingleMediaController,
	},
	kapcsolat: {
		component: ContactController,
	},
	'a-csapat': {
		component: TeamPageController,
		sub_url: '/:player_id',
		sub_cmp: PlayerController,
	},
	informaciok: {
		component: InfoPageController,
	},
	merkozesek: {
		component: MatchPageController,
		sub_url: '/:slug',
		sub_cmp: MatchOverviewController,
	},
};
