import React, { useEffect, useState } from 'react';
import NewsPageView from '../View/NewsPage.view';
import { RouteComponentProps } from 'react-router-dom';
import { useDispatch, connect } from 'react-redux';
import * as newsPageActions from '../../../Redux/NewsPage/actions';
import IReduxState from '../../../Interfaces/IRedux.interface';
import { NewsPageModel } from '../../../Models/NewsPage.model';

export interface NewsPageControllerProps extends RouteComponentProps {
	newsPageData: NewsPageModel;
}

const NewsPageController: React.FC<NewsPageControllerProps> = (props) => {
	const dispatch = useDispatch();
	const [selectedPage, setSelectedPage] = useState<number>(1);
	const pageSize: number = 4;

	const loadNews = (): void => {
		dispatch(newsPageActions.getNews(pageSize, selectedPage));
	};

	const loadSearchResults = () => {
		setSelectedPage(1);
		dispatch(
			newsPageActions.getSearchResults(
				props.location.search.split('?')[1],
				pageSize,
				selectedPage
			)
		);
	};

	useEffect(() => {
		if (props.location.search === '') loadNews();
		else loadSearchResults();
		//eslint-disable-next-line
	}, [selectedPage]);

	useEffect(() => {
		if (props.location.search === '') loadNews();
		else loadSearchResults();
		//eslint-disable-next-line
	}, [props.location.search]);

	return <NewsPageView {...props} selectedPageHandler={setSelectedPage} />;
};

const mapStateToProps = (state: IReduxState) => ({
	newsPageData: state.newsPage,
});

export default connect(mapStateToProps)(NewsPageController);
