import { IMenuState, MenuActionTypes, SET_MENU } from "./types";

const initialState: IMenuState = {
  name: "",
  slug: "",
  description: "",
  count: 0,
  items: [],
};

const menuReducer = (
  state = initialState,
  action: MenuActionTypes
): IMenuState => {
  switch (action.type) {
    case SET_MENU:
      return action.menu;
    default:
      return state;
  }
};

export default menuReducer;
