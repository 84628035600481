import { MainPageActionTypes, SET_SLIDER, SET_NEWS, SET_FEATURED_MEDIA } from './types';
import { MainNewsModel } from '../../Models/MainNews.model';
import { MainPageSliderModel } from '../../Models/MainPageSlider.model';
import { CalendarModel } from '../../Models/Calendar.model';
import { MediaModel } from '../../Models/Media.model';
import { Dispatch } from 'redux';
import IReduxState from '../../Interfaces/IRedux.interface';
import { setError, setLoading } from '../Status/actions';
import { IError } from '../../Interfaces/IError.interface';
import { setUpcoming, setLastest } from './../Calendar/actions';
import { setFull } from '../Status/actions';
import MainPageDAO from '../../DAOs/MainPage.DAO';

const setSlider = (slider: MainPageSliderModel[]): MainPageActionTypes => {
	return {
		type: SET_SLIDER,
		slider: slider,
	};
};

const setNews = (news: MainNewsModel[]): MainPageActionTypes => {
	return {
		type: SET_NEWS,
		news: news,
	};
};

const setMedia = (media: MediaModel[]): MainPageActionTypes => {
	return {
		type: SET_FEATURED_MEDIA,
		media: media,
	};
};

export const getMainPage = () => {
	return async (dispatch: Dispatch, getState: () => IReduxState) => {
		if (
			(!!getState().mainPage.media.length ||
				!!getState().mainPage.news.length ||
				!!getState().mainPage.slider.length ||
				!!getState().calendar.lastest.full.length ||
				!!getState().calendar.upcoming.full.length) &&
			getState().status.full
		) {
			dispatch(setFull(false));
		} else if (!getState().status.full) {
			dispatch(setFull(true));
		}

		const allAction = [
			//Silder API Call
			MainPageDAO.getSlider()
				.then(async (sliderResp: MainPageSliderModel[]) => {
					if (
						JSON.stringify(getState().mainPage.slider) !==
						JSON.stringify(sliderResp)
					) {
						dispatch(setSlider(sliderResp));
					}
				})
				.catch((error: IError) => dispatch(setError(error))),

			//News API Call
			MainPageDAO.getNews()
				.then(async (newsResp: MainNewsModel[]) => {
					if (
						JSON.stringify(getState().mainPage.news) !==
						JSON.stringify(newsResp)
					) {
						dispatch(setNews(newsResp));
					}
				})
				.catch((error: IError) => dispatch(setError(error))),

			//Upcoming API Call
			MainPageDAO.getUpcoming()
				.then(async (upcomingResp: CalendarModel) => {
					if (
						JSON.stringify(getState().calendar.upcoming) !==
						JSON.stringify(upcomingResp)
					) {
						dispatch(setUpcoming(upcomingResp));
					}
				})
				.catch((error: IError) => dispatch(setError(error))),

			//Lastest API Call
			MainPageDAO.getLastest()
				.then(async (lastestResp: CalendarModel) => {
					if (
						JSON.stringify(getState().calendar.lastest) !==
						JSON.stringify(lastestResp)
					) {
						dispatch(setLastest(lastestResp));
					}
				})
				.catch((error: IError) => dispatch(setError(error))),

			//Media API Call
			MainPageDAO.getMedia()
				.then((mediaResp: MediaModel[]) => {
					if (
						JSON.stringify(getState().mainPage.media) !==
						JSON.stringify(mediaResp)
					) {
						dispatch(setMedia(mediaResp));
					}
				})
				.catch((error: IError) => dispatch(setError(error))),
		];

		if (getState().status.loading) {
			Promise.all(allAction).finally(() => {
				if (getState().status.loading) {
					dispatch(setLoading(null));
				}
			});
		} else {
			dispatch(
				setLoading(
					Promise.all(allAction).finally(() => {
						if (getState().status.loading) {
							dispatch(setLoading(null));
						}
					})
				)
			);
		}
	};
};
