import React from 'react';
import Breadcrumb from '../../UI/Breadcrumb/Controller/Breadcrumb.controller';
import { Container, Row, Col } from 'react-bootstrap';
import NextMatchWidget from '../../UI/NextMatchWidget/Controller/NextMatchWidget.controller';
import InterestedInWidget from '../../UI/InterestedInWidget/Controller/InterestedIn.controller';
import { SingleNewsModel as InsideNewsPageModel } from '../../../Models/SingleNews.model';
import HTMLParser from 'html-react-parser';
import moment from 'moment';
import 'moment/locale/hu';
/* import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons'; */
import { RouteComponentProps } from 'react-router-dom';
import {
	NewsContainer,
	NewsContentContainer,
	NewsFeaturedImg,
	NewsFeaturedImgWrapper,
	NewsContent,
	NewsText,
	//NewsCategory,
	NewsDateAndAuthor,
	NewsSidebar,
	NewsTitle,
	NewsInfoContainer,
	NewsModificationDate,
	NewsShare,
} from './InsideNewsPage.style';
import { FacebookShareButton } from 'react-share';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookSquare } from '@fortawesome/free-brands-svg-icons';

export interface InsideNewPageViewProps extends RouteComponentProps {
	news: InsideNewsPageModel[];
}

const InsideNewPageView: React.FC<InsideNewPageViewProps> = (props) => {
	if (!props.news.length) {
		return <></>;
	} else {
		return (
			<NewsContainer>
				<Breadcrumb
					third_level={HTMLParser(props.news[0]?.title.rendered).toString()}
				/>
				<NewsContentContainer>
					<Container>
						<Row>
							<Col xs='12'>
								<NewsFeaturedImgWrapper>
									<NewsFeaturedImg
										imageUrl={props.news[0]?.f_image.medium_large}
									/>
								</NewsFeaturedImgWrapper>
							</Col>
						</Row>
					</Container>
					<Container>
						<Row>
							<Col xs='12' lg='8' xl='9'>
								<NewsContent>
									{/*
									<NewsCategory>
										{props.news[0]?._embedded['wp:term'][0][0]?.name}
									</NewsCategory>*/}
									<Container>
										<Row className='flex-column-reverse flex-lg-row'>
											<Col xs='12' sm='10'>
												<NewsTitle>
													{HTMLParser(props.news[0]?.title.rendered)}
												</NewsTitle>
											</Col>
											<Col xs='12' sm='2' className='d-flex align-items-center'>
												<NewsShare>
													<FacebookShareButton
														url={window.location.href}
														quote={props.news[0].excerpt.rendered}
														hashtag={
															props.news[0]?._embedded['wp:term'][0][0]?.name
														}
													>
														{' '}
														Megosztom
														<FontAwesomeIcon icon={faFacebookSquare} />
													</FacebookShareButton>
												</NewsShare>
											</Col>
										</Row>
									</Container>
									<NewsInfoContainer>
										<NewsDateAndAuthor>
											{moment(props.news[0]?.date).format('L')}
											{' Írta '}
											{props.news[0]?._embedded.author[0]?.name}
										</NewsDateAndAuthor>
										<NewsModificationDate>
											{`Frissítve: ${moment(props.news[0]?.modified).format(
												'L'
											)}`}
										</NewsModificationDate>
									</NewsInfoContainer>
									<NewsText>
										{HTMLParser(props.news[0]?.content.rendered)}
									</NewsText>
								</NewsContent>
							</Col>
							<Col xs='12' className='mobile'>
								<InterestedInWidget />
							</Col>
							<Col xs='12' lg='4' xl='3'>
								<NewsSidebar>
									<NextMatchWidget />
								</NewsSidebar>
							</Col>
						</Row>
						<Row>
							<Col xs='12' className='desktop'>
								<InterestedInWidget />
							</Col>
						</Row>
					</Container>
				</NewsContentContainer>
			</NewsContainer>
		);
	}
};

export default InsideNewPageView;
