export const theme = {
  colors: {
    primary: "#262626",
    secondary: "#8dcc26",
    tertiary: "#ffffff",
    quarterly: "#000000",
    primary_text: "#ffffff",
    secondary_text: "#000000",
    light_text: "#999999",
    highlighted_text: "#8dcc26",
    inactive_color: "#afafb0",
    opacity_black: "rgba(0,0,0,0.5)",
    shadow_color: "rgba(0,0,0,0.64)",
    dropdown_color: "#f9f9f9",
    separator_color: "#464646",
    dropdown_line_color: "#e1e1e1",
    dropdown_hover_color: "#f1f1f1",
    drawer_button_color: "#1f1f1f",
    grey_background_color: "#f3f3f3",
    copyright_background_color: "#fefefe",
    decorated_button_dark_green_color: "#4f8408",
    decorated_button_hover_color: "#2b4206",
    upcoming_button_color: "#7c7c7c",
    frame_border_color: "#e4e7ed",
    news_card_description_background: "#262626",
    news_paginate_button_color: "#f7f7f7",
    news_paginate_arrow_button_color: "#e7e7e7",
    news_page_image_border_color: "#d4d4d4",
    facebook_color: "#4267B2",
  },
};
