import React, { useEffect } from "react";
import InfoPageView from "../View/InfoPage.view";
import { connect, useDispatch } from "react-redux";
import * as infoPageActions from "../../../Redux/InfoPage/actions";
import IReduxState from "../../../Interfaces/IRedux.interface";
import { InfoPageModel } from "../../../Models/InfoPage.model";

interface InfoPageControllerProps {
  infoData: InfoPageModel;
}

const InfoPageController: React.FC<InfoPageControllerProps> = (
  props
): React.ReactElement => {
  const dispatch = useDispatch();
  const { infoData } = props;

  const loadInfoPage = () => {
    dispatch(infoPageActions.getInfoPageData());
  };

  useEffect(() => {
    loadInfoPage();
    // eslint-disable-next-line
  }, []);

  return <InfoPageView acf={infoData} />;
};

const mapStateToProps = (state: IReduxState) => ({
  infoData: state.infoPage,
  loading: state.status.loading,
});

export default connect(mapStateToProps)(InfoPageController);
