import styled from 'styled-components';
import { theme } from '../../../../Global/Variables';

export const ContactBoxWrapper = styled.div`
	background: ${theme.colors.tertiary};
	padding-bottom: 20px;
	border: 2px solid ${theme.colors.frame_border_color};
	margin: 20px 0;
`;

export const ContactBoxText = styled.div`
	color: ${theme.colors.light_text};
	font-size: 14px;
	text-transform: uppercase;
	padding: 10px 15px;
	a {
		color: ${theme.colors.light_text} !important;
	}
	svg {
		margin-right: 10px;
	}
`;

export const ContactBoxTitle = styled.div`
	border-bottom: 2px solid ${theme.colors.frame_border_color};
	padding: 10px 15px;
	font-style: italic;
	font-weight: 900;
	text-transform: uppercase;
	display: flex;
	align-items: center;
`;

export const ContactBoxTitleBefore = styled.div`
	background: ${theme.colors.secondary};
	min-height: 20px;
	height: 100%;
	width: 70px;
	position: relative;
	display: inline-block;
	margin-right: 30px;
	&:after {
		content: '';
		display: inline-block;
		border-style: solid;
		border-width: 20px 20px 0 0;
		position: absolute;
		right: -20px;
		border-color: ${theme.colors.secondary} transparent transparent transparent;
	}
`;
