import styled from 'styled-components';
import { theme } from '../../../../Global/Variables';

export const FormWrapper = styled.div`
	padding: 20px 0;
	width: 100%;
	height: 100%;
`;

export const FormWrapperInner = styled.div`
	padding: 30px 50px;
	height: 100%;
	background: ${theme.colors.news_paginate_button_color};
	border: 2px solid ${theme.colors.frame_border_color};
	form {
		display: flex;
		height: 100%;
		flex-direction: column;
		justify-content: space-between;
		.error {
			color: red;
			text-transform: uppercase;
			font-style: italic;
			font-size: 14px;
		}
		button {
			font-size: 14px;
			font-style: italic;
			font-weight: 900;
			color: white;
			border: none !important;
			outline: none !important;
			background: black;
			padding: 10px 20px;
			text-transform: uppercase;
			&.sent {
				background: ${theme.colors.secondary};
			}
		}
		input,
		textarea {
			padding: 5px 10px;
			border: none !important;
			width: 100%;
			outline: none !important;
			margin-bottom: 20px;
		}
		p {
			margin: 0;
			font-size: 14px;
			span {
				color: red;
			}
		}
		h3 {
			font-size: 18px;
			font-weight: 900;
			font-style: italic;
			text-transform: uppercase;
			display: flex;
			align-items: center;
			span {
				font-size: 14px;
				font-weight: 400;
				text-transform: none;
				margin-left: 5px;
			}
		}
	}
`;
