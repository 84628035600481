import main from "../Assets/Images/szakosztaly_deac.png";
import soccer from "../Assets/Images/szakosztaly_labdarugas.png";
import handball from "../Assets/Images/szakosztaly_kezilabda.png";
import basketball from "../Assets/Images/szakosztaly_kosarlabda.png";
import futsal from "../Assets/Images/szakosztaly_futsal.png";
import football from "../Assets/Images/szakosztaly_amerikaifutball.png";
import volleyball from "../Assets/Images/szakosztaly_roplabda.png";
import floorball from "../Assets/Images/szakosztaly_floorball.png";
import hockey from "../Assets/Images/szakosztaly_jegkorong.png";

export const DeacSections = [
  {
    name: "DEAC",
    url: "https://deac.hu/",
    logo: main,
  },
  {
    name: "LABDARÚGÁS",
    url: "https://labdarugas.deac.hu/",
    logo: soccer,
  },
  {
    name: "KÉZILABDA",
    url: "https://kezilabda.deac.hu/",
    logo: handball,
  },
  {
    name: "KOSÁRLABDA",
    url: "https://kosarlabda.deac.hu/",
    logo: basketball,
  },
  {
    name: "FUTSAL",
    url: "https://futsal.deac.hu/",
    logo: futsal,
  },
  {
    name: "GLADIATORS",
    url: "https://gladiators.deac.hu/",
    logo: football,
  },
  {
    name: "RÖPLABDA",
    url: "https://roplabda.deac.hu/",
    logo: volleyball,
  },
  {
    name: "FLOORBALL",
    url: "https://floorball.deac.hu/",
    logo: floorball,
  },
  {
    name: "JÉGKORONG",
    url: "https://jegkorong.deac.hu/",
    logo: hockey,
  },
];
