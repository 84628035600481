import React from 'react';
import { cutLink } from '../../../Utils/CutYTLink.util';
import { MediaCardWrapper } from './MediaCard.style';
import { Link } from 'react-router-dom';

interface IMediaCardProps {
	featured_image?: string;
	link: string;
	title: string;
}

export const MediaCard: React.FC<IMediaCardProps> = (
	props
): React.ReactElement => {
	const getCard = (): React.ReactElement => {
		return (
			<MediaCardWrapper
				f_image={
					props.featured_image
						? props.featured_image
						: `https://img.youtube.com/vi/${cutLink(
								props.link
						  )}/maxresdefault.jpg`
				}
			>
				<div>
					{props.featured_image ? (
						<Link to={props.link}>{props.title}</Link>
					) : (
						<a href={props.link} target='_blank' rel='noopener noreferrer'>
							{props.title}
						</a>
					)}
				</div>
			</MediaCardWrapper>
		);
	};

	return getCard();
};
