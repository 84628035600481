import React, { useEffect } from "react";
import NextMatchWidgetView from "../View/NextMatchWidget.view";
import { CalendarModel } from "../../../../Models/Calendar.model";
import IReduxState from "../../../../Interfaces/IRedux.interface";
import { connect, useDispatch } from "react-redux";
import * as widgetActions from "../../../../Redux/Widgets/actions";

export interface NextMatchWidgetControllerProps {
  nextMatch: CalendarModel;
}

const NextMatchWidgetController: React.FC<NextMatchWidgetControllerProps> = (
  props
) => {
  const dispatch = useDispatch();
  const loadNext = (): void => {
    dispatch(widgetActions.getNextMatch());
  };

  useEffect(() => {
    loadNext();
    //eslint-disable-next-line
  }, []);

  return <NextMatchWidgetView nextMatch={props.nextMatch} />;
};

const mapStateToProps = (state: IReduxState) => ({
  nextMatch: state.widgets.nextMatch,
});

export default connect(mapStateToProps)(NextMatchWidgetController);
