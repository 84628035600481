import styled from 'styled-components';
import { theme } from '../../../Global/Variables';

interface IMediaCard {
	f_image: string;
}

export const MediaCardWrapper = styled.div<IMediaCard>`
	background: url(${({ f_image }) => f_image});
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
	height: 400px;
	width: 100%;
	display: flex;
	align-items: flex-end;
	padding: 15px;
	position: relative;
	z-index: 0;
	margin-bottom: 40px;
	&:after {
		content: '';
		background: rgba(0, 0, 0, 0.3);
		left: 0;
		top: 0;
		position: absolute;
		width: 100%;
		height: 100%;
		z-index: 1;
	}
	a {
		position: relative;
		z-index: 2;
		font-size: 35px;
		color: white !important;
		text-transform: uppercase;
		font-weight: 600;
		line-height: 40px;
		max-width: 100%;
		background-position: left;
		background-size: 0% 100%;
		background-image: linear-gradient(
			${theme.colors.secondary},
			${theme.colors.secondary}
		);
		transition: all 0.5s ease-in-out;
		display: inline;
		background-repeat: no-repeat;
		&:hover {
			background-size: 100% 100%;
			text-decoration: none;
		}
		@media screen and (max-width: 992px) {
			font-size: 25px;
			line-height: 1.2;
		}
	}
`;
