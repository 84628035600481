import { CalendarModel } from "../../Models/Calendar.model";

// Action types
export const SET_UPCOMING = "SET_UPCOMING";
export const SET_LASTEST = "SET_LASTEST";

// State interface
export interface ICalendarState {
  upcoming: CalendarModel;
  lastest: CalendarModel;
}

// Actions interface

interface IUpcomingAction {
  type: typeof SET_UPCOMING;
  upcoming: CalendarModel;
}
interface ILastestAction {
  type: typeof SET_LASTEST;
  lastest: CalendarModel;
}

export type CalendarActionTypes = IUpcomingAction | ILastestAction;
