import { axios } from "../API/axios";
import { SingleNewsModel as InsideNewsPageModel } from "../Models/SingleNews.model";
import { IPost } from "../Interfaces/ISingleNews.interface";
import { AxiosPromise, AxiosResponse } from "axios";
import { newsRoute } from "../Static/ApiRoutes";

export default abstract class HeaderDAO {
  private static readonly base: string = newsRoute;

  public static async getPost(slug: string): Promise<InsideNewsPageModel[]> {
    const request: AxiosPromise<IPost[]> = axios
      .getInstance()
      .get(`${this.base}&slug=${slug}`);
    const response: AxiosResponse<IPost[]> = await request;
    return response.data;
  }
}
