import { axios } from '../API/axios';
import { MenuModel } from '../Models/Menu.model';
import { IMenu } from '../Interfaces/IMenu.interface';
import { AxiosPromise, AxiosResponse } from 'axios';
import { menuRoute } from '../Static/ApiRoutes';

export default abstract class HeaderDAO {
	private static readonly base: string = menuRoute;

	public static async getMenu(): Promise<MenuModel> {
		const request: AxiosPromise<IMenu> = axios.getInstance().get(this.base);
		const response: AxiosResponse<IMenu> = await request;
		return response.data;
	}
}
