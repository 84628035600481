export const formatDateString = (
	dateString: string,
	delimeter: string,
	fullMonth?: boolean,
	showYear?: boolean,
	isRevert?: boolean
): string => {
	const dateArr: string[] = dateString.split(/[.]|[\s]|[-]|[:]|[/]|[T]/g);
	const y: string = dateArr[0];
	let m: string = dateArr[1];
	let d: string = dateArr[2];
	if (d.startsWith('0')) {
		d = d.split('0')[1];
	}
	if (fullMonth) {
		m = monthNames[+m - 1];
		if (!showYear && !isRevert) return `${m} ${d}.`;
		if (!showYear && isRevert) return `${d} ${m}`;
		return `${y}. ${m} ${d}.`;
	}
	return `${y}${delimeter}${m}${delimeter}${d}`;
};

const monthNames = [
	'Január',
	'Február',
	'Március',
	'Április',
	'Május',
	'Június',
	'Július',
	'Augusztus',
	'Szeptember',
	'Október',
	'November',
	'December',
];
