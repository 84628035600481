import styled from 'styled-components';
import { theme } from '../../../../Global/Variables';

export const BreadcrumbWrapper = styled.div`
	height: 75px;
	background-color: ${theme.colors.secondary};
	mix-blend-mode: multiply;
	display: flex;
	align-items: center;
`;

export const BreadcrumbContainer = styled.div`
	display: flex;
	flex-wrap: wrap;
	.last {
		font-weight: 700;
	}

	span {
		margin: 0 5px;
	}
`;

export const BreadcrumbItem = styled.p`
	font-size: 16px;
	font-weight: 400;
	margin: 0;
	a {
		color: black !important;
		text-decoration: none !important;
	}
	&:first-letter {
		text-transform: uppercase;
	}
`;
