import { combineReducers } from "redux";

//reducers
import headerReducer from "./Header/reducer";
import menuReducer from "./Menu/reducer";
import statusReducer from "./Status/reducer";
import mainPageReducer from "./MainPage/reducer";
import footerReducer from "./Footer/reducer";
import widgetsReducer from "./Widgets/reducer";
import mediaReducer from "./Media/reducer";
import infoPageReducer from "./InfoPage/reducer";
import calendarReducer from "./Calendar/reducer";
import insideNewsPageReducer from "./InsideNewsPage/reducer";
import newsPageReducer from "./NewsPage/reducer";
import teamPageReducer from "./TeamPage/reducer";
import playerPageReducer from "./SinglePlayerPage/reducer";
import matchOverviewPageReducer from "./MatchOverviewPage/reducer";

export default combineReducers({
  header: headerReducer,
  menu: menuReducer,
  status: statusReducer,
  mainPage: mainPageReducer,
  footer: footerReducer,
  widgets: widgetsReducer,
  media: mediaReducer,
  infoPage: infoPageReducer,
  calendar: calendarReducer,
  singleNews: insideNewsPageReducer,
  newsPage: newsPageReducer,
  teamPage: teamPageReducer,
  playerPage: playerPageReducer,
  matchOverviewPage: matchOverviewPageReducer,
});
