import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import Breadcrumb from '../../UI/Breadcrumb/Controller/Breadcrumb.controller';
import { ISinglePlayer } from '../../../Interfaces/ISinglePlayer.interface';
import NextMatchWidget from '../../UI/NextMatchWidget/Controller/NextMatchWidget.controller';
import {
	SinglePlayerWrapper,
	SinglePlayerLead,
	SinglePlayerBox,
	SinglePlayerBoxTitle,
	SinglePlayerBoxContentTop,
	GreenBG,
} from './style/SinglePlayer.style';
import { Countries } from '../../../Static/Countries';
import HTMLParser from 'html-react-parser';

export interface IPlayerViewProps {
	player: ISinglePlayer[];
}
interface IPlayerMetrics {
	data: string;
	value: any;
}
const SinglePlayerView: React.FC<IPlayerViewProps> = (
	props
): React.ReactElement => {
	if (props.player.length === 0) return <></>;
	let pMetrics: Array<IPlayerMetrics> = [];
	for (const [key, value] of Object.entries(props.player[0].metrics)) {
		pMetrics.push({ data: key, value: value });
	}
	return (
		<>
			<Breadcrumb
				third_level={HTMLParser(props.player[0].title.rendered).toString()}
			/>
			<SinglePlayerWrapper>
				<Container>
					<SinglePlayerLead>
						<GreenBG>{props.player[0].statistics[129][1][0].name}</GreenBG>
						<p>{props.player[0].title.rendered}</p>
					</SinglePlayerLead>
					<Row>
						<Col xs='12' lg='8' xl='9'>
							<img
								src={
									props.player[0]._embedded['wp:featuredmedia'][0].source_url
								}
								alt={props.player[0].title.rendered}
							/>

							<SinglePlayerBox>
								<SinglePlayerBoxTitle>
									<GreenBG alt>Játékos</GreenBG>
									<p>Adatok</p>
								</SinglePlayerBoxTitle>
								<SinglePlayerBoxContentTop>
									<p>
										Nemzetiség:{' '}
										<span>
											{
												Countries.filter(
													(a) => a.short === props.player[0].nationalities[0]
												)[0].long
											}
										</span>
									</p>
									<p>
										Mezszám: <span>{props.player[0].number}</span>
									</p>
									{pMetrics.map((metrics) => (
										<p key={metrics.value}>
											{metrics.data}: <span>{metrics.value}</span>
										</p>
									))}
								</SinglePlayerBoxContentTop>
							</SinglePlayerBox>
						</Col>

						<Col xs='12' lg='4' xl='3'>
							<NextMatchWidget />
						</Col>
					</Row>
				</Container>
			</SinglePlayerWrapper>
		</>
	);
};

export default SinglePlayerView;
