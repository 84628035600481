import { SET_ERROR, SET_FULL, SET_LOADING, StatusActionTypes } from "./types";
import { IError } from "../../Interfaces/IError.interface";

export const setError = (error: IError): StatusActionTypes => {
  return {
    type: SET_ERROR,
    error: error,
  };
};

export const setLoading = (loading: Promise<any> | null) => {
  return {
    type: SET_LOADING,
    loading: loading,
  };
};

export const setFull = (full: boolean) => {
  return {
    type: SET_FULL,
    full: full,
  };
};
