import { axios } from "../API/axios";
import { AxiosPromise, AxiosResponse } from "axios";
import { SponsorModel } from "../Models/Sponsor.model";
import { ISponsor } from "../Interfaces/ISponsor.interface";
import { sponsorsRoute } from "../Static/ApiRoutes";

export default abstract class HeaderDAO {
  private static readonly base: string = sponsorsRoute;

  public static async getSponsors(): Promise<SponsorModel[]> {
    const request: AxiosPromise<ISponsor[]> = axios
      .getInstance()
      .get(this.base);
    const response: AxiosResponse<ISponsor[]> = await request;
    return response.data;
  }
}
