import React, { useState, useRef, useEffect } from 'react';
import { Formik, Form, Field } from 'formik';
import { FormWrapper, FormWrapperInner } from './style/ContactPageForm.style';
import ContactDAO from '../../../DAOs/Contact.DAO';

interface IMessageResponse {
	status: string;
	message: string;
}

const ContactPageForm: React.FC<{}> = () => {
	const [buttonText, setButtonText] = useState<string>('üzenet küldése');
	const [error, set_error] = useState<string>('');
	const [messageSent, set_messageSent] = useState<boolean>(false);
	const isMounted = useRef(false);
	useEffect(() => {
		isMounted.current = true;
		return () => {
			isMounted.current = false;
		};
	}, []);

	return (
		<FormWrapper>
			<FormWrapperInner>
				<Formik
					initialValues={{
						message: '',
						name: '',
						email: '',
					}}
					onSubmit={(values, actions) => {
						const data: FormData = new FormData();
						data.append('your-name', values.name);
						data.append('your-email', values.email);
						data.append('your-message', values.message);
						setButtonText('kézbesítés folyamatban');
						ContactDAO.postData(data)
							.then(async (d: IMessageResponse) => {
								set_error('');
								if (d.status !== 'mail_sent') {
									setButtonText('üzenet küldése');
									set_error(d.message);
								} else {
									if (isMounted) {
										setButtonText('üzenet elküldve');
										set_messageSent(true);
									}
								}
							})
							.catch(() => {
								if (isMounted) {
									setButtonText('próbáld újra');
									setTimeout(() => {
										if (isMounted) {
											setButtonText('üzenet küldése');
										}
									}, 1000);
								}
							});
						actions.setSubmitting(false);
					}}
				>
					<Form>
						<h3>
							Írj nekünk<span>/ Kérdés esetén állunk rendelkezésedre.</span>
						</h3>
						<div>
							<p>
								Hogyan szólíthatunk <span>*</span>
							</p>
							<Field type='text' name='name' id='name' required />
						</div>
						<div>
							<p>
								Email címed <span>*</span>
							</p>
							<Field type='email' name='email' id='email' required />
						</div>
						<div>
							<p>
								Üzeneted <span>*</span>
							</p>
							<Field
								component='textarea'
								id='message'
								name='message'
								required
								rows='4'
							></Field>
						</div>
						<div className='error'>{error}</div>
						<button
							type='submit'
							disabled={messageSent}
							className={`${messageSent && 'sent'}`}
						>
							{buttonText}
						</button>
					</Form>
				</Formik>
			</FormWrapperInner>
		</FormWrapper>
	);
};

export default ContactPageForm;
