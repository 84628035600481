import { axios } from '../API/axios';
import { AxiosPromise, AxiosResponse } from 'axios';
import { playerRoute } from '../Static/ApiRoutes';
import { SinglePlayerModel } from '../Models/SinglePlayer.model';
import { ISinglePlayer } from '../Interfaces/ISinglePlayer.interface';

export default abstract class SinglePlayerDAO {
	public static async getPlayer(p_slug: string): Promise<SinglePlayerModel[]> {
		const request: AxiosPromise<ISinglePlayer[]> = axios
			.getInstance()
			.get(playerRoute(p_slug));
		const response: AxiosResponse<ISinglePlayer[]> = await request;
		return response.data;
	}
}
