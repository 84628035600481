import { axios } from '../API/axios';
import { AxiosPromise, AxiosResponse } from 'axios';
import { cfRoute } from '../Static/ApiRoutes';

export default abstract class ContactDAO {
	private static readonly base: string = cfRoute;

	public static async postData(params: FormData): Promise<any> {
		const request: AxiosPromise<any> = axios
			.getInstance()
			.post(this.base, params);
		const response: AxiosResponse<any> = await request;
		return response.data;
	}
}
