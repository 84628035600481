import { SingleNewsModel } from "./../../Models/SingleNews.model";

// Action types
export const SET_SINGLE_NEWS = "SET_SINGLE_NEWS";

// State interface
export interface ISingleNewsState {
  news: SingleNewsModel[];
}

// Actions interface

interface ISingleNewsAction {
  type: typeof SET_SINGLE_NEWS;
  news: SingleNewsModel[];
}

export type SingleNewsActionTypes = ISingleNewsAction;
