import { SET_INFO_PAGE_DATA, InfoPageActionTypes } from "./types";
import { Dispatch } from "redux";
import IReduxState from "../../Interfaces/IRedux.interface";
import { setError, setFull, setLoading } from "../Status/actions";
import { IError } from "../../Interfaces/IError.interface";
import InfoPageDAO from "../../DAOs/InfoPage.DAO";
import { InfoPageModel } from "../../Models/InfoPage.model";

const setInfoData = (infoData: InfoPageModel): InfoPageActionTypes => {
  return {
    type: SET_INFO_PAGE_DATA,
    infoData: infoData,
  };
};

export const getInfoPageData = () => {
  return async (dispatch: Dispatch, getState: () => IReduxState) => {
    if (getState().infoPage.acf.rolunk.length !== 0) {
      dispatch(setFull(false));
    } else {
      dispatch(setFull(true));
    }

    dispatch(
      setLoading(
        InfoPageDAO.getData()
          .then((d: InfoPageModel) => {
            if (JSON.stringify(getState().infoPage) !== JSON.stringify(d)) {
              dispatch(setInfoData(d));
            }
          })
          .catch((error: IError) => dispatch(setError(error)))
          .finally(() => dispatch(setLoading(null)))
      )
    );
  };
};
