import { IMedia } from '../Interfaces/IMedia.interface';
import { axios } from '../API/axios';
import { AxiosPromise, AxiosResponse } from 'axios';
import { galleryRoute } from '../Static/ApiRoutes';
import { MediaModel } from '../Models/Media.model';

export default abstract class MediaDAO {
	private static readonly media: string = galleryRoute;

	public static async getMedia(): Promise<MediaModel[]> {
		const link = this.media;
		const mediaReq: AxiosPromise<IMedia[]> = axios.getInstance().get(link);
		const mediaResp: AxiosResponse<IMedia[]> = await mediaReq;
		return mediaResp.data;
	}
}
