import React, { useEffect } from 'react';
import { MenuModel } from '../../../Models/Menu.model';
import { Route } from 'react-router';
import IReduxState from '../../../Interfaces/IRedux.interface';
import * as menuActions from '../../../Redux/Menu/actions';
import { connect, useDispatch } from 'react-redux';
import RouterView from '../View/Router.view';
import { RouteAsComponent } from '../../../Static/RouteAsComponent.static';
import MainPageController from '../../MainPage/Controller/MainPage.controller';
import E404View from '../../E404/View/Error404.view';

interface IRouterControllerProps {
	routes: MenuModel;
}

const RouterController: React.FC<IRouterControllerProps> = (
	props
): React.ReactElement => {
	const { routes } = props;
	const dispatch = useDispatch();

	useEffect(() => {
		loadRoute();
		// eslint-disable-next-line
	}, []);

	const loadRoute = (): void => {
		dispatch(menuActions.getMenu());
	};

	const getRoutes = (): any => {
		if (!routes) return null;
		const elements: (JSX.Element | null)[] = [];

		routes.items.forEach((element) => {
			if (element.object_slug in RouteAsComponent) {
				const Component = RouteAsComponent[element.object_slug].component;
				elements.push(
					<Route
						key={`${element.id}-route-element`}
						component={(props: any) => {
							window.scrollTo(0, 0);
							return <Component label={element.object_slug} {...props} />;
						}}
						path={`/${element.object_slug}`}
						exact
					/>
				);
				if (
					RouteAsComponent[element.object_slug].sub_url &&
					RouteAsComponent[element.object_slug].sub_cmp
				) {
					const Component = RouteAsComponent[element.object_slug]?.sub_cmp;
					elements.push(
						<Route
							key={`${element.id}-route-element-sub`}
							component={(props: any) => {
								window.scrollTo(0, 0);
								return <Component label={element.object_slug} {...props} />;
							}}
							path={`/${element.object_slug}${
								RouteAsComponent[element.object_slug].sub_url
							}`}
							exact
						/>
					);
				}
			}
		});

		elements.push(
			<Route
				key={'mainpage'}
				component={(props: any) => {
					window.scrollTo(0, 0);
					return <MainPageController {...props} />;
				}}
				path={'/'}
				exact
			/>
		);

		elements.push(
			<Route
				key={'404-route-element'}
				component={(props: any) => {
					window.scrollTo(0, 0);
					return <E404View {...props} />;
				}}
				path={'*'}
				exact
			/>
		);
		return elements;
	};

	return <RouterView>{getRoutes()}</RouterView>;
};

const mapStateToProps = (state: IReduxState) => ({
	routes: state.menu,
});

export default connect(mapStateToProps)(RouterController);
