import { FooterActionTypes, SET_SPONSORS } from "./types";
import { SponsorModel } from "../../Models/Sponsor.model";
import { Dispatch } from "redux";
import FooterDAO from "../../DAOs/Footer.DAO";
import IReduxState from "../../Interfaces/IRedux.interface";
import { setError /* , setLoading  */ } from "../Status/actions";
import { IError } from "../../Interfaces/IError.interface";

const setSponsors = (sponsors: SponsorModel[]): FooterActionTypes => {
  return {
    type: SET_SPONSORS,
    sponsors: sponsors,
  };
};

export const getSponsors = () => {
  return async (dispatch: Dispatch, getState: () => IReduxState) => {
    /*  if (getState().status.loading) { */
    FooterDAO.getSponsors()
      .then((sponsors: SponsorModel[]) => {
        if (
          JSON.stringify(getState().footer.sponsors) !==
          JSON.stringify(sponsors)
        )
          dispatch(setSponsors(sponsors));
      })
      .catch((error: IError) => dispatch(setError(error)));
    /* .finally(() => {
          if (getState().status.loading) {
            dispatch(setLoading(null));
          }
        }); */
    /* } else {
      dispatch(
        setLoading(
          FooterDAO.getSponsors()
            .then((sponsors: SponsorModel[]) => {
              if (
                JSON.stringify(getState().footer.sponsors) !==
                JSON.stringify(sponsors)
              )
                dispatch(setSponsors(sponsors));
            })
            .catch((error: IError) => dispatch(setError(error)))
           .finally(() => {
              if (getState().status.loading) {
                dispatch(setLoading(null));
              }
            })
        )
      );
    } */
  };
};
