import styled from 'styled-components';
import { theme } from '../../../Global/Variables';

export const TabWrapper = styled.section`
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(105px, 1fr));
	grid-auto-rows: 20px;
	grid-template-rows: 1fr;
	grid-gap: 5px;
	place-items: center;
	align-items: center;
	height: 60px;
	border-top: 1px solid rgba(0, 0, 0, 0.3);
	border-bottom: 1px solid rgba(0, 0, 0, 0.3);
	margin-bottom: 20px;
	position: relative;
`;

export const TabItem = styled.div`
	font-weight: 900;
	color: black;
	text-transform: uppercase;
	opacity: 0.3;
	height: 100%;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	position: relative;
	transition: all 0.3s ease-in-out;

	&:after {
		transition: all 0.3s ease-in-out;
		position: absolute;
		content: '';
		background: ${theme.colors.secondary};
		height: 4px;
		bottom: -3px;
		width: 0;
	}

	&.active {
		opacity: 1;
		&:after {
			position: absolute;
			content: '';
			background: ${theme.colors.secondary};
			height: 4px;
			bottom: -3px;
			width: 100%;
		}
	}
`;
