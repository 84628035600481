import React from 'react';
import { RouteChildrenProps } from 'react-router-dom';
import ContactView from '../View/Contact.view';

export interface IContactProps extends RouteChildrenProps {}

const ContactController: React.FC<IContactProps> = (props) => {
	return <ContactView {...props}></ContactView>;
};

export default ContactController;
