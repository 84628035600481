import { MatchOverviewModel } from './../../Models/MatchOverview.model';

// Action types
export const SET_OVERVIEW = "SET_OVERVIEW";

// State interface
export interface IMatchOverviewState {
  overview: MatchOverviewModel[];
}

// Actions interface

interface IMatchOverviewAction {
  type: typeof SET_OVERVIEW;
  overview: MatchOverviewModel[];
}

export type MatchOverviewActionTypes = IMatchOverviewAction;
