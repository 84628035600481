import {
  ISingleNewsState,
  SingleNewsActionTypes,
  SET_SINGLE_NEWS,
} from "./types";

const initialState: ISingleNewsState = {
  news: [],
};

const widgetsReducer = (
  state = initialState,
  action: SingleNewsActionTypes
): ISingleNewsState => {
  switch (action.type) {
    case SET_SINGLE_NEWS:
      return { ...state, news: action.news };
    default:
      return state;
  }
};

export default widgetsReducer;
