import React, { ReactNode } from 'react';
import Footer from '../../Footer/Controller/Footer.controller';
import Header from '../../Header/Controller/Header.controller';
import { RouteComponentProps, withRouter } from 'react-router';
import { useSelector } from 'react-redux';
import IReduxState from '../../../Interfaces/IRedux.interface';
import BG from '../../../Assets/Images/bg.png';

export interface ILayoutViewProps extends RouteComponentProps {
	children: ReactNode;
}

const LayoutView: React.FC<ILayoutViewProps> = (props): React.ReactElement => {
	const isMainPage = useSelector(
		(state: IReduxState) => state.header.mainStyle
	);

	return (
		<>
			<Header {...props} />
			{isMainPage ? (
				props.children
			) : (
				<div style={{ background: `url(${BG})` }}>{props.children}</div>
			)}

			<Footer />
		</>
	);
};

export default withRouter(LayoutView);
