import React from 'react';
import ReactDOMServer from 'react-dom/server';
import {
	UpcomingWrapper,
	UpcomingTitle,
	UpcomingSubtitle,
	UpcomingButton,
	UpcomingItem,
	UpcomingItemLogos,
	UpcomingItemTitle,
	UpcomingItemInfos,
	UpcomingItemButton,
	UpcomingItemLogo,
	UpcomingItemWrapper,
	UpcomingItemTitleContainer,
} from './style/UpcomingSlider.style';
import { Col, Container, Row } from 'react-bootstrap';
import OwlCarousel from 'react-owl-carousel2';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import ButtonView from '../../UI/Button/View/Button.view';
import { CalendarModel } from '../../../Models/Calendar.model';
import moment from 'moment';
import 'moment/locale/hu';
import { google } from 'calendar-link';
import { Link } from 'react-router-dom';

export interface IMainMatchesSliderProps {
	upcoming: CalendarModel;
}

const UpcomingSlider: React.FC<IMainMatchesSliderProps> = (props) => {
	const { upcoming } = props;

	const options = {
		items: 3,
		nav: true,
		margin: 40,
		navText: [
			ReactDOMServer.renderToString(
				<UpcomingButton>
					<FontAwesomeIcon icon={faArrowLeft} />
				</UpcomingButton>
			),
			ReactDOMServer.renderToString(
				<UpcomingButton>
					<FontAwesomeIcon icon={faArrowRight} />
				</UpcomingButton>
			),
		],
		responsive: {
			0: {
				items: 1,
			},
			768: {
				items: 2,
			},
			1200: {
				items: 3,
			},
		},
	};

	if (!upcoming.full.length) {
		return <></>;
	} else {
		return (
			<UpcomingWrapper>
				<Container>
					<Row>
						<Col xs='10'>
							<UpcomingTitle>Közelgő események</UpcomingTitle>
							<UpcomingSubtitle>
								<Link to='/merkozesek'>Összes megtekintése</Link>
							</UpcomingSubtitle>
						</Col>
					</Row>
					<Row>
						<Col xs='12'>
							<OwlCarousel options={options}>
								{upcoming.full.map((event) => (
									<UpcomingItemWrapper key={event.ID}>
										<UpcomingItem>
											<UpcomingItemLogos>
												<UpcomingItemLogo
													src={event.f_image0}
													alt={event.team0.post.post_title}
												/>
												<UpcomingItemLogo
													src={event.f_image1}
													alt={event.team1.post.post_title}
												/>
											</UpcomingItemLogos>
											<UpcomingItemTitleContainer>
												<UpcomingItemTitle>
													{event.team0.post.post_title}
												</UpcomingItemTitle>
												<span> Vs </span>
												<UpcomingItemTitle>
													{event.team1.post.post_title}
												</UpcomingItemTitle>
											</UpcomingItemTitleContainer>
											<UpcomingItemInfos>
												<p>
													{moment(event.post_date).format(
														'YYYY-MM-DD HH:mm dddd'
													)}
												</p>
												<p>
													{event.event_venue ? event.event_venue[0]?.name : '-'}
												</p>
											</UpcomingItemInfos>
											<UpcomingItemButton>
												<a
													target='_blank'
													rel='noopener noreferrer'
													href={google({
														title: event.post_title,
														start: event.post_date,
														end: event.post_date,
														location: event.event_venue
															? event.event_venue[0]?.name
															: '',
													})}
												>
													<ButtonView width='160px' height='45px' decorated>
														Naptárba
													</ButtonView>
												</a>
											</UpcomingItemButton>
										</UpcomingItem>
									</UpcomingItemWrapper>
								))}
							</OwlCarousel>
						</Col>
					</Row>
				</Container>
			</UpcomingWrapper>
		);
	}
};

export default UpcomingSlider;
