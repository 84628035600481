import React from 'react';
import { ShadowTextWrapper } from './ShadowText.style';

export interface IShadowTextProps {
	text: string;
	color: string;
	fontSize?: string;
	margin: string;
}

const ShadowText: React.FC<IShadowTextProps> = (props) => {
	return (
		<ShadowTextWrapper
			color={props.color}
			fontSize={props.fontSize}
			margin={props.margin}
			content={props.text}
		>
			{props.text}
		</ShadowTextWrapper>
	);
};

export default ShadowText;
