import styled from 'styled-components';
import { theme } from '../../../../Global/Variables';

export const CountDownContainer = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-around;
	align-items: flex-start;
	width: 100%;
	max-width: 350px;
`;

export const CountDownSection = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
`;

export const SectionSeparator = styled.p`
	font-size: 50px;
	font-weight: 900;
	height: 100%;
	margin: 0;
`;

export const CountDownNumber = styled.p`
	font-size: 50px;
	font-weight: 900;
	color: ${theme.colors.secondary_text};
`;

export const CountDownUnit = styled.p`
	font-size: 18px;
	font-weight: 900;
	text-transform: uppercase;
	color: ${theme.colors.secondary_text};
`;
