import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFlag, faEnvelope, faPhone } from '@fortawesome/free-solid-svg-icons';
import {
	ContactBoxWrapper,
	ContactBoxText,
	ContactBoxTitle,
	ContactBoxTitleBefore,
} from './style/ContactBox.style';

export interface IContactBoxViewProps {
	title: string;
	location?: string;
	mail?: string;
	tel?: string;
	text?: string;
}

const ContactBoxView: React.FC<IContactBoxViewProps> = (props) => {
	return (
		<ContactBoxWrapper>
			<ContactBoxTitle>
				<ContactBoxTitleBefore />
				{props.title}
			</ContactBoxTitle>
			{props.text && <ContactBoxText>{props.text}</ContactBoxText>}
			{props.location && (
				<ContactBoxText>
					<FontAwesomeIcon icon={faFlag} /> {props.location}
				</ContactBoxText>
			)}
			{props.mail && (
				<ContactBoxText>
					<FontAwesomeIcon icon={faEnvelope} />{' '}
					<a href={`mailto: ${props.mail}`}>{props.mail}</a>
				</ContactBoxText>
			)}
			{props.tel && (
				<ContactBoxText>
					<FontAwesomeIcon icon={faPhone} /> {props.tel}
				</ContactBoxText>
			)}
		</ContactBoxWrapper>
	);
};

export default ContactBoxView;
