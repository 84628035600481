import { SET_PLAYER, IPlayerState, PlayerActionTypes } from "./types";

const initialState: IPlayerState = {
  player: [],
};

const widgetsReducer = (
  state = initialState,
  action: PlayerActionTypes
): IPlayerState => {
  switch (action.type) {
    case SET_PLAYER:
      return { ...state, player: action.player };
    default:
      return state;
  }
};

export default widgetsReducer;
