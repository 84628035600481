import React, { useEffect } from "react";
import MatchPageView from "../View/MatchPage.view";
import { CalendarModel } from "../../../Models/Calendar.model";
import IReduxState from "../../../Interfaces/IRedux.interface";
import { useDispatch, connect } from "react-redux";
import * as matchPageActions from "../../../Redux/MatchPage/actions";

export interface MatchPageControllerProps {
  upcoming: CalendarModel;
  results: CalendarModel;
}

const MatchPageController: React.FC<MatchPageControllerProps> = (props) => {
  const dispatch = useDispatch();

  const loadMatches = (): void => {
    dispatch(matchPageActions.getMatchData());
  };

  useEffect(() => {
    loadMatches();
    // eslint-disable-next-line
  }, []);

  return <MatchPageView upcoming={props.upcoming} results={props.results} />;
};

const mapStateToProps = (state: IReduxState) => ({
  upcoming: state.calendar.upcoming,
  results: state.calendar.lastest,
});

export default connect(mapStateToProps)(MatchPageController);
