import { SET_STAFF, SET_TEAM, TeamPageActionTypes } from "./types";
import { Dispatch } from "redux";
import IReduxState from "../../Interfaces/IRedux.interface";
import { setError, setFull, setLoading } from "../Status/actions";
import { IError } from "../../Interfaces/IError.interface";
import { TeamPageModel } from "../../Models/TeamPage.model";
import { StaffModel } from "../../Models/Staff.model";
import TeamPageDAO from "../../DAOs/TeamPage.DAO";
import sort from "fast-sort";

const setTeamData = (team: TeamPageModel): TeamPageActionTypes => {
  return {
    type: SET_TEAM,
    team: team,
  };
};
const setStaffData = (staff: StaffModel[]): TeamPageActionTypes => {
  return {
    type: SET_STAFF,
    staff: staff,
  };
};

export const getTeamPageData = () => {
  return async (dispatch: Dispatch, getState: () => IReduxState) => {
    if (
      !getState().teamPage.team.data.mydata.length ||
      !getState().teamPage.staff.length
    ) {
      dispatch(setFull(true));
    } else {
      dispatch(setFull(false));
    }
    //eslint-disable-next-line
    const allAction = [
      TeamPageDAO.getTeam()
        .then((team: TeamPageModel) => {
          sort(team.data.mydata).asc([
            (data) => data.pos[0]?.term_id,
            (data) => parseInt(data.number),
          ]);
          if (
            JSON.stringify(getState().teamPage.team) !== JSON.stringify(team)
          ) {
            dispatch(setTeamData(team));
          }
        })
        .catch((error: IError) => dispatch(setError(error))),
      ,
      TeamPageDAO.getStaff()
        .then((staff: StaffModel[]) => {
          if (
            JSON.stringify(getState().teamPage.staff) !== JSON.stringify(staff)
          ) {
            dispatch(setStaffData(staff));
          }
        })
        .catch((error: IError) => dispatch(setError(error))),
      ,
    ];

    if (getState().status.loading) {
      Promise.all(allAction).finally(() => {
        if (getState().status.loading) {
          dispatch(setLoading(null));
        }
      });
    } else {
      dispatch(
        setLoading(
          Promise.all(allAction).finally(() => {
            if (getState().status.loading) {
              dispatch(setLoading(null));
            }
          })
        )
      );
    }
  };
};
