import { MenuActionTypes, SET_MENU } from "./types";
import { MenuModel } from "../../Models/Menu.model";
import { Dispatch } from "redux";
import MenuDAO from "../../DAOs/Menu.DAO";
import IReduxState from "../../Interfaces/IRedux.interface";
import { setError/* , setLoading */ } from "../Status/actions";
import { IError } from "../../Interfaces/IError.interface";

const setMenu = (menu: MenuModel): MenuActionTypes => {
  return {
    type: SET_MENU,
    menu: menu,
  };
};

export const getMenu = () => {
  return async (dispatch: Dispatch, getState: () => IReduxState) => {
    /*  if (getState().status.loading) {*/
      MenuDAO.getMenu()
        .then((menu: MenuModel) => {
          if (JSON.stringify(getState().menu) !== JSON.stringify(menu))
            dispatch(setMenu(menu));
        })
        .catch((error: IError) => dispatch(setError(error)))
       /*.finally(() => {
          if (getState().status.loading) {
            dispatch(setLoading(null));
          }
        });
    } else {
      dispatch(
        setLoading( 
    MenuDAO.getMenu()
      .then((menu: MenuModel) => {
        if (JSON.stringify(getState().menu) !== JSON.stringify(menu))
          dispatch(setMenu(menu));
      })
      .catch((error: IError) => dispatch(setError(error)));
    .finally(() => {
        if (getState().status.loading) {
          dispatch(setLoading(null));
        }
      });
       )
      );
    }*/
  };
};
