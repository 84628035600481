import styled, { css } from 'styled-components';
import { theme } from '../../../../Global/Variables';
interface ICardTextProps {
	alt?: boolean;
}

export const TeamCardWrapper = styled.div`
	img {
		max-width: 100%;
	}
	margin: 0 0 20px 0;
	border: 2px solid ${theme.colors.frame_border_color};
	&:hover {
		cursor: pointer;
		box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.1);
	}
	a {
		text-decoration: none !important;
	}
`;

export const TeamCardText = styled.div<ICardTextProps>`
	display: flex;
	flex-wrap: wrap;
	& > span {
		display: flex;
		align-items: center;
		justify-content: center;
		font-weight: 600;
		font-size: 30px;
		color: ${theme.colors.tertiary} !important;
		background-color: ${theme.colors.secondary};
		width: 30%;
		padding: 10px 0;
	}
	p {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 70%;
		${({ alt }) =>
			alt
				? css`
						width: 100%;
						padding: 15px 0;
				  `
				: ''}
		font-size: 14px;
		font-weight: 900;
		margin: 0;
		background: ${theme.colors.tertiary};
		text-transform: uppercase;
		color: black !important;
		text-align: center;
		span {
			font-style: italic;
			font-size: 12px;
			font-weight: 300;
			display: contents;
		}
	}
`;
