import React, { useEffect } from "react";
import { useDispatch, connect } from "react-redux";
import { RouteChildrenProps } from "react-router-dom";
import IReduxState from "../../../Interfaces/IRedux.interface";
import { StaffModel } from "../../../Models/Staff.model";
import { TeamPageModel } from "../../../Models/TeamPage.model";
import TeamPageView from "../View/TeamPage.view";
import * as teamPageActions from "../../../Redux/TeamPage/actions";

export interface ITeamPageControllerProps extends RouteChildrenProps {
  team: TeamPageModel;
  staff: StaffModel[];
}

const TeamPageController: React.FC<ITeamPageControllerProps> = (
  props
): React.ReactElement => {
  const dispatch = useDispatch();

  useEffect(() => {
    loadTeamPageData();
    // eslint-disable-next-line
  }, []);

  const loadTeamPageData = () => {
    dispatch(teamPageActions.getTeamPageData());
  };

  return <TeamPageView {...props} />;
};

const mapStateToProps = (state: IReduxState) => ({
  team: state.teamPage.team,
  staff: state.teamPage.staff,
});

export default connect(mapStateToProps)(TeamPageController);
