import styled from 'styled-components';
import { theme } from '../../../../Global/Variables';

export const InfoPageWrapper = styled.div`
	.col-lg-3 {
		padding-right: 0;
		@media screen and (max-width: 992px) {
			padding: inherit;
		}
	}
	.col-lg-9 {
		padding-left: 0;
		@media screen and (max-width: 992px) {
			padding: inherit;
			margin-bottom: 30px;
		}
	}
`;

export const InfoPageMenu = styled.div`
	background: ${theme.colors.news_paginate_button_color};
	height: 100%;
	padding: 50px 0;
	box-shadow: -20px -20px 30px -15px rgba(0, 0, 0, 0.1);
	@media screen and (max-width: 992px) {
		box-shadow: none;
		padding: 20px 0;
		border-left: 1px solid ${theme.colors.frame_border_color};
		border-right: 1px solid ${theme.colors.frame_border_color};
	}
	p {
		font-weight: 300;
		font-size: 14px;
		text-transform: uppercase;
		border-bottom: 2px dashed #c9c9c9;
		margin: 0;
		padding: 10px 0 10px 20px;
		cursor: pointer;
		transition: all 0.15s linear;
		position: relative;
		&:before {
			content: '';
			max-height: 0;
			overflow: hidden;
			height: 100%;
			width: 5px;
			background: ${theme.colors.secondary};
			position: absolute;
			top: 0;
			left: 0;
			transition: all 0.3s ease-in-out;
		}
		&.active,
		&:hover {
			font-weight: 900;
			font-style: italic;
			&:before {
				max-height: 300px;
				transition: all 0.5s ease-out;
			}
		}
	}
`;

export const InfoPageContent = styled.div`
	background: ${theme.colors.tertiary};
	padding: 50px 20px 80px 65px;
	height: 100%;
	box-shadow: -20px -20px 30px -15px rgba(0, 0, 0, 0.1);
	@media screen and (max-width: 992px) {
		box-shadow: none;
		padding: 30px 10px 30px 10px;
		border: 1px solid ${theme.colors.frame_border_color};
	}
	p {
		font-weight: 300;
		font-size: 16px;
	}
`;
