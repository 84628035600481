import React, { useEffect } from 'react';
import NewsPageView from '../View/InsideNewsPage.view';
import { RouteComponentProps } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import * as insideNewsPageActions from '../../../Redux/InsideNewsPage/actions';
import IReduxState from '../../../Interfaces/IRedux.interface';
import { connect } from 'react-redux';
import { SingleNewsModel } from '../../../Models/SingleNews.model';

export interface InsideNewPageControllerProps
	extends RouteComponentProps<IParams> {
	news: SingleNewsModel[];
}

export interface IParams {
	news_id: string;
}

const InsideNewPageController: React.FC<InsideNewPageControllerProps> = (
	props
) => {
	const { news_id } = props.match.params;
	const dispatch = useDispatch();

	const loadNews = (slug: string): void => {
		dispatch(insideNewsPageActions.getSingleNews(slug));
	};

	useEffect(() => {
		loadNews(news_id);
		//eslint-disable-next-line
	}, [props.location]);

	return <NewsPageView {...props} />;
};

const mapStateToProps = (state: IReduxState) => ({
	news: state.singleNews.news,
});

export default connect(mapStateToProps)(InsideNewPageController);
