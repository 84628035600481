import React, { useEffect } from "react";
import { RouteComponentProps } from "react-router-dom";
import IReduxState from "../../../Interfaces/IRedux.interface";
import { MatchOverviewModel } from "../../../Models/MatchOverview.model";
import MatchOverviewView from "../View/MatchOverview.view";
import { useDispatch, connect } from "react-redux";
import * as matchOverviewActions from "../../../Redux/MatchOverviewPage/actions";

interface IMatchOverviewControllerProps extends RouteComponentProps<IParams> {
  overview: MatchOverviewModel[];
}
export interface IParams {
  slug: string;
}
const MatchOverviewController: React.FC<IMatchOverviewControllerProps> = (
  props
): React.ReactElement => {
  const { slug } = props.match.params;
  const dispatch = useDispatch();

  useEffect(() => {
    loadOverview();
    //eslint-disable-next-line
  }, []);
  const loadOverview = () => {
    dispatch(matchOverviewActions.getMatchOverview(slug));
  };
  return <MatchOverviewView data={props.overview} />;
};

const mapStateToProps = (state: IReduxState) => ({
  overview: state.matchOverviewPage.overview,
});

export default connect(mapStateToProps)(MatchOverviewController);
