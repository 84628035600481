import { SET_NEWS_PAGE_DATA, NewsPageActionTypes } from './types';
import { Dispatch } from 'redux';
import IReduxState from '../../Interfaces/IRedux.interface';
import { setError, setFull, setLoading } from '../Status/actions';
import { IError } from '../../Interfaces/IError.interface';
import { NewsPageModel } from '../../Models/NewsPage.model';
import NewsPageDAO from '../../DAOs/NewsPage.DAO';

const setNewsPageData = (newsPageData: NewsPageModel): NewsPageActionTypes => {
	return {
		type: SET_NEWS_PAGE_DATA,
		newsPageData: newsPageData,
	};
};

export const getNews = (pageSize: number, selectedPage: number) => {
	return async (dispatch: Dispatch, getState: () => IReduxState) => {
		if (!!getState().newsPage.data.length) {
			dispatch(setFull(false));
		} else {
			dispatch(setFull(true));
		}
		dispatch(
			setLoading(
				NewsPageDAO.getNews(pageSize, selectedPage)
					.then((newsResponse: NewsPageModel) => {
						if (
							JSON.stringify(getState().newsPage) !==
							JSON.stringify(newsResponse)
						) {
							dispatch(setNewsPageData(newsResponse));
						}
					})
					.catch((error: IError) => dispatch(setError(error)))
					.finally(() => dispatch(setLoading(null)))
			)
		);
	};
};

export const getSearchResults = (
	slug: string,
	pageSize: number,
	selectedPage: number
) => {
	return async (dispatch: Dispatch, getState: () => IReduxState) => {
		if (!!getState().newsPage.data.length) {
			dispatch(setFull(false));
		} else {
			dispatch(setFull(true));
		}

		dispatch(
			setLoading(
				NewsPageDAO.getSearchResults(slug, pageSize, selectedPage)
					.then((newsResponse: NewsPageModel) => {
						if (
							JSON.stringify(getState().newsPage) !==
							JSON.stringify(newsResponse)
						) {
							dispatch(setNewsPageData(newsResponse));
						}
					})
					.catch((error: IError) => dispatch(setError(error)))
					.finally(() => dispatch(setLoading(null)))
			)
		);
	};
};
