import { setFull } from "./../Status/actions";
import { SET_MEDIA, MediaActionTypes } from "./types";
import { MediaModel } from "../../Models/Media.model";
import { Dispatch } from "redux";
import IReduxState from "../../Interfaces/IRedux.interface";
import { setError, setLoading } from "../Status/actions";
import { IError } from "../../Interfaces/IError.interface";
import MediaDAO from "../../DAOs/Media.DAO";

const setMedia = (media: MediaModel[]): MediaActionTypes => {
  return {
    type: SET_MEDIA,
    media: media,
  };
};

export const getMediaAndVideo = () => {
  return async (dispatch: Dispatch, getState: () => IReduxState) => {
    if (!getState().media.media.length) {
      dispatch(setFull(true));
    } else {
      dispatch(setFull(false));
    }

    const allAction = [
      //Media API Call
      MediaDAO.getMedia()
        .then((media: MediaModel[]) => {
          dispatch(setMedia(media));
        })
        .catch((error: IError) => dispatch(setError(error))),
    ];

    if (getState().status.loading) {
      Promise.all(allAction).then(() => {
        if (getState().status.loading) {
          dispatch(setLoading(null));
        }
      });
    } else {
      dispatch(
        setLoading(
          Promise.all(allAction).then(() => {
            if (getState().status.loading) {
              dispatch(setLoading(null));
            }
          })
        )
      );
    }
  };
};
