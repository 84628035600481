import styled from 'styled-components';
import { INewCard } from '../Interfaces/NewsPageStyle.interface';
import { theme } from '../../../Global/Variables';
import { Link } from 'react-router-dom';

export const NewsContainer = styled.div`
	position: relative;
	min-height: 600px;
`;

export const NewsContentContainer = styled.div`
	padding-top: 45px;
`;

export const NewCardWrapper = styled(Link)`
	color: ${theme.colors.primary_text};
	text-decoration: none;
	&:active,
	&:focus,
	&:hover {
		text-decoration: none;
	}
`;

export const NewCard = styled.div<INewCard>`
	height: 450px;
	position: relative;
	margin: 10px 0 15px 0;
	overflow: hidden;
	display: flex;
	align-items: flex-end;
	justify-content: flex-start;
	z-index: 0;

	&::before {
		content: ' ';
		position: absolute;
		z-index: -1;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: url(${({ BgImage }) => BgImage});
		background-size: cover;
		background-position: center;
		background-repeat: no-repeat;
		transition: all 0.3s ease-in-out;
	}

	&:hover {
		&::before {
			transform: scale(1.2);
			filter: grayscale(50%);
		}
	}
`;

export const NewCardTexts = styled.div`
	padding: 0 30px 20px 30px;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: flex-end;
`;

export const NewCardTitle = styled.div`
	font-weight: 900;
	font-size: 24px;
	color: ${theme.colors.primary_text};
	background: ${theme.colors.secondary}80;
	text-transform: uppercase;
	margin-bottom: 10px;
	display: inline;
	box-decoration-break: clone;
	line-height: 1.5;
	padding: 0 5px;
`;

export const NewCardDescription = styled.div`
	font-weight: 300;
	font-size: 16px;
	color: ${theme.colors.primary_text};
	margin-bottom: 15px;
	p {
		display: inline;
		background-color: ${theme.colors.news_card_description_background};
		box-decoration-break: clone;
		line-height: 1.5;
		padding: 0 5px;
	}
`;

export const NewCardAuthor = styled.p`
	font-weight: 300;
	font-size: 14px;
	color: ${theme.colors.primary_text};
	text-transform: uppercase;
	margin: 0;
`;

export const NewsPaginationContainer = styled.div`
	ul.paginateContainer {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
		padding: 50px 0 80px 0;
		font-size: 14px;
		font-weight: 600;
		color: ${theme.colors.secondary_text};
		@media screen and (max-width: 992px) {
			padding: 30px 60px;
		}
	}

	li {
		list-style-type: none;
		height: 50px;
		width: 50px;
		display: flex;
		justify-content: center;
		align-items: center;
		transition: all 0.3s ease-in-out;
	}

	li.pageElement {
		background-color: ${theme.colors.news_paginate_button_color};
	}

	li.break {
		display: none;
	}

	.active {
		background-color: ${theme.colors.secondary} !important;
		color: ${theme.colors.primary_text} !important;
		font-weight: 900;
	}

	.prev,
	.next {
		background-color: ${theme.colors.news_paginate_arrow_button_color};
	}

	.prev {
		margin-right: 25%;
		@media screen and (max-width: 768px) {
			margin-right: 5%;
		}
	}

	.next {
		margin-left: 25%;
		@media screen and (max-width: 768px) {
			margin-left: 5%;
		}
	}

	a {
		text-decoration: none;
		&:focus,
		&:active {
			outline: none;
			text-decoration: none;
		}
	}
`;
