import styled from 'styled-components';

interface IShadowTextWrapperProps {
	margin: string;
	fontSize?: string;
	color: string;
	content: string;
}

export const ShadowTextWrapper = styled.h2<IShadowTextWrapperProps>`
	font: 900 28px 'Rubik', sans-serif;
	text-transform: uppercase;
	position: relative;
	z-index: 0;
	margin: ${({ margin }) => margin};
	color: ${({ color }) => color};
	&:after {
		content: ${({ content }) => `'${content}'`};
		position: absolute;
		font: 900 80px 'Rubik', sans-serif;
		font-size: ${({ fontSize }) => fontSize};
		text-transform: uppercase;
		left: -5px;
		color: ${({ color }) => color}1A;
		z-index: -1;
		top: 50%;
		transform: translateY(-50%);
	}
	@media screen and (max-width: 992px) {
		font-size: 15px;
		&:after {
			font-size: 20px;
		}
	}
`;
