import { CalendarModel } from "../../Models/Calendar.model";
import { SingleNewsModel as InterestedInModel } from "../../Models/SingleNews.model";

// Action types
export const SET_NEXT_MATCH = "SET_NEXT_MATCH";
export const SET_INTERESTED_IN = "SET_INTERESTED_IN";

// State interface
export interface IWidgetsState {
  nextMatch: CalendarModel;
  interestedIn: InterestedInModel[];
}

// Actions interface

interface INextMatchAction {
  type: typeof SET_NEXT_MATCH;
  nextMatch: CalendarModel;
}

interface IInterestedInAction {
  type: typeof SET_INTERESTED_IN;
  interestedIn: InterestedInModel[];
}

export type WidgetsActionTypes = INextMatchAction | IInterestedInAction;
