export const BC = [
	{
		link: 'media',
		rendered: 'Média',
	},
	{
		link: 'hirek',
		rendered: 'Hírek',
	},
	{
		link: 'a-csapat',
		rendered: 'A csapat',
	},
	{
		link: 'merkozesek',
		rendered: 'Mérkőzések',
	},
	{
		link: 'informaciok',
		rendered: 'Információk',
	},
];
