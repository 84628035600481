import {
  IHeaderState,
  HeaderActionTypes,
  SET_HEADER_STYLE,
  SET_ACTIVE_ITEM_ID,
} from "./types";

const initialState: IHeaderState = {
  mainStyle: false,
  activeItem: "",
};

const headerReducer = (
  state = initialState,
  action: HeaderActionTypes
): IHeaderState => {
  switch (action.type) {
    case SET_HEADER_STYLE:
      return { ...state, mainStyle: action.mainStyle };
    case SET_ACTIVE_ITEM_ID:
      return { ...state, activeItem: action.activeItem };
    default:
      return state;
  }
};

export default headerReducer;
