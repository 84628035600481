import * as React from 'react';
import { SingleNewsModel as InterestedInModel } from '../../../../Models/SingleNews.model';
import {
	InterestedInContainer,
	InterestedInTitleConteiner,
	InterestedInTitle,
	InterestedInCard,
	InterestedInCardDate,
	InterestedInCardImg,
	InterestedInCardTitle,
	InterestedInCardInfo,
	//InterestedInCardCategory,
} from './Style';
import { Col, Row } from 'react-bootstrap';
import HTMLParser from 'html-react-parser';
import moment from 'moment';
import 'moment/locale/hu';
import { Link } from 'react-router-dom';

export interface InterestedInWidgetViewProps {
	interestedIn: InterestedInModel[];
}

const InterestedInWidgetView: React.FC<InterestedInWidgetViewProps> = (
	props
) => {
	if (!props.interestedIn.length) {
		return <></>;
	} else {
		return (
			<InterestedInContainer>
				<InterestedInTitleConteiner>
					<InterestedInTitle>Ezek is érdekelhetnek</InterestedInTitle>
				</InterestedInTitleConteiner>
				<Row>
					{props.interestedIn.map((interest) => (
						<Col xs='12' lg='6' xl='4' key={interest.id}>
							<Link to={`/hirek/${interest.slug}`}>
								<InterestedInCard>
									<InterestedInCardImg imageUrl={interest.f_image.thumbnail} />
									<InterestedInCardInfo>
										{/**
                     <InterestedInCardCategory>
											{interest._embedded['wp:term'][0][0]?.name}
										</InterestedInCardCategory>
                    */}

										<InterestedInCardTitle>
											{HTMLParser(interest.title.rendered)}
										</InterestedInCardTitle>
										<InterestedInCardDate>
											{moment(interest.date).format('YYYY-MM-DD')}
										</InterestedInCardDate>
									</InterestedInCardInfo>
								</InterestedInCard>
							</Link>
						</Col>
					))}
				</Row>
			</InterestedInContainer>
		);
	}
};

export default InterestedInWidgetView;
