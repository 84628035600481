import React, { useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import Breadcrumb from '../../UI/Breadcrumb/Controller/Breadcrumb.controller';
import HTMLParser from 'html-react-parser';
import {
	InfoPageMenu,
	InfoPageWrapper,
	InfoPageContent,
} from './style/InfoPage.style';
import { IInfoPage } from '../../../Interfaces/IInfoPage.interface';

interface IInfoPageViewProps {
	acf: IInfoPage;
}
interface IInfoItems {
	menu: string;
	data: string;
}
const InfoPageView: React.FC<IInfoPageViewProps> = (
	props
): React.ReactElement => {
	const [active, set_active] = useState<number>(0);
	const items: Array<IInfoItems> = [
		{
			menu: 'Rólunk',
			data: props.acf.acf.rolunk,
		},
	];
	/**
 * {
			menu: 'Jegyárak',
			data: 'jegyára',
		},
 */
	return (
		<InfoPageWrapper>
			<Breadcrumb />
			<Container>
				<Row>
					<Col lg='3' xs='12'>
						<InfoPageMenu>
							{items.map((item: IInfoItems, idx: number) => (
								<p
									onClick={() => set_active(idx)}
									className={`${active === idx && 'active'}`}
									key={`item-${idx}`}
								>
									{item.menu}
								</p>
							))}
						</InfoPageMenu>
					</Col>
					<Col lg='9' xs='12'>
						<InfoPageContent>{HTMLParser(items[active].data)}</InfoPageContent>
					</Col>
				</Row>
			</Container>
		</InfoPageWrapper>
	);
};

export default InfoPageView;
