import styled from 'styled-components';
import { theme } from '../../../Global/Variables';
import { INewsFeaturedImg } from '../Interfaces/InsideNewsPageStyle.interface';

export const NewsContainer = styled.div`
	position: relative;
	min-height: 600px;
	@media screen and (min-width: 992px) {
		.mobile {
			display: none;
		}
	}
	@media screen and (max-width: 991px) {
		.desktop {
			display: none;
		}
	}
`;

export const NewsFeaturedImgWrapper = styled.div`
	padding: 15px;
	background-color: ${theme.colors.news_page_image_border_color};
`;
export const NewsFeaturedImg = styled.div<INewsFeaturedImg>`
	background: url(${({ imageUrl }) => imageUrl});
	background-position: center;
	background-repeat: none;
	background-size: cover;
	width: 100%;
	height: 600px;
	box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.47);
	@media screen and (max-width: 992px) {
		height: 400px;
	}
`;

export const NewsSidebar = styled.div`
	padding-top: 15px;
	@media screen and (max-width: 991px) {
		padding-top: 0;
		padding-bottom: 35px;
	}
`;
export const NewsContentContainer = styled.div`
	padding-top: 30px;
`;

export const NewsContent = styled.div`
	border: 1px solid ${theme.colors.frame_border_color};
	background-color: white;
	padding: 25px 0 40px 0;

	& > * {
		padding: 0 30px 0 15px;
	}

	a {
		color: ${theme.colors.secondary};
	}

	img {
		border: 15px solid ${theme.colors.news_page_image_border_color};
		width: 100%;
		height: auto;
	}
`;

export const NewsCategory = styled.span`
	margin: 0 0 15px 15px;
	padding: 5px 10px;
	background-color: ${theme.colors.secondary};
	color: ${theme.colors.primary_text};
	font-size: 16px;
	text-transform: uppercase;
`;

export const NewsTitle = styled.p`
	font-size: 28px;
	font-weight: 900;
	color: ${theme.colors.secondary_text};
	@media screen and (max-width: 992px) {
		font-size: 24px;
		line-height: 1.2;
	}
`;

export const NewsShare = styled.div`
	margin-bottom: 1rem;
	cursor: pointer;
	@media screen and (max-width: 992px) {
		margin: 0;
		padding: 25px 0;
	}

	button {
		font-size: 12px !important;
		font-weight: 600 !important;
		text-transform: uppercase;
		display: flex;
		align-items: center;
	}

	svg {
		font-size: 16px;
		color: ${theme.colors.facebook_color};
		margin-left: 15px;
	}
`;

export const NewsInfoContainer = styled.div`
	display: flex;
	flex-wrap: wrap;
	margin-bottom: 1rem;
	p {
		margin: 0;
	}
`;

export const NewsDateAndAuthor = styled.p`
	text-transform: uppercase;
	font-size: 16px;
	font-weight: 300;
`;

export const NewsModificationDate = styled.p`
	font-size: 10px;
	font-weight: 300;
	text-transform: uppercase;
	margin-left: 40px !important;
	display: flex;
	align-items: center;

	@media screen and (max-width: 992px) {
		margin-left: 0 !important;
	}
`;
export const NewsText = styled.div``;
