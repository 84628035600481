import Axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
export type IAxiosInstances = { [key: string]: AxiosInstance };

export abstract class axios {
	private static instances: IAxiosInstances = {};

	public static initAxios(
		baseURL: string,
		instanceName: string = 'basic'
	): void {
		this.instances[instanceName] = Axios.create({
			baseURL,
		});

		this.instances[
			instanceName
		].interceptors.request.use((config: AxiosRequestConfig) =>
			this.setInstanceHeaders(config)
		);
	}

	private static setInstanceHeaders(
		config: AxiosRequestConfig
	): AxiosRequestConfig {
		return config;
	}

	public static getInstance(instanceName: string = 'basic'): AxiosInstance {
		return this.instances[instanceName];
	}
}
