import React, { useEffect, useState } from "react";
import CountDownView from "../View/CountDown.view";
import moment from "moment";
import "moment/locale/hu";

export interface CountDownControllerProps {
  date: string;
}

const CountDownController: React.FC<CountDownControllerProps> = (props) => {
  const getDays = (date: string): number => {
    return moment(date).diff(moment(), "days") % moment().dayOfYear();
  };
  const getHours = (date: string): number => {
    return moment(date).diff(moment(), "hours") % 24;
  };
  const getMinutes = (date: string): number => {
    return moment(date).diff(moment(), "minutes") % 60;
  };

  const [days, setDays] = useState<number>(getDays(props.date));
  const [hours, setHours] = useState<number>(getHours(props.date));
  const [minutes, setMinutes] = useState<number>(getMinutes(props.date));

  useEffect(() => {
    const interval = setInterval(() => {
      setDays(getDays(props.date));
      setHours(getHours(props.date));
      setMinutes(getMinutes(props.date));
    }, 60000);
    return () => clearInterval(interval);
  }, [props.date]);

  return <CountDownView days={days} hours={hours} minutes={minutes} />;
};

export default CountDownController;
