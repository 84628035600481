import React from 'react';
import { MainPageSliderModel } from '../../../Models/MainPageSlider.model';
import MainSliderView from './MainSlider.view';
import BG from '../../../Assets/Images/bg.png';
import MainNewsView from './MainNews.view';
import { MainNewsModel } from '../../../Models/MainNews.model';
import UpcomingSlider from './UpcomingSlider.view';
import { CalendarModel } from '../../../Models/Calendar.model';
import LastestResultView from './LastestResult.view';
import { MediaModel } from '../../../Models/Media.model';
import MainMediaView from './MainMedia.view';

export interface IMainPageViewProps {
	slider: MainPageSliderModel[];
	news: MainNewsModel[];
	upcoming: CalendarModel;
	lastest: CalendarModel;
	media: MediaModel[];
}

const MainPageView: React.FC<IMainPageViewProps> = (
	props
): React.ReactElement => {
	return (
		<main style={{ backgroundImage: 'url(' + BG + ')' }}>
			<MainSliderView slider={props.slider} />
			<MainNewsView news={props.news} />
			<UpcomingSlider upcoming={props.upcoming} />
			<LastestResultView lastest={props.lastest} />
			<MainMediaView media={props.media} />
		</main>
	);
};

export default MainPageView;
