import { ModelConstructor } from "../Utils/DataSetter.util";
import {
  IFullMatch,
  ICalendar,
} from "../Interfaces/ICalendar.interface";

export class CalendarModel {
  constructor(Calendar?: ICalendar) {
    if (Calendar) {
      ModelConstructor(Calendar, this);
    }
  }
  full: IFullMatch[] = [];
}
