import React, { useEffect } from "react";
import InterestedInWidgetView from "../View/InterestedInWidget.view";
import { SingleNewsModel as InterestedInModel } from "../../../../Models/SingleNews.model";
import IReduxState from "../../../../Interfaces/IRedux.interface";
import { connect, useDispatch } from "react-redux";
import * as widgetActions from "../../../../Redux/Widgets/actions";

export interface InterestedInWidgetControllerProps {
  interestedIn: InterestedInModel[];
}

const InterestedInWidgetController: React.FC<InterestedInWidgetControllerProps> = (
  props
) => {
  const dispatch = useDispatch();
  const loadInterestedIn = (): void => {
    dispatch(widgetActions.getInterestedIn());
  };

  useEffect(() => {
    loadInterestedIn();
    //eslint-disable-next-line
  }, []);

  return <InterestedInWidgetView interestedIn={props.interestedIn} />;
};

const mapStateToProps = (state: IReduxState) => ({
  interestedIn: state.widgets.interestedIn,
});

export default connect(mapStateToProps)(InterestedInWidgetController);
