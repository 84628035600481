import { axios } from '../API/axios';
import { TeamPageModel } from '../Models/TeamPage.model';
import { ITeamPage } from '../Interfaces/ITeamPage.interface';
import { AxiosPromise, AxiosResponse } from 'axios';
import { teamRoute, staffRoute } from '../Static/ApiRoutes';
import { StaffModel } from '../Models/Staff.model';
import { IStaff } from '../Interfaces/IStaff.interface';

export default abstract class TeamPageDAO {
	private static readonly base: string = teamRoute;
	private static readonly staff: string = staffRoute;

	public static async getTeam(): Promise<TeamPageModel> {
		const request: AxiosPromise<ITeamPage> = axios.getInstance().get(this.base);
		const response: AxiosResponse<ITeamPage> = await request;
		return response.data;
	}
	public static async getStaff(): Promise<StaffModel[]> {
		const request: AxiosPromise<IStaff[]> = axios.getInstance().get(this.staff);
		const response: AxiosResponse<IStaff[]> = await request;
		return response.data;
	}
}
