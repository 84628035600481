import React, { useEffect, useState } from 'react';
import SingleMediaView from '../View/SingleMedia.view';
import { RouteChildrenProps } from 'react-router';
import { SingleMediaModel } from '../../../Models/SingleMedia.model';
import SingleMediaDAO from '../../../DAOs/SingleMedia.DAO';

export interface ISingleMediaControllerProps
	extends RouteChildrenProps<{ slug: string }> {}

const SingleMediaController: React.FC<ISingleMediaControllerProps> = (
	props
) => {
	const [media, set_media] = useState<SingleMediaModel[]>([]);
	const setData = () => {
		if (props.match && props.match.params.slug)
			SingleMediaDAO.getMedia(
				props.match.params.slug
			).then((media: SingleMediaModel[]) => set_media(media));
	};
	useEffect(() => {
		setData();
		//eslint-disable-next-line
	}, []);
	return <SingleMediaView media={media} />;
};

export default SingleMediaController;
