// Action types
export const SET_HEADER_STYLE = "SET_HEADER_STYLE";
export const SET_ACTIVE_ITEM_ID = "SET_ACTIVE_ITEM_ID";

// State interface
export interface IHeaderState {
  mainStyle: boolean;
  activeItem: string;
}

// Actions interface
interface IHeaderStyleAction {
  type: typeof SET_HEADER_STYLE;
  mainStyle: boolean;
}

interface IHeaderActiveItemAction {
  type: typeof SET_ACTIVE_ITEM_ID;
  activeItem: string;
}

export type HeaderActionTypes = IHeaderStyleAction | IHeaderActiveItemAction;
