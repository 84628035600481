import { IMedia } from '../Interfaces/IMedia.interface';
import { ICalendar } from '../Interfaces/ICalendar.interface';
import { axios } from '../API/axios';
import { MainPageSliderModel } from '../Models/MainPageSlider.model';
import { IMainNews, IMainPageSlider } from '../Interfaces/IMainPage.interface';
import { AxiosPromise, AxiosResponse } from 'axios';
import {
	mainPageSliderRoute,
	mainPageNewsRoute,
	upcomingMatchRoute,
	lastestMatchRoute,
	mainGalleryRoute,
} from '../Static/ApiRoutes';
import { MainNewsModel } from '../Models/MainNews.model';
import { CalendarModel } from '../Models/Calendar.model';
import { MediaModel } from '../Models/Media.model';

export default abstract class MainPageDAO {
	private static readonly slider: string = mainPageSliderRoute;
	private static readonly news: string = mainPageNewsRoute;
	private static readonly upcoming: string = upcomingMatchRoute;
	private static readonly lastest: string = lastestMatchRoute;
	private static readonly media: string = mainGalleryRoute;

	public static async getNews(): Promise<MainNewsModel[]> {
		const newsReq: AxiosPromise<IMainNews[]> = axios
			.getInstance()
			.get(this.news);
		const newsResp: AxiosResponse<IMainNews[]> = await newsReq;
		return newsResp.data;
	}

	public static async getSlider(): Promise<MainPageSliderModel[]> {
		const sliderReq: AxiosPromise<IMainPageSlider[]> = axios
			.getInstance()
			.get(this.slider);
		const sliderResp: AxiosResponse<IMainPageSlider[]> = await sliderReq;
		return sliderResp.data;
	}

	public static async getUpcoming(): Promise<CalendarModel> {
		const upcomingReq: AxiosPromise<ICalendar> = axios
			.getInstance()
			.get(this.upcoming);
		const upcomingResp: AxiosResponse<ICalendar> = await upcomingReq;
		return upcomingResp.data;
	}

	public static async getLastest(): Promise<CalendarModel> {
		const lastestReq: AxiosPromise<ICalendar> = axios
			.getInstance()
			.get(this.lastest);
		const lastestResp: AxiosResponse<ICalendar> = await lastestReq;
		return lastestResp.data;
	}
	public static async getMedia(): Promise<MediaModel[]> {
		const link = this.media;
		const mediaReq: AxiosPromise<IMedia[]> = axios.getInstance().get(link);
		const mediaResp: AxiosResponse<IMedia[]> = await mediaReq;
		return mediaResp.data;
	}
}
