import React from 'react';
import {
	BreadcrumbWrapper,
	BreadcrumbItem,
	BreadcrumbContainer,
} from './Style';
import { Container, Row, Col } from 'react-bootstrap';
import { BC } from '../../../../Static/BC';
import { Link } from 'react-router-dom';

export interface BreadcrumbProps {
	breadcrumb: string[];
	third_level: string | undefined;
}

const Breadcrumb: React.FC<BreadcrumbProps> = ({ breadcrumb, third_level }) => {
	return (
		<BreadcrumbWrapper>
			<Container>
				<Row>
					<Col xs='12'>
						<BreadcrumbContainer>
							{breadcrumb.map((bc, index) => {
								if (index === breadcrumb.length - 1) {
									return (
										<BreadcrumbItem className='last' key={index}>
											{BC.filter((b) => b.link === bc)[0]?.rendered ||
												third_level ||
												bc}
										</BreadcrumbItem>
									);
								} else {
									return (
										<BreadcrumbItem key={index}>
											<Link
												to={`/${
													BC.filter((b) => b.link === bc)[0]?.link || ''
												}`}
											>
												{BC.filter((b) => b.link === bc)[0]?.rendered ||
													(index !== 0 && third_level) ||
													bc}
											</Link>
											<span>/</span>
										</BreadcrumbItem>
									);
								}
							})}
						</BreadcrumbContainer>
					</Col>
				</Row>
			</Container>
		</BreadcrumbWrapper>
	);
};

export default Breadcrumb;
