import { Dispatch } from "redux";
import IReduxState from "../../Interfaces/IRedux.interface";
import { setError, setFull, setLoading } from "../Status/actions";
import { IError } from "../../Interfaces/IError.interface";
import { setLastest, setUpcoming } from "../Calendar/actions";
import MatchPageDAO from "../../DAOs/MatchPage.DAO";
import { CalendarModel } from "../../Models/Calendar.model";

export const getMatchData = () => {
  return async (dispatch: Dispatch, getState: () => IReduxState) => {
    if (
      !!getState().calendar.upcoming.full.length ||
      !!getState().calendar.lastest.full.length
    ) {
      dispatch(setFull(false));
    } else {
      dispatch(setFull(true));
    }

    const allAction = [
      MatchPageDAO.getUpcoming()
        .then((upcoming: CalendarModel) => {
          if (
            JSON.stringify(getState().calendar.upcoming) !==
            JSON.stringify(upcoming)
          ) {
            dispatch(setUpcoming(upcoming));
          }
        })
        .catch((error: IError) => dispatch(setError(error))),
      MatchPageDAO.getResults()
        .then((results: CalendarModel) => {
          if (
            JSON.stringify(getState().calendar.lastest) !==
            JSON.stringify(results)
          ) {
            dispatch(setLastest(results));
          }
        })
        .catch((error: IError) => dispatch(setError(error))),
    ];

    if (getState().status.loading) {
      Promise.all(allAction).finally(() => {
        if (getState().status.loading) {
          dispatch(setLoading(null));
        }
      });
    } else {
      dispatch(
        setLoading(
          Promise.all(allAction).finally(() => {
            if (getState().status.loading) {
              dispatch(setLoading(null));
            }
          })
        )
      );
    }
  };
};
