import styled, { css } from 'styled-components';
import { motion } from 'framer-motion';
import { theme } from '../../../Global/Variables';
import {
	IDropdown,
	IDrawerItem,
	IMenuWrapper,
	/* IResponsiveMenuButton, */
	ISearchInput,
	IMobileMenuButton,
	IMobileMenuButtonLine,
} from '../Interfaces/Style.interfaces';
import drawerBg from '../.././../Assets/Images/menubckg-mobile.png';
import { Link } from 'react-router-dom';

export const HeaderContainer = styled.header`
	color: ${theme.colors.light_text};
	position: relative;
`;

export const BeforeMenuWrapper = styled.div`
	width: 100%;
	background-color: ${theme.colors.primary};
	display: flex;
	justify-content: center;
`;
export const BeforeMenu = styled.div`
	width: 100%;
	min-height: 60px;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	padding: 0 15px;

	@media screen and (max-width: 992px) {
		flex-direction: column;
		justify-content: center;
		position: fixed;
		top: 0;
		z-index: 10;
		padding: 0;
	}
`;

export const BeforeMenuLogo = styled(Link)`
	@media screen and (min-width: 993px) {
		display: none;
	}
	display: flex;
	justify-content: flex-start;
	align-items: center;
	padding: 15px 0;
	img {
		max-width: 80%;
		max-height: 100px;
		height: auto;
		width: auto;
	}

	@media screen and (max-width: 576px) {
		img {
			max-width: 100%;
		}
	}

	@media screen and (max-width: 992px) {
		padding: 60px 15px 0 15px;
	}
`;

export const DropdownContainer = styled.div`
	position: relative;
	background-color: ${theme.colors.primary};

	@media screen and (max-width: 992px) {
		padding: 0px;
		width: 100%;
	}
`;
export const DropdownButton = styled.button<IDropdown>`
	color: ${theme.colors.primary_text};
	background-color: transparent;
	border: none;
	padding: 10px 0;
	text-transform: uppercase;
	font-size: 16px;
	font-weight: 300;
	height: 60px;

	@media screen and (max-width: 992px) {
		width: calc(100% - 75px);
		display: flex;
		justify-content: center;
		align-items: center;
		padding: 10px 15px;
	}

	&:focus {
		outline: none;
	}

	svg {
		margin-left: 14px;
		transition: transform 0.3s ease-in-out;
		transform: ${({ show }) => (show ? 'rotate(180deg)' : 'rotate(0deg)')};
	}
`;

export const Dropdown = styled(motion.div)`
	background-color: ${theme.colors.dropdown_color};
	min-width: 290px;
	transform-origin: top center;
	position: absolute;
	z-index: 3;
	border-bottom: none;
	overflow: hidden;

	@media screen and (max-width: 992px) {
		position: relative;
	}

	a {
		padding-left: 16px;
		line-height: 46px;
		text-decoration: none;

		@media screen and (max-width: 600px) {
			padding-left: 0;
		}
	}
`;

export const DropdownElement = styled.div`
	width: 100%;
	border-bottom: 1px solid ${theme.colors.dropdown_line_color};
	transition: background-size 0.3s ease-in-out;
	&:hover {
		background-color: ${theme.colors.dropdown_hover_color};
	}

	@media screen and (max-width: 992px) {
		display: flex;
		justify-content: center;
		align-items: center;
	}
`;

export const SocialsAndSearch = styled.div`
	display: flex;
	flex-direction: row;
	height: 100%;

	@media screen and (max-width: 992px) {
		display: none;
	}
`;

export const SocialsContainer = styled.div`
	height: 100%;
	width: auto;
	display: flex;
	justify-content: center;
	align-items: center;

	* {
		margin-right: 25px;
	}

	svg {
		font-size: 16px;
		line-height: 25px;
		cursor: pointer;
	}

	a {
		text-decoration: none;
		margin: 0;
		color: ${theme.colors.light_text};
		transition: color 0.3s ease-in-out;
		&:hover {
			color: ${theme.colors.primary_text};
		}
	}
`;

export const SocialsTitle = styled.p`
	text-transform: uppercase;
	font-weight: 400;
	font-size: 14px;
	margin-bottom: 0;
	text-align: center;
`;

export const SearchContainer = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	position: relative;
`;

export const SearchInput = styled.input<ISearchInput>`
	color: ${({ darkTheme }) =>
		darkTheme ? theme.colors.light_text : theme.colors.primary_text};
	height: 100%;
	width: ${({ width }) => (width ? width : '100%')};
	padding: 8px 15px;
	background-color: transparent;
	${({ darkTheme }) =>
		darkTheme
			? `
    border-top: none;
    border-bottom: none;
    border-right: 1px solid ${theme.colors.separator_color};
    border-left: 1px solid ${theme.colors.separator_color};
    `
			: 'border: none'};

	&:focus {
		outline: none;
	}

	&::placeholder {
		${({ darkTheme }) =>
			!darkTheme &&
			css`
				color: ${theme.colors.primary_text} !important;
				text-transform: uppercase;
				font-weight: 500;
				font-style: italic;
			`};
	}
`;

export const SearchButton = styled.button`
	color: ${theme.colors.light_text};
	padding: 10px 15px;
	background-color: transparent;
	border: none;
	position: absolute;
	right: 0;
	transition: color 0.3s ease-in-out;
	&:focus {
		outline: none;
	}
	&:hover {
		color: ${theme.colors.primary_text};
	}
`;

export const MenuWrapper = styled.div<IMenuWrapper>`
	background-color: ${({ main }) =>
		main ? theme.colors.opacity_black : theme.colors.tertiary};
	min-height: 80px;
	width: 100%;
	position: ${({ main }) => (main ? 'absolute' : 'relative')};
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 2;

	a {
		color: ${({ main }) =>
			main ? theme.colors.primary_text : theme.colors.secondary_text};
	}

	@media screen and (max-width: 992px) {
		display: none;
	}
`;

export const MenuContainer = styled.div`
	width: 100%;
	padding: 0 15px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	flex-direction: row;
	/* max-width: 1170px;
  margin: 0 auto; */
`;

export const MenuLogo = styled(Link)`
	width: 30%;
	height: auto;
	@media screen and (max-width: 992px) {
		display: none;
	}

	img {
		width: 100%;
		height: auto;
		max-width: 100%;
	}
`;

export const MenuItemsContainer = styled.div`
	display: flex;
	flex-wrap: wrap;

	& > a {
		text-decoration: none;
		text-transform: uppercase;
		padding-right: 10px;

		&:last-child {
			padding-right: 0px;
		}

		@media screen and (max-width: 1200px) {
			padding-right: 8px;
			div {
				font-size: 16px;
			}
		}
	}

	.active {
		background-image: linear-gradient(
			to right,
			${theme.colors.secondary},
			${theme.colors.secondary}
		);
		background-position: bottom center;
		background-repeat: no-repeat;
		background-size: 100% 5px;
		font-weight: 900;
	}
`;

export const MenuItem = styled.div`
	font-size: 18px;
	font-weight: 400;
	text-align: center;
	background-image: linear-gradient(
		to right,
		${theme.colors.secondary},
		${theme.colors.secondary}
	);
	background-position: bottom center;
	background-repeat: no-repeat;
	background-size: 0% 5px;
	transition: background-size 0.3s ease-in-out, font-weight 0.15s ease-in-out;

	&:hover {
		font-weight: 900;
		background-size: 100% 5px;
	}
	&::before {
		display: block;
		content: attr(title);
		font-weight: 900;
		height: 0;
		overflow: hidden;
		visibility: hidden;
	}
`;

export const ResponsiveDrawerMenu = styled(motion.div)`
	position: fixed;
	z-index: 3;
	top: 60px;
	bottom: 0;
	right: 0;
	width: 90%;
	background-color: ${theme.colors.tertiary};

	@media screen and (min-width: 993px) {
		display: none;
	}
`;

export const ResponsiveDrawerMenuContainer = styled(motion.div)`
	position: relative;
	height: 100%;
	width: 100%;
	background-image: url(${drawerBg});
	background-position: top;
	background-repeat: repeat;
`;

export const ResponsiveDrawerSearch = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	margin: 40px 10px 0 10px;
	height: 60px;
	background-color: ${theme.colors.secondary};
`;

export const ResponsiveDrawerSearchButton = styled.button`
	position: absolute;
	height: 100%;
	background-color: transparent;
	outline: none;
	border: none;
	top: 0;
	right: 0;
	width: 75px;
	padding: 0 15px;
	transition: color 0.3s ease-in-out;
	color: ${theme.colors.primary_text};
	background-image: linear-gradient(
		${theme.colors.tertiary},
		${theme.colors.tertiary}
	);
	background-position: left center;
	background-size: 1px 80%;
	background-repeat: no-repeat;

	&:focus {
		outline: none;
	}
`;

export const ResponsiveDrawerContent = styled.div`
	padding-bottom: 50px;
	padding-top: 50px;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	flex-direction: column;
	position: absolute;
	top: 100px;
	bottom: 85px;
	overflow-y: scroll;
	width: 100%;

	a {
		width: 100%;
		text-decoration: none;
	}

	.active {
		font-weight: 900;
		background-image: linear-gradient(
			to right,
			${theme.colors.secondary},
			${theme.colors.secondary}
		);
		background-position: left center;
		background-repeat: no-repeat;
		background-size: 10px 100%;
	}
`;

export const ResponsiveDrawerFooter = styled.div`
	position: absolute;
	bottom: 0;
	background-image: url(${drawerBg});
	background-position: top;
	background-repeat: repeat;
	padding: 0 15px;
	height: 85px;
	width: 100%;
	border-top: 1px solid ${theme.colors.light_text};
	display: flex;
	justify-content: space-between;
	flex-direction: row;
	align-items: center;
	color: ${theme.colors.primary} !important;

	svg {
		color: ${theme.colors.light_text} !important;
		margin: 0 10px;
		font-size: 16px;
		&:hover {
			color: ${theme.colors.primary} !important;
		}
	}
`;

export const ResponsiveDrawerItem = styled.div<IDrawerItem>`
	padding: 5px 20px 5px 0;
	min-height: 40px;
	color: ${theme.colors.primary};
	font-size: 28px;
	font-weight: 400;
	text-align: end;
	text-transform: uppercase;
	background-image: linear-gradient(
		to right,
		${theme.colors.secondary},
		${theme.colors.secondary}
	);
	background-position: left center;
	background-repeat: no-repeat;
	background-size: 10px 0%;
	opacity: ${({ show }) => (show ? 1 : 0)};
	transition: background-size 0.3s ease-in-out, font-weight 0.15s ease-in-out,
		opacity 0.5s ease-in-out;

	&:hover {
		font-weight: 900;
		background-size: 10px 100%;
	}
	&::before {
		display: block;
		content: attr(title);
		font-weight: 900;
		height: 0;
		overflow: hidden;
		visibility: hidden;
	}
	@media screen and (max-width: 600px) {
		transition: background-size 0.3s ease-in-out, font-weight 0.15s ease-in-out;
	}
`;

export const DropdownButtonContainer = styled.div`
	display: flex;
	flex-direction: row;
	@media screen and (max-width: 992px) {
		width: 100%;
	}
`;

export const MobileMenuButtonContainer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	width: 75px;
	padding: 10px;
	background-color: ${theme.colors.drawer_button_color};

	@media screen and (min-width: 993px) {
		display: none;
	}
`;

export const MobileMenuButton = styled.div<IMobileMenuButton>`
	width: 100%;
	display: grid;
	grid-template-columns: auto auto auto;
	grid-template-rows: auto auto auto;
	gap: 5px 0px;
	grid-template-areas:
		'. line1 line1'
		'line2 line2 line2'
		'line3 line3 .';
	transition: all 0.3s ease-in-out;

	div:nth-child(1) {
		${({ drawerStatus }) =>
			drawerStatus
				? css`
						transform: rotate(45deg);
				  `
				: css`
						transform: rotate(0deg);
				  `}
	}

	div:nth-child(2) {
		${({ drawerStatus }) =>
			drawerStatus
				? css`
						transform: rotate(-45deg);
				  `
				: css`
						transform: rotate(0deg);
				  `}
	}

	div:nth-child(3) {
		${({ drawerStatus }) =>
			drawerStatus
				? css`
						transform: rotate(-45deg);
						position: relative;
						left: 3.5px;
				  `
				: css`
						transform: rotate(0deg);
				  `}
	}
`;

export const MobileMenuButtonLine = styled.div<IMobileMenuButtonLine>`
	height: 2px;
	background-color: ${theme.colors.tertiary};
	transition: all 0.3s ease-in-out;
	${({ full, right, left }) =>
		full
			? css`
					grid-area: line1;
			  `
			: right
			? css`
					grid-area: line2;
			  `
			: left
			? css`
					grid-area: line3;
			  `
			: ``}
`;

interface IGrayBG {
	isVisible: boolean;
}

export const GrayBG = styled.div<IGrayBG>`
	position: fixed;
	top: 0;
	left: 0;
	max-width: 100%;
	width: 100%;
	height: 100%;
	background: rgba(0, 0, 0, 0.5);
	opacity: 1;
	z-index: 2;
	transition: opacity 0.7s ease;
	${({ isVisible }) =>
		!isVisible &&
		css`
			max-width: 0;
			opacity: 0;
		`}
`;
