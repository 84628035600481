import styled from 'styled-components';
/* import * as theme from "../../../../Global/Variables"; */
import ButtonView from '../../../UI/Button/View/Button.view';

export const OwlContents = styled.div`
	width: 100%;
	height: 100%;
	transition: background-color 0.5s linear;
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: flex-end;
	color: white;
	background: linear-gradient(
		0deg,
		rgba(0, 0, 0, 0.8) 0%,
		rgba(0, 0, 0, 0) 50%
	);

	@media screen and (max-width: 576px) {
		flex-direction: column;
		align-items: center;
		justify-content: flex-end;
		text-align: center;
		background: linear-gradient(
			0deg,
			rgba(0, 0, 0, 0.8) 0%,
			rgba(0, 0, 0, 0) 100%
		);
		padding-bottom: 30px;
	}
`;

export const OwlText = styled.div`
	padding: 0 15px;
	min-height: 51%;
	width: 30%;
	transition: min-height 0.25s linear;
	display: flex;
	flex-direction: column;

	@media screen and (max-width: 576px) {
		width: 65% !important;
	}

	@media screen and (max-width: 991px) {
		min-height: 0 !important;
	}

	@media screen and (min-width: 991px) {
		width: 32%;
	}
	@media screen and (min-width: 1200px) {
		width: 35%;
	}
`;

export const OwlTitle = styled.div`
	font: 900 20px 'Rubik', sans-serif !important;
	font-style: italic !important;
	opacity: 0;
	visibility: hidden;
	font-weight: normal;
	transition-property: font-size, font-weight, opacity, visibility;
	transition-duration: 0.25s;
	transition-timing-function: linear;

	@media screen and (max-width: 576px) {
		font-size: 20px;
	}
`;

export const OwlDesc = styled.div`
	display: none;

	@media screen and (max-width: 991px) {
		display: none !important;
	}
`;

export const OwlButton = styled(ButtonView)`
	opacity: 0;
	transition-property: opacity, visibility;
	transition: all 0.25s linear;
	visibility: hidden;
	width: 30%;
	a {
		width: 100%;
		transition: all 0.25s linear;
		position: relative;
		float: right;
		text-transform: uppercase;

		@media screen and (max-width: 991px) {
			font-size: 12px !important;
		}
		@media screen and (min-width: 991px) {
			font-size: 14px !important;
		}
	}

	@media screen and (max-width: 576px) {
		width: auto !important;
	}
`;

export const OwlWrapper = styled.section`
	z-index: 0;
	padding: 0;
	max-width: 100%;
	overflow-x: hidden;
	margin: 0;

	.owl-carousel {
		touch-action: manipulation;
	}

	article {
		height: 600px;
		background: rgba(0, 0, 0, 0.5);

		@media screen and (max-width: 576px) {
			height: 400px;
		}
	}
	.owl-item {
		&.left {
			${OwlContents} {
				flex-direction: row-reverse;
			}
			${OwlTitle} {
				text-align: left;
			}
		}
		&.right {
			${OwlTitle} {
				text-align: right;
			}
		}
		&.left ${OwlTitle}, &.right ${OwlTitle} {
			opacity: 1;
			visibility: visible;
		}
		&:not(.center) > article > ${OwlContents} {
			background-color: rgba(0, 0, 0, 0.7);
		}
		&.active.center {
			& ${OwlButton} {
				visibility: visible;
				opacity: 1;
			}
			& ${OwlText} {
				width: 70%;
				min-height: 30%;
				padding: 0 15px 15px 15px;
			}
			& ${OwlDesc} {
				font: 200 16px 'Rubik', sans-serif !important;
				font-style: italic !important;
				display: initial;
			}
			& ${OwlTitle} {
				font: 900 40px 'Rubik', sans-serif !important;
				opacity: 1;
				visibility: visible;
				margin-bottom: 20px;
				@media screen and (max-width: 576px) {
					font-size: 20px !important;
				}
			}
		}
	}
	.owl-nav {
		margin: 0 !important;
		.owl-prev,
		.owl-next {
			outline: none;
			text-decoration: none;
			position: absolute;
			top: 50%;
			bottom: 50%;
			margin: 0 !important;
			&:hover {
				background: transparent !important;
			}
			&:focus {
				text-decoration: none;
			}
		}
		.owl-prev {
			left: 15px;
		}
		.owl-next {
			right: 15px;
		}
	}
	.owl-prev-arrow,
	.owl-next-arrow {
		width: 100px;
		height: auto;
		background-color: transparent !important;
		margin: 0;
		transform: translateY(-50%);
		&:hover {
			text-decoration: none !important;
		}
		&:focus  {
			outline: none !important;
		}

		@media screen and (max-width: 576px) {
			width: 50px;
			height: auto;
			background-color: transparent !important;
		}
	}
`;
