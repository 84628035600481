import { axios } from '../API/axios';
/* import { NewsPageModel } from "../Model/NewsPage.model"; */
import { INewsResponse, INew } from '../Interfaces/NewsPage.interface';
import { AxiosPromise, AxiosResponse } from 'axios';
import { newsRoute } from '../Static/ApiRoutes';

export default abstract class NewsPageDAO {
	private static readonly base: string = newsRoute;

	public static async getNews(
		pageSize: number,
		selectedPage: number
	): Promise<INewsResponse> {
		const request: AxiosPromise<INew[]> = axios
			.getInstance()
			.get(`${this.base}&per_page=${pageSize}&page=${selectedPage}`);
		const response: AxiosResponse<INew[]> = await request;
		return {
			headers: response.headers,
			data: response.data,
		};
	}

	public static async getSearchResults(
		slug: string,
		pageSize: number,
		selectedPage: number
	): Promise<INewsResponse> {
		const request: AxiosPromise<INew[]> = axios
			.getInstance()
			.get(
				`${this.base}&search=${slug}&per_page=${pageSize}&page=${selectedPage}`
			);
		const response: AxiosResponse<INew[]> = await request;
		return {
			headers: response.headers,
			data: response.data,
		};
	}
}
