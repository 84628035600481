import { MenuModel } from "../../Models/Menu.model";

// Action types
export const SET_MENU = "SET_MENU";

// State interface
export interface IMenuState extends MenuModel {}

// Actions interface

interface IMenuAction {
  type: typeof SET_MENU;
  menu: MenuModel;
}

export type MenuActionTypes = IMenuAction;
