import React from "react";
import {
  CountDownContainer,
  CountDownUnit,
  CountDownSection,
  CountDownNumber,
  SectionSeparator,
} from "./CountDown.style";

export interface CountDownViewProps {
  days: number;
  hours: number;
  minutes: number;
}

const CountDownView: React.FC<CountDownViewProps> = (props) => {
  return (
    <CountDownContainer>
      <CountDownSection>
        <CountDownNumber>{props.days}</CountDownNumber>
        <CountDownUnit>Nap</CountDownUnit>
      </CountDownSection>
      <SectionSeparator>:</SectionSeparator>
      <CountDownSection>
        <CountDownNumber>{props.hours}</CountDownNumber>
        <CountDownUnit>Óra</CountDownUnit>
      </CountDownSection>
      <SectionSeparator>:</SectionSeparator>
      <CountDownSection>
        <CountDownNumber>{props.minutes}</CountDownNumber>
        <CountDownUnit>Perc</CountDownUnit>
      </CountDownSection>
    </CountDownContainer>
  );
};

export default CountDownView;
