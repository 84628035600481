import { InfoPageModel } from "../../Models/InfoPage.model";
import {
  IInfoPageState,
  InfoPageActionTypes,
  SET_INFO_PAGE_DATA,
} from "./types";

const initialState: IInfoPageState = new InfoPageModel();

const widgetsReducer = (
  state = initialState,
  action: InfoPageActionTypes
): IInfoPageState => {
  switch (action.type) {
    case SET_INFO_PAGE_DATA:
      return action.infoData;
    default:
      return state;
  }
};

export default widgetsReducer;
