import React, { useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { RouteChildrenProps } from 'react-router-dom';
import NextMatchWidget from '../../UI/NextMatchWidget/Controller/NextMatchWidget.controller';
import Breadcrumb from '../../UI/Breadcrumb/Controller/Breadcrumb.controller';
import { TeamPageModel } from '../../../Models/TeamPage.model';
import { TeamPageWrapper } from './style/TeamPage.style';
import { Tab } from '../../UI/Tab/Tab';
import ShadowText from '../../UI/ShadowText/ShadowText';
import TeamCardView from './TeamCard.view';
import { StaffModel } from '../../../Models/Staff.model';
export interface IMediaViewProps extends RouteChildrenProps {
	team: TeamPageModel;
	staff: StaffModel[];
}

const TeamPageView: React.FC<IMediaViewProps> = (props): React.ReactElement => {
	const [active, set_active] = useState<number>(0);
	if (props.team.data.mydata.length === 0) return <></>;
	const tabData = ['NB II.', 'STÁB'];
	const changeActiveTab = (d: string): void => {
		tabData.forEach((td: string, idx: number) => {
			if (td === d) set_active(idx);
		});
	};
	let posTmp = '';
	return (
		<>
			<Breadcrumb />
			<TeamPageWrapper>
				<Container>
					<Row>
						<Col xs='12' lg='8' xl='9'>
							<Tab data={tabData} active={active} callback={changeActiveTab} />
							<Row>
								{active !== tabData.length - 1
									? props.team.data.mydata.map((td) => {
											if (posTmp !== td.pos[0].name) {
												posTmp = td.pos[0].name;
												return (
													<React.Fragment key={td.player_id}>
														<Col xs='12'>
															<div style={{ margin: '30px 0' }}>
																<ShadowText
																	margin='0'
																	text={posTmp}
																	color='#000000'
																	fontSize='40px'
																/>
															</div>
														</Col>
														<Col lg='4' md='6' sm='12'>
															<TeamCardView
																player_pic={td.featured_image}
																name={td.name}
																number={td.number}
																player_id={td.player_id}
															/>
														</Col>
													</React.Fragment>
												);
											}
											return (
												<Col lg='4' md='6' sm='12' key={td.player_id}>
													<TeamCardView
														player_pic={td.featured_image}
														name={td.name}
														number={td.number}
														player_id={td.player_id}
													/>
												</Col>
											);
									  })
									: props.staff.map((td) => (
											<Col lg='4' md='6' sm='12'>
												<TeamCardView
													player_pic={
														td._embedded['wp:featuredmedia'][0].source_url
													}
													name={td.title.rendered}
													position={td.acf.pozicio}
												/>
											</Col>
									  ))}
							</Row>
						</Col>
						<Col xs='12' lg='4' xl='3'>
							<NextMatchWidget />
						</Col>
					</Row>
				</Container>
			</TeamPageWrapper>
		</>
	);
};

export default TeamPageView;
