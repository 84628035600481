import React from 'react';
import { Link } from 'react-router-dom';
import { UnaccentedSlug } from '../../../Utils/UnaccentedSlug.util';

import { TeamCardWrapper, TeamCardText } from './style/TeamCard.style';

export interface ITeamCardViewProps {
	player_pic: string;
	number?: string;
	name: string;
	position?: string;
	player_id?: number;
}

const TeamCardView: React.FC<ITeamCardViewProps> = (
	props
): React.ReactElement => {
	return (
		<TeamCardWrapper>
			{props.player_id ? (
				<Link to={`/a-csapat/${UnaccentedSlug(props.name)}`}>
					<img src={props.player_pic} alt={props.name} />
					<TeamCardText>
						<span>{props.number}</span>
						<p>{props.name}</p>
					</TeamCardText>
				</Link>
			) : (
				<>
					<img src={props.player_pic} alt={props.name} />
					<TeamCardText alt>
						<p>
							{props.name}
							<br />
							<span>{props.position}</span>
						</p>
					</TeamCardText>
				</>
			)}
		</TeamCardWrapper>
	);
};

export default TeamCardView;
