import React, { ReactNode } from "react";
import { Switch } from "react-router";
import LayoutController from "../../Layout/Controller/Layout.controller";

interface IRouteViewProps {
  children: ReactNode;
}

const Router: React.FC<IRouteViewProps> = (props) => {
  return (
    <LayoutController>
      <Switch>{props.children}</Switch>
    </LayoutController>
  );
};

export default Router;
