import styled from 'styled-components';
import { theme } from '../../../../Global/Variables';
import { IButton } from '../Interfaces/Style.interface';

export const Button = styled.button<IButton>`
	position: relative;
	height: ${({ height }) => (height ? height : '50px')};
	width: ${({ width }) => (width ? width : '200px')};
	line-height: 1.2;
	text-align: center;
	text-transform: uppercase;
	outline: none;
	border: none;
	transition: all 0.3s ease-in-out;
	font-weight: 900;
	font-size: 18px;
	font-style: italic;
	color: ${theme.colors.primary_text};
	background-color: ${theme.colors.secondary};
	${({ outlined }) =>
		outlined &&
		`background: linear-gradient(to right, #8dcc26 80%, #030407) 50% 50% repeat-x,
        linear-gradient(to left, #030407 60%, #8dcc26) 50% 50% repeat-x,
        linear-gradient(to top, #8dcc26, #8dcc26) 0 100% repeat-y,
        linear-gradient(to bottom, #030407, #030407) 100% 0 repeat-y;
      background-size: 300% 1px, 300% 1px, 1px 300%, 1px 300%, 100% 100%;
      background-position: 100% 0, 0% 100%, 0 0, 100% 100%, 0 0;
      color: ${theme.colors.secondary_text};
      `}

	&:hover {
		background-color: ${theme.colors.tertiary};
		color: ${theme.colors.highlighted_text};
		${({ outlined }) =>
			outlined &&
			`background-color: ${theme.colors.secondary}; color: ${theme.colors.primary_text};`}

		${({ decorated }) =>
			decorated &&
			`background-color: ${theme.colors.decorated_button_hover_color};`}
	}

	&:focus {
		outline: none;
	}

	a {
		text-transform: none;
		color: inherit;
		&:hover,
		&:visited,
		&:link,
		&:active {
			text-decoration: none;
		}
	}

	${({ decorated }) =>
		decorated &&
		`
    top: 12px;
    &::after {
      content: "";
      position: absolute;
      right: -10px;
      bottom: 0;
      width: 0;
      height: 0;
      border-top: 10px solid ${theme.colors.decorated_button_dark_green_color};
      border-right: 10px solid transparent;
      opacity: 1;
      transition: all .6s ease-in-out;
    }
  
    &::before {
      content: "";
      position: absolute;
      left: -10px;
      bottom: 0;
      width: 0;
      height: 0;
      border-top: 10px solid ${theme.colors.decorated_button_dark_green_color};
      border-left: 10px solid transparent;
      opacity: 1;
      transition: all .6s ease-in-out;
    }
  `}
`;
