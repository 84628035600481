import { axios } from "../API/axios";
import { ICalendar } from "../Interfaces/ICalendar.interface";
import { AxiosPromise, AxiosResponse } from "axios";
import { upcomingMatchRoute } from "../Static/ApiRoutes";
import { CalendarModel } from "../Models/Calendar.model";

export default abstract class NextMatchWidgetDAO {
  private static readonly next: string = upcomingMatchRoute;

  public static async getNext(): Promise<CalendarModel> {
    const nextReq: AxiosPromise<ICalendar> = axios.getInstance().get(this.next);
    const nextResp: AxiosResponse<ICalendar> = await nextReq;
    return nextResp.data;
  }
}
