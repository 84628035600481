import styled from 'styled-components';
import { theme } from '../../../../Global/Variables';

export const MatchOverviewWrapper = styled.div`
	padding: 30px 0;
`;

export const MatchOverviewBox = styled.div`
	background: ${theme.colors.tertiary};
	padding: 30px 20px;
	border: 2px solid ${theme.colors.frame_border_color};
	margin-bottom: 20px;
`;
export const MatchOverviewBoxTitle = styled.div`
	display: flex;
	align-items: center;
	margin-bottom: 40px;
	h2 {
		margin: 0;
		padding: 0;
	}
	&:before {
		content: '';
		margin-right: 15px;
		width: 70px;
		height: 20px;
		background-color: ${theme.colors.secondary};
		display: block;
		clip-path: polygon(0 0, 100% 0%, 85% 100%, 0% 100%);
	}
`;
export const MatchOverviewBoxContent = styled.div`
	p {
		font-size: 18px;
	}
	.details {
		div {
			text-align: center;
			font-size: 18px;
			span {
				font-weight: bold;
				word-break: break-all;
			}
			&.row {
				padding: 10px 0;
				border-top: 2px solid ${theme.colors.frame_border_color};
			}
		}
	}
`;
export const MatchOverviewResultBox = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;

	@media screen and (max-width: 992px) {
		flex-direction: column;
	}

	.logo {
		@media screen and (max-width: 992px) {
			order: 1;
			&:first-child {
				position: absolute;
				right: 25%;
			}
			&:last-child {
				position: relative;
				left: -25%;
			}
		}
		img {
			width: 128px;
			height: 128px;
			@media screen and (max-width: 992px) {
				width: 64px;
				height: 64px;
			}
		}
	}
	.result-wrapper {
		text-align: center;
		@media screen and (max-width: 992px) {
			order: 2;
		}
		.result {
			font-size: 50px;
			font-weight: 900;
		}
		.teams {
			text-transform: uppercase;
			font-weight: 600;
			font-size: 20px;
			margin-bottom: 10px;
			padding-bottom: 10px;
			position: relative;
			&:after {
				content: '';
				display: block;
				background: ${theme.colors.secondary};
				width: 50px;
				height: 5px;
				position: absolute;
				bottom: 0;
				left: 50%;
				transform: translateX(-50%);
			}
			span {
				color: ${theme.colors.light_text};
			}
		}
		.match-date,
		.venue {
			font-size: 14px;
			color: ${theme.colors.light_text};
			text-transform: uppercase;
		}
	}
`;
