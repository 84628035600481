import { IDynamicObject } from '../Interfaces/IDynamicObject.interface';

export const setBoolean = (
	value: string | null | number | boolean
): boolean => {
	let result: boolean = false;
	if (typeof value === 'boolean') result = value;
	else {
		result = `${value}` === '1' ? true : false;
	}
	return result;
};

export const setString = (value: string | number | null): string => {
	let val: string = `${value}`;
	if (value === null || value === 0) val = '';
	return val;
};

export const setNumber = (
	value: string | number | null,
	Default: number = 0
): number => {
	const parsed_number: number = Number(value);
	if (isNaN(parsed_number) || !parsed_number) return Default;
	return parsed_number;
};

export const dataSetter = (
	baseValue: string | number | boolean,
	incomingValue: any
): any => {
	let returnValue = incomingValue;
	if (typeof baseValue === 'string') {
		returnValue = setString(incomingValue);
	}
	if (typeof baseValue === 'boolean') {
		returnValue = setBoolean(incomingValue);
	}
	if (typeof baseValue === 'number') {
		returnValue = setNumber(incomingValue);
	}
	return returnValue;
};

export const ModelConstructor = (
	APIData: IDynamicObject<any>,
	ModelProperties: IDynamicObject<any>
) => {
	for (let property in APIData) {
		ModelProperties[property] = dataSetter(
			ModelProperties[property],
			APIData[property]
		);
	}
	return;
};
