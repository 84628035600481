import {
  SET_LOADING,
  SET_ERROR,
  SET_FULL,
  IStatusState,
  StatusActionTypes,
} from "./types";

const initialState: IStatusState = {
  loading: null,
  error: null,
  full: true,
};

const statusReducer = (
  state = initialState,
  action: StatusActionTypes
): IStatusState => {
  switch (action.type) {
    case SET_LOADING:
      return { ...state, loading: action.loading };
    case SET_FULL:
      return { ...state, full: action.full };
    case SET_ERROR:
      return { ...state, error: action.error };
    default:
      return state;
  }
};

export default statusReducer;
