import { SinglePlayerModel } from "./../../Models/SinglePlayer.model";

// Action types
export const SET_PLAYER = "SET_PLAYER";

// State interface
export interface IPlayerState {
  player: SinglePlayerModel[];
}

// Actions interface
interface IPlayerDataAction {
  type: typeof SET_PLAYER;
  player: SinglePlayerModel[];
}

export type PlayerActionTypes = IPlayerDataAction;
