import * as React from "react";
import LoaderView from "../View/Loader.view";
import IReduxState from "../../../../Interfaces/IRedux.interface";
import { connect } from "react-redux";

export interface LoaderControllerProps {
  visible: boolean;
  full: boolean;
}

const LoaderController: React.FC<LoaderControllerProps> = (props) => {
  if (props.visible) {
    return <LoaderView {...props} />;
  } else {
    return <></>;
  }
};

const mapStateToProps = (state: IReduxState) => ({
  visible:
    !!state.status.loading ||
    !!!state.footer.sponsors.length ||
    !!!state.menu.items.length,
  full: false,
});

export default connect(mapStateToProps)(LoaderController);
