import React, { useEffect } from 'react';
import { RouteChildrenProps } from 'react-router-dom';
import IReduxState from '../../../Interfaces/IRedux.interface';
import MediaView from '../View/Media.view';
import { MediaModel } from '../../../Models/Media.model';
import { connect, useDispatch } from 'react-redux';
import * as mediaActions from '../../../Redux/Media/actions';

export interface IMediaProps extends RouteChildrenProps {
	media: MediaModel[];
}

const MediaController: React.FC<IMediaProps> = (props): React.ReactElement => {
	const dispatch = useDispatch();
	const loadMediaAndVideo = (): void => {
		dispatch(mediaActions.getMediaAndVideo());
	};

	useEffect(() => {
		loadMediaAndVideo();
		//eslint-disable-next-line
	}, []);

	return <MediaView {...props} />;
};

const mapStateToProps = (state: IReduxState) => ({
	media: state.media.media,
});

export default connect(mapStateToProps)(MediaController);
