import React from "react";
import { Wrapper, Loader, LoaderContainer, SvgContainer } from "./Loader.style";
import { ReactComponent as DeacSVG } from "../../../../Assets/Images/deac.svg";

export interface LoaderViewProps {
  visible?: boolean;
  full?: boolean;
}

const LoaderView: React.FC<LoaderViewProps> = (props) => {
  return (
    <LoaderContainer {...props}>
      <SvgContainer full={props.full}>
        <DeacSVG />
      </SvgContainer>
      <Wrapper>
        <Loader>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </Loader>
      </Wrapper>
    </LoaderContainer>
  );
};

export default LoaderView;
