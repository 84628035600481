import { SinglePlayerModel } from './../../Models/SinglePlayer.model';
import { PlayerActionTypes, SET_PLAYER } from './types';
import { Dispatch } from 'redux';
import IReduxState from '../../Interfaces/IRedux.interface';
import { setError, setFull, setLoading } from '../Status/actions';
import { IError } from '../../Interfaces/IError.interface';
import SinglePlayerDAO from '../../DAOs/SinglePlayer.DAO';

const setPlayerData = (player: SinglePlayerModel[]): PlayerActionTypes => {
	return {
		type: SET_PLAYER,
		player: player,
	};
};

export const getPlayer = (nameSlug: string) => {
	return async (dispatch: Dispatch, getState: () => IReduxState) => {
		dispatch(setFull(true));

		dispatch(
			setLoading(
				SinglePlayerDAO.getPlayer(nameSlug)
					.then((p) => {
						if (
							JSON.stringify(getState().playerPage.player) !== JSON.stringify(p)
						) {
							dispatch(setPlayerData(p));
						}
					})
					.catch((error: IError) => dispatch(setError(error)))
					.finally(() => {
						if (getState().status.loading) {
							dispatch(setLoading(null));
						}
					})
			)
		);
	};
};
