import styled from "styled-components";
import Bg from "../../../../Assets/Images/Upcoming_Bg.png";
import { theme } from "../../../../Global/Variables";

export const UpcomingWrapper = styled.section`
  background: url(${Bg});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

  .owl-carousel {
    padding-bottom: 60px;
    touch-action: manipulation;
  }

  .owl-item {
    padding-bottom: 12px;
  }

  .owl-item:last-child div {
    &::after {
      display: none;
    }
  }

  .owl-nav {
    display: flex;
    flex-direction: row;
    position: absolute;
    top: -140px;
    right: 0;
  }
  .owl-prev button {
    margin-right: 5px;
  }
  .disabled {
    opacity: 0.5;
  }

  @media screen and (max-width: 768px) {
    .owl-nav {
      position: relative;
      top: auto;
      right: auto;
      justify-content: center;
      margin-top: 40px;
      align-items: center;
    }
  }
`;

export const UpcomingTitle = styled.p`
  font-size: 28px;
  font-weight: 900;
  color: ${theme.colors.primary_text};
  padding: 60px 0 0 0;
  margin: 0;
  text-transform: uppercase;
`;

export const UpcomingButtons = styled.div`
  padding-top: 60px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;

  button:first-child {
    margin-right: 5px;
  }

  .disabled {
    opacity: 0.5;
  }
`;

export const UpcomingButton = styled.button`
  width: 30px;
  height: 30px;
  border: 1px solid ${theme.colors.separator_color};
  background-color: ${theme.colors.tertiary};
  outline: none;
  color: ${theme.colors.upcoming_button_color};
  font-size: 12px;
  transition: opacity 0.3s ease-in-out;
  &:focus {
    outline: none;
  }

  @media screen and (max-width: 768px) {
    width: 45px;
    height: 45px;
	font-size: 16px;
  }
`;

export const UpcomingSubtitle = styled.p`
  a {
    font-size: 14px;
    font-weight: 300;
    color: ${theme.colors.primary_text} !important;
    font-style: italic;
  }
  padding-bottom: 60px;
  margin: 0;
`;

export const UpcomingItemWrapper = styled.div`
  &::after {
    content: "";
    position: absolute;
    top: 15%;
    right: -21.5px;
    height: 70%;
    width: 3px;
    background-color: ${theme.colors.secondary};
  }
`;

export const UpcomingItem = styled.div`
  background-color: ${theme.colors.tertiary};
  border: 2px solid ${theme.colors.secondary};
  padding: 30px 25px 0 30px;
`;

export const UpcomingItemLogos = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 30px;
`;

export const UpcomingItemLogo = styled.img`
  width: auto !important;
  height: 110px;
`;

export const UpcomingItemTitleContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding-bottom: 15px;

  span {
    font-weight: 600;
    font-size: 16px;
    text-transform: uppercase;
    text-align: center;
    color: ${theme.colors.light_text};
    margin-bottom: 0;
  }
`;

export const UpcomingItemTitle = styled.p`
  text-align: center;
  font-size: 20px;
  text-transform: uppercase;
  color: ${theme.colors.secondary_text};
  font-weight: 600;
  margin-bottom: 0;
`;

export const UpcomingItemInfos = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: ${theme.colors.light_text};
  text-transform: uppercase;
  background-image: linear-gradient(
    ${theme.colors.secondary} 0%,
    ${theme.colors.secondary} 100%
  );
  background-position: top center;
  background-size: 20% 3px;
  background-repeat: no-repeat;
  p {
    margin-bottom: 0;
  }
  padding-top: 20px;
`;

export const UpcomingItemButton = styled.div`
  display: flex;
  justify-content: center;
`;
