import { IMediaState, SET_MEDIA, MediaActionTypes } from './types';

const initialState: IMediaState = {
	media: [],
};

const mediaReducer = (
	state = initialState,
	action: MediaActionTypes
): IMediaState => {
	switch (action.type) {
		case SET_MEDIA:
			return { ...state, media: action.media };
		default:
			return state;
	}
};

export default mediaReducer;
