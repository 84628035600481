import styled from 'styled-components';
import { theme } from '../../../Global/Variables';

export const SingleMediaWrapper = styled.div`
	padding: 20px 0;
`;

export const ImageGalleryWrapper = styled.div`
	background: white;
	box-shadow: 2px 2px 10px 1px rgba(0, 0, 0, 0.2);
	padding: 15px;
	.image-gallery-thumbnail.active,
	.image-gallery-thumbnail:hover,
	.image-gallery-thumbnail:focus {
		border-color: ${theme.colors.secondary};
	}
	.image-gallery-icon:hover {
		color: ${theme.colors.secondary};
	}
	.image-gallery-icon {
		outline: none !important;
	}
`;
